import chroma from 'chroma-js';

export const colorUtil = {
    randomHSL,
    generateFromBaseColor
};

function randomHSL(userId){
    const color = "hsla(" + ~~(40 * userId ^ 50)  + "," +
                    "70%,"+
                    "70%,1)"
    return color;
}

function generateFromBaseColor(index, numColors, alpha = 1.0) {
    let baseColor = "#8393F4"
    const chromaBaseColor = chroma(baseColor);

    // Calculate the hue step to maintain the same hue
    const hueStep = 360 / numColors;

    // Calculate the new hue for the gradient color
    const newHue = (chromaBaseColor.get('hsl.h') + index * hueStep) % 360;

    // Create a new color with the adjusted hue and same saturation and lightness
    const gradientColor = chroma(baseColor).set('hsl.h', newHue);
    return gradientColor.alpha(alpha).hex();
}