<template>
  <div>
    <popup-view :isVisible="currentType != null" @close="setCurrentType(null)">
      <edit-type-dialog />
    </popup-view>
    <popup-view :isVisible="currentZone != null" @close="setCurrentZone(null)">
      <edit-zone-dialog />
    </popup-view>
    <popup-view :isVisible="currentTable != null" @close="setCurrentTable(null)">
      <edit-table-dialog />
    </popup-view>
    <popup-view :isVisible="deleteOpenDialogTable" @close="deleteOpenDialogTable = false">
      <confirm-delete-dialog :product="currentSelectTable" @submitDeleteAction="submitDeleteTable" @cancelDeleteAction="deleteOpenDialogTable = false"/>
    </popup-view>
    <popup-view :isVisible="deleteOpenDialogZone" @close="deleteOpenDialogZone = false">
      <confirm-delete-dialog :product="currentSelectZone" @submitDeleteAction="submitDeleteZone" @cancelDeleteAction="deleteOpenDialogZone = false"/>
    </popup-view>
    <popup-view :isVisible="deleteOpenDialogType" @close="deleteOpenDialogType = false">
      <confirm-delete-dialog :product="currentSelectType" @submitDeleteAction="submitDeleteType" @cancelDeleteAction="deleteOpenDialogType = false"/>
    </popup-view>

    <page-header 
      @actionClick="setCurrentType({})"
      actionIconName="plus.svg"
      :actionText="$t('tablePage.addTypes')"
      :headerTitle="$t('tablePage.nameTypes')"
      iconName="table.svg" />

    <transition-group class="card-list my-4" name="list" tag="div">
      <type-row v-for="type in types" :key="type.id" :item="type" @openAccount="openAccount" @secondaryAction="openDeleteDialogType" />
    </transition-group>
    
    <page-header 
      @actionClick="setCurrentZone({})"
      actionIconName="plus.svg"
      :actionText="$t('tablePage.addZones')"
      :headerTitle="$t('tablePage.nameZones')"
      iconName="table.svg" />

    <transition-group class="card-list my-4" name="list" tag="div">
      <zone-row v-for="zone in zones" :key="zone.zoneId" :item="zone" @openAccount="openAccount" @secondaryAction="openDeleteDialogZone" />
    </transition-group>

    <page-header 
      @actionClick="setCurrentTable({})"
      actionIconName="plus.svg"
      :actionText="$t('tablePage.addTable')"
      :headerTitle="$t('tablePage.name')"
      iconName="table.svg" />

    <transition-group class="card-list mt-4" name="list" tag="div">
      <table-row v-for="table in tables" :key="table.tableId" :item="table" @openAccount="openAccount" @secondaryAction="openDeleteDialogTable" />
    </transition-group>
  </div>
</template>

<script>
import PageHeader from './components/PageHeader.vue';
import {mapState, mapActions, mapMutations} from 'vuex';
import TableRow from './components/TableRow.vue';
import ZoneRow from './components/ZoneRow.vue';
import TypeRow from './components/TypeRow.vue';
import PopupView from './components/PopupView.vue';
import EditTableDialog from './dialogs/EditTableDialog.vue';
import EditZoneDialog from './dialogs/EditZoneDialog.vue';
import EditTypeDialog from './dialogs/EditTypeDialog.vue';
import ConfirmDeleteDialog from './dialogs/ConfirmDeleteDialog.vue';

export default {
    name: "TablesPage",
    components: {
      PageHeader,
      TableRow,
      ZoneRow,
      TypeRow,
      PopupView,
      EditTableDialog,
      EditZoneDialog,
      EditTypeDialog,
      ConfirmDeleteDialog
    },
    data() {
      return {
          deleteOpenDialogTable: false,
          deleteOpenDialogZone: false,
          deleteOpenDialogType: false,
          currentSelectTable: {},
          currentSelectZone: {},
          currentSelectType: {}
      }
    },
    methods: {
      ...mapActions('table', ['getTables', 'deleteTable', 'getZones', 'deleteZone', 'getTypes', 'deleteType']),
      ...mapMutations('table', ['setCurrentTable', 'setCurrentZone', 'setCurrentType']),
      openAccount(account) {
        this.$router.push({name: 'AccountSummary', params: {accountId: account.clientAccountId}});
      },
      openDeleteDialogTable(item){
        this.deleteOpenDialogTable = true;
        this.currentSelectTable = item;
      },
      submitDeleteTable(){
        this.deleteTable(this.currentSelectTable);
        this.deleteOpenDialogTable = false;
      },
      openDeleteDialogZone(item){
        this.deleteOpenDialogZone = true;
        this.currentSelectZone = item;
      },
      submitDeleteZone(){
        this.deleteZone(this.currentSelectZone);
        this.deleteOpenDialogZone = false;
      },
      openDeleteDialogType(item){
        this.deleteOpenDialogType = true;
        this.currentSelectType = item;
      },
      submitDeleteType(){
        this.deleteType(this.currentSelectType);
        this.deleteOpenDialogType = false;
      },
    },
    computed: {
      ...mapState('table', ['tables', 'currentTable', 'zones', 'types', 'currentZone', 'currentType'])
    },
    mounted() {
      this.getTables();
      this.getTypes();
      this.getZones();
    }
}
</script>

<style>

</style>