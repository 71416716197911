import apiClient from '../helpers/ApiClient'
import { authHeader, handleError } from '../helpers/ApiClient';
import { JWTHelper } from "@/helpers/JWTHelper";

export const accountService = {
    login,
    logout,
    getMyData,
    switchOrg
};

function switchOrg(orgId){
    return apiClient.post(`/user/multiorg/switch/${orgId}`, {}, { headers: authHeader()})
        .then((response) => {
            const { data, status } = response;
            if (status === 200){
                JWTHelper.saveJwt(data.data.token);
            }
            return response.data
        })
        .catch((error) => handleError(error));
}

function getMyData(){
    return apiClient.get("/user/me", { headers: authHeader()})
        .then((response) => {
            return response.data
        })
        .catch((error) => handleError(error));
}

function logout(){
    JWTHelper.clearJwt()
}

function login(username, password, orgId){
    const postData = {
        "orgId": orgId,
        "password": password,
        "username": username
    };
    return apiClient.post("/user/login", postData)
        .then((response) => {
            const { data, status } = response;
            if (status === 200){
                JWTHelper.saveJwt(data.data.token);
            }
            return response.data
        })
        .catch((error) => handleError(error));
}