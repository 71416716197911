import { userService } from "@/services/UserService";
import { toast } from "vue3-toastify";
import { i18n } from "./../lang";

const state = {
    users: [],
    deletedUserId: null,
    userRequestMessage: "",
    isLoadingUserRequest: false,
    editingUser: null,
    editRequest: {
        loading: false,
    },
    isAddingUser: false,
    addRequest: {
        loading: false,
    }
};

const actions = {
    getAllUsers({dispatch, commit}){
        commit(mutations.setLoadingRequest.name, true);
        userService.getAllUsers()
            .then((response) => {
                commit('setLoadingRequest', false);
                commit(mutations.setUsersList.name, response.data);
            })
            .catch((error) => {
                commit(mutations.setLoadingRequest.name, false, error);
            });
    },
    deleteUser({dispatch, commit, state}, user){
        commit(mutations.setUserEditRequestStarted.name);
        userService.deleteUser(user.userId)
            .then((response) => {
                toast.success(i18n.global.t("toast.deleteSuccess"));
                const updatedUsers = state.users.filter(function(element) {
                    return element.userId !== user.userId;
                  });
                commit(mutations.setEditRequestSuccess.name);
                commit(mutations.updateUsersList.name, updatedUsers);
            })
            .catch((error) => {
                toast.error(i18n.global.t("toast.deleteError"));
                commit(mutations.setEditRequestError.name, error)
            })
    },
    createUser({dispatch, commit, state}, {username, password, type, pincode, terminalIp}){
        commit(mutations.setAddUserRequestState.name, {loading: true});
        userService.createUser(username, password, type, pincode, terminalIp)
            .then((response) => {
                toast.success(i18n.global.t("toast.createSuccess"));
                commit(mutations.setAddUserRequestState.name, {loading: false});
                commit(mutations.addNewUser.name, response.data);
            })
            .catch((error) => {
                toast.error(i18n.global.t("toast.createError"));
                commit(mutations.setAddUserRequestState.name, {loading: false, message: error})
            })
    },
    updateUser({dispatch, commit, state}, user) {
        commit(mutations.setUserEditRequestStarted.name);
        userService.updateUser(user)
            .then((response) => {
                toast.success(i18n.global.t("toast.successEdit"));
                const updatedUsers = state.users.map(x => (x.userId === user.userId ? user : x));
                commit(mutations.setEditRequestSuccess.name);
                commit(mutations.updateUsersList.name, updatedUsers);
            })
            .catch((error) => {
                toast.error(i18n.global.t("toast.errorEdit"));
                commit(mutations.setEditRequestError.name, error)
            });
    }
}

const mutations = {
    setLoadingRequest(state, isLoading, message=''){
        state.userRequestMessage = message;
        state.isLoadingUserRequest = isLoading;
    },
    setUsersList(state, users){
        state.users = users;
    },
    setDeletedUserId(state, userId){
        
        state.deletedUserId = userId;
    },
    setUserEdit(state, editingUser){
        state.editingUser = editingUser;
        //in order to clear previous errors
        state.editRequest = {
            loading: false
        }
    },
    setUserEditRequestStarted(state){
        state.editRequest = {
            loading: true
        }
    },
    setEditRequestSuccess(state){
        state.editRequest = {
            loading: false,
            success: true
        }
        state.editingUser = null;
    },
    setEditRequestError(state, errorMessage){
        state.editRequest = {
            loading: false,
            success: false,
            message: errorMessage
        }
    },
    setAddingUser(state, isAdding) {
        state.isAddingUser = isAdding;
        if (!isAdding){
            state.addRequest = {};
        }
    },
    setAddUserRequestState(state, {loading, message = null}){
        state.addRequest = {
            loading: loading,
            message: message,
        }

        console.log(loading+" "+message);
        if (!loading && !message){
            state.isAddingUser = false;
        }
    },
    updateUsersList(state, newUsersList){
        state.users = newUsersList;
    },
    addNewUser(state, newUser){
        state.users.push(newUser);
    }
}


export const user = {
    namespaced: true,
    state,
    actions,
    mutations
};