<template>
  <div class="input-container">
      <div class="hint-container">
          <p class="input-hint" > {{ hint }} </p>
          <span class="tooltip-container" v-if="tooltipText" v-tooltip.left="tooltipText">
              <inline-svg
                  class="icon-info"
                  :src="require('@/assets/icons/info.svg')"></inline-svg>
          </span>
      </div>
      <input
      :class="error ? 'error' : ''"
      :type="type" 
      :value="customValue" 
      :disabled="disabled"
      @input="$emit('update:customValue', $event.target.value)"/>

  </div>
</template>

<script>
import Tooltip from 'primevue/tooltip';
export default {
    name: "DesignInput",
    directives: {
        'tooltip': Tooltip
    },
    emits: ['update:customValue'],
    props: {
        'type': {
            type: String,
            default: ""
        },
        'min': {
          type: Number,
          default: 1
        },
        'max': {
          type: Number,
          default: 100
        },
        'hint': {
            type: String,
            default: ""
        },
        'customValue': {
            type: [String, Number],
            default: ""
        },
        'error' : {
            type: Boolean,
            default: false
        },
        'disabled' : {
            type: Boolean,
            default: false
        },
        'tooltipText': {
            type: String,
            default: ""
        }
    }
}
</script>

<style lang="scss" scoped>
    .input-container {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        .hint-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 8px 8px !important;

            .input-hint {
                padding: 0 !important;
                font-size: 12px;
                font-weight: normal;
                color: $color-secondary-text;
                opacity: 0.8;
                margin: 0 !important;
            }

            .icon-info {
                stroke: rgba($color-secondary-text, 0.5);
                width: 12px;
                height: 12px;
            }

            .tooltip-container {
                line-height: 0 !important;
            }
        }



        input {
            width: 100%;
            padding: 10px 20px 10px 20px;
            &.error {
                outline: 1px solid rgba($color-pastel-red, .3);;
                box-shadow: 0px 0px 15px rgba($color-pastel-red, .3);
            }
            &:disabled {
                opacity: 0.3;
            }
        }
    }
</style>