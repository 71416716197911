<template>
    <div class="main">
        <p class="page-header mb-3 text-center"> {{ $t('tablePage.zoneEditTitle') }} </p>
        <div class="form-input-container">
            <DesignInput v-model:customValue="zoneName" type="text" :hint="$t('tablePage.zoneName')"/>
        </div>
        <div class="form-input-container mt-3  mb-4">
            <DesignMultiSelectWrapper :hint="$t('productPage.color')">
                <ColorPicker v-model="zoneColor" default-color="" inputId="cp-hex" format="hex"
                             class="color-picker"/>
            </DesignMultiSelectWrapper>
        </div>
        <p v-if="zoneRequest.message" class="text-status error"> {{ zoneRequest.message }}</p>
        <div class="button-container row">
            <design-button class="button col-12 col-md-6" @customClick="setCurrentZone(null)" type="secondary">
                {{ $t('generic.cancel') }}
            </design-button>
            <design-button class="button col-12 col-md-6" @customClick="submitZone()" :loading="zoneRequest.loading">
                {{ $t('generic.save') }}
            </design-button>
        </div>
    </div>
</template>

<script>
import ColorPicker from "primevue/colorpicker";
import DesignMultiSelectWrapper from "@/views/components/DesignMultiSelectWrapper.vue";
import {mapActions, mapState, mapMutations} from "vuex";
import DesignInput from '../components/DesignInput.vue';
import DesignButton from '../components/DesignButton.vue';

export default {
    name: 'EditZoneDialog',
    components: {
        ColorPicker,
        DesignMultiSelectWrapper,
        DesignInput,
        DesignButton,
    },
    data() {
        return {
            zoneName: '',
            zoneColor: '',
            isNew: false,
        }
    },
    methods: {
        ...mapMutations('table', ['setCurrentZone']),
        ...mapActions('table', ['addZone', 'updateZone']),
        updateColor(eventData) {
            this.zoneColor = eventData.cssColor
        },
        submitZone() {
            const zoneToSend = this.currentZone;
            zoneToSend.name = this.zoneName;
            zoneToSend.color = this.zoneColor.toUpperCase();
            if (this.isNew) {
                this.addZone(zoneToSend);
            } else {
                this.updateZone(zoneToSend);
            }
        }
    },
    computed: {
        ...mapState('table', ['zoneRequest', 'currentZone'])
    },
    mounted() {
        if (!$.isEmptyObject(this.currentZone)) {
            this.isNew = false;
            this.zoneName = this.currentZone.name;
            this.zoneColor = this.currentZone.color;
        } else {
            this.isNew = true;
            this.zoneColor = '#000';
        }
    },
    watch: {
        zoneColor: function (newVal) {
            if (!newVal.startsWith('#')) {
                this.zoneColor = '#' + newVal;
            }
        }
    }

}
</script>

<style lang="scss" scoped>
.main {
    width: auto;
    padding: 20px 20px;
}

select {
    padding: 10px 20px 10px 20px;
}

.form-input-container {
    display: flex;
    flex-direction: column;
    row-gap: $default-edge-spacing-l;
}

.button-container {
    margin-top: $default-edge-spacing-l;

    .button {
        width: 100%;

        &:nth-child(1) {
            padding-right: $default-edge-spacing !important;
        }
    }

}

.profile-data-container {
    padding: 0px 0px 30px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .letter-icon {
        color: $color-primary-text;
        background-color: $color-primary-blue;
        border-radius: 150px;
        text-align: center;
        font-weight: 400;
        font-size: 24px;
        height: 48px;
        width: 48px;
        min-width: 48px;
        min-height: 48px;
        padding: 6px;
    }

    .profile-text-container {
        flex-grow: 1;
        padding-left: $default-edge-spacing-l;
        overflow: hidden;

        .title {
            font-size: $font-title;
            color: $color-primary-text;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .subtitle {
            font-size: $font-subtitle;
            color: $color-secondary-text;
        }
    }

    .delete-button {
        min-width: 18px;
        max-width: 18px;
        margin-right: $default-edge-spacing-xl;
        stroke: $color-pastel-red;

    }


}

@media (max-width: $screen-md) {

    .button-container {
        padding-top: $default-edge-spacing;

        .button {
            margin-top: $default-edge-spacing-l;

            &:nth-child(1) {
                padding-right: 0 !important;
            }
        }


    }

}
</style>