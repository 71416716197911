<template>
  <div>
    <page-header
        class="header"
        iconName="clock.svg"
        actionIconName="refresh.svg"
        @actionClick="getAccount"
        :actionText="$t('generic.refresh')"
        :headerTitle="$t('generic.account')"
    >
    </page-header>
    <div class="row" v-if="clientAccountSummaryData">
      <div class="col-12 col-md card p-5">
        <p class="default-header">{{ $t('generic.account') }}</p>
        <p class="default-text">{{ clientAccountSummaryData.clientAccount.clientAccountId }}</p>
      </div>
      <div class="col-12 col-md">
        <div class="card p-5 ml-md-3">
          <p class="default-header">{{ $t('generic.status') }}</p>
          <p class="default-text" :class="{red : clientAccountSummaryData.clientAccount.status == 'CLOSED'}">
            {{ clientAccountSummaryData.clientAccount.status }}</p>
        </div>
      </div>
      <div class="col-12 col-md">
        <div class="card p-5 ml-md-3">
          <p class="default-header">{{ $t('generic.openedAt') }}</p>
          <p class="default-text">
            {{ new Date(clientAccountSummaryData.clientAccount.openedTimestamp).toLocaleString('en-GB') }}</p>
        </div>
      </div>

      <div class="col-12 col-sm" v-if="clientAccountSummaryData.clientAccount.user?.username">
        <div class="card p-5 ml-md-3">
          <p class="default-header">{{ $t('generic.username') }}</p>
          <p class="default-text">
            {{ clientAccountSummaryData.clientAccount.user.username }}
          </p>
        </div>
      </div>

      <div class="col-12 col-sm" v-if="clientAccountSummaryData.clientAccount.table?.tableNumber">
        <div class="card p-5 ml-md-3">
          <p class="default-header">{{ $t('generic.tableNumber') }}</p>
          <p v-if="clientAccountSummaryData.clientAccount.table" class="default-text">
            {{ clientAccountSummaryData.clientAccount.table.tableNumber }}
          </p>
        </div>
      </div>


      <div class="col-12 col-sm" v-if="clientAccountSummaryData.clientAccount?.customName">
        <div class="card p-5 ml-md-3">
          <p class="default-header">{{ $t('generic.name') }}</p>
          <span class="default-text">{{ clientAccountSummaryData.clientAccount.customName }}</span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="main-content col-12 col-md-9 order-2 order-md-1">
        <DataTable :value="clientAccountSummaryData?.orderedProducts"
                   v-model:filters="filters"
                   table-style="min-width: 50rem"
                   :loading="loading" :global-filter-fields="['orderedProductId', 'product.name', 'payType']">
          <template #header>
            <div class="d-flex" style="margin-top: 22px">
              <div class="p-input-icon-left">
                <i class="pi pi-search"/>
                <InputText v-model="filters['global'].value" placeholder="Keyword Search"/>
              </div>
              <div class="ml-3">
                <Calendar dateFormat="dd-mm-yy" class="mr-1 mb-1" v-model="dates" selectionMode="range"
                          :manualInput="false" showIcon iconDisplay="input" placeholder="jj/mm/aaaa - jj/mm/aaaa"/>
                <button class="action-button mr-1" v-if="dates" @click="searchDateCalendar()">
                  <inline-svg class="action-icon" :src="require('@/assets/icons/search.svg')"/>
                </button>
              </div>
            </div>
          </template>
          <template #empty> {{ $t('productGroupPage.noFundResultTable') }}</template>
          <template #loading> {{ $t('productGroupPage.loadingResultTable') }}</template>
          <Column field="orderedProductId" header="Id" sortable>
            <template #body="{ data }">
              {{ data.orderedProductId }}
            </template>
          </Column>
          <Column field="name" header="Name" sortable>
            <template #body="{ data }">
              {{ data.product.name }}
            </template>
          </Column>
          <Column field="customComment" header="Comment" sortable>
            <template #body="{ data }">
              {{ data.customComment }}
            </template>
          </Column>
          <Column field="payType" header="Type" sortable>
            <template #body="{ data }">
              <select v-if="data.linkedToOrderedProductId == 0 &&  (data.payType == 'CASH' || data.payType == 'CARD')"
                      class="bread-crumb-base" @change="updateOrderedProductPayType($event, data)"
                      :value="data.payType">
                <option value="CARD">Card</option>
                <option value="CASH">Cash</option>
              </select>
              <span v-else class="bread-crumb-base">{{ data.payType }}</span>
            </template>
          </Column>
          <Column field="units" header="Quantity" sortable>
            <template #body="{ data }">
              {{ data.units.toFixed(2) }} <span v-if="data.product.weightPrice">KG</span>
            </template>
          </Column>
          <Column field="chargedPriceVatIncl" header="Price" sortable>
            <template #body="{ data }">
              € {{ data.chargedPriceVatIncl.toFixed(2) }}
            </template>
          </Column>
          <Column field="chargedVat" header="VAT" sortable>
            <template #body="{ data }">
              {{ data.chargedVat }}%
            </template>
          </Column>
          <Column field="linkedToOrderedProductId" header="Linked To" sortable>
            <template #body="{ data }">
              {{ data.linkedToOrderedProductId }}
            </template>
          </Column>
          <Column field="actions" header="Actions" sortable>
            <template #body="{ data }">
              <div class="edit-row-btn">
                <button v-if="data.linkedToOrderedProductId == 0" class="delete"
                        @click="deleteProduct(data.orderedProductId)">
                  <inline-svg :src="require('@/assets/icons/trash.svg')"/>
                </button>
              </div>
            </template>
          </Column>
        </DataTable>
      </div>

      <div v-if="clientAccountSummaryData" class="col-12 col-md-3 order-1 order-md-2">
        <div class="col-12 sticky-top">
          <div class="ml-md-3 total-container card ">
            <div class="text-center">
              <p class="header float-left">{{ $t('generic.total') }}</p>
              <span class="float-right default-header">€{{
                  clientAccountSummaryData.clientAccount.totalData.total.toFixed(2)
                }}</span>
            </div>
            <div class="text-center mb-3">
              <p class="header float-left">{{ $t('generic.paid') }}</p>
              <span class="float-right default-header">€{{ clientAccountSummaryData.totalData.total.toFixed(2) }}</span>
            </div>

            <div class="text-center">
              <span class="float-left">{{ $t('paymentsPage.totalCash') }}</span>
              <span v-if="clientAccountSummaryData != null"
                    class="float-right">€{{ clientAccountSummaryData.totalData.totalCash.toFixed(2) }}</span>
              <span v-else class="float-right">€{{ 0.0.toFixed(2) }}</span>
            </div>

            <div class="text-center">
              <span class="float-left">{{ $t('paymentsPage.totalCard') }}</span>
              <span v-if="clientAccountSummaryData != null"
                    class="float-right">€{{ clientAccountSummaryData.totalData.totalCard.toFixed(2) }}</span>
              <span v-else class="float-right">€{{ 0.0.toFixed(2) }}</span>
            </div>

            <div class="text-center">
              <span class="float-left">{{ $t('paymentsPage.totalBankTransfer') }}</span>
              <span v-if="clientAccountSummaryData != null"
                    class="float-right">€{{ clientAccountSummaryData.totalData.totalBankTransfer.toFixed(2) }}</span>
              <span v-else class="float-right">€{{ 0.0.toFixed(2) }}</span>
            </div>
            <hr>

            <div class="text-center">
              <span class="float-left">{{ $t('generic.total') }}</span>
              <span v-if="clientAccountSummaryData != null"
                    class="float-right">€{{ clientAccountSummaryData.totalData.total.toFixed(2) }}</span>
              <span v-else class="float-right">€{{ 0.0.toFixed(2) }}</span>
            </div>

            <div class="text-center">
              <span class="float-left">{{ $t('paymentsPage.totalOffered') }}</span>
              <span v-if="clientAccountSummaryData != null"
                    class="float-right">€{{ clientAccountSummaryData.totalData.totalOffered.toFixed(2) }}</span>
              <span v-else class="float-right">€{{ 0.0.toFixed(2) }}</span>
            </div>

            <div class="text-center">
              <span class="float-left">{{ $t('paymentsPage.totalError') }}</span>
              <span v-if="clientAccountSummaryData != null"
                    class="float-right">€{{ clientAccountSummaryData.totalData.totalError.toFixed(2) }}</span>
              <span v-else class="float-right">€{{ 0.0.toFixed(2) }}</span>
            </div>
          </div>
          <div v-if="clientAccountSummaryData && clientAccountSummaryData.vatSummaries"
               class="ml-md-3 total-container card mt-3">
            <div class="d-flex align-items-center">
              <p class="header mb-3">{{ $t('generic.vat') }}</p>
            </div>

            <div v-for="vatSummary in clientAccountSummaryData.vatSummaries" :key="vatSummary.vatBasePercent"
                 class="mb-3 d-card">
              <span>{{ vatSummary.vatBasePercent }}%</span>
              <div class="text-center">
                <span class="float-left">{{ $t('paymentsPage.totalVatExcl') }}</span>
                <span class="float-right">€{{ vatSummary.totalVatExcl.toFixed(2) }}</span>
              </div>
              <div class="text-center">
                <span class="float-left">{{ $t('generic.vat') }}</span>
                <span class="float-right">€{{ vatSummary.totalVat.toFixed(2) }}</span>
              </div>
              <div class="text-center">
                <span class="float-left">{{ $t('paymentsPage.totalVatIncl') }}</span>
                <span class="float-right">€{{ vatSummary.totalVatIncl.toFixed(2) }}</span>
              </div>

            </div>
          </div>
          <div v-if="clientAccountSummaryData.clientAccount.clientData?.name" class="ml-md-3 total-container card mt-3">
            <div class="d-flex align-items-center">
              <p class="header mb-3">{{ $t('generic.clients') }}</p>
            </div>
            <div class="text-center">
              <span class="float-left">{{ $t('generic.name') }}</span>
              <span class="float-right">{{ clientAccountSummaryData.clientAccount.clientData?.name }}</span>
            </div>
            <div class="text-center">
              <span class="float-left">{{ $t('generic.mail') }}</span>
              <span class="float-right">{{ clientAccountSummaryData.clientAccount.clientData?.email }}</span>
            </div>
            <div class="text-center" v-if="clientAccountSummaryData.clientAccount.clientData?.vat">
              <span class="float-left">{{ $t('generic.vat') }}</span>
              <span class="float-right">{{ clientAccountSummaryData.clientAccount.clientData?.vat }}</span>
            </div>
            <div class="text-center">
              <span class="float-left">{{ $t('generic.phone') }}</span>
              <span class="float-right">{{ clientAccountSummaryData.clientAccount.clientData?.phoneNumber }}</span>
            </div>
            <div class="text-center">
              <span class="float-left">{{ $t('generic.address') }}</span>
              <span class="float-right">
                          {{ clientAccountSummaryData.clientAccount.clientData?.zipCode }}
                          {{ clientAccountSummaryData.clientAccount.clientData?.city }}
                <br/>
                          {{ clientAccountSummaryData.clientAccount.clientData?.street }}
                          {{ clientAccountSummaryData.clientAccount.clientData?.number }}
                          {{ clientAccountSummaryData.clientAccount.clientData?.box }}
              </span>
            </div>
          </div>

          <div class="ml-md-3 mt-3">
            <design-button type="delete"
                           @customClick="deleteAccountWithConfirm(clientAccountSummaryData.clientAccount.clientAccountId)">
              <p>{{ $t('generic.delete') }}</p>
            </design-button>
          </div>
        </div>


      </div>
    </div>

  </div>

</template>

<script>
import {ref} from 'vue';
import {FilterMatchMode} from 'primevue/api';
import PageHeader from './components/PageHeader.vue'
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import {mapActions, mapState, mapMutations} from "vuex";
import DesignButton from './components/DesignButton.vue'

export default {
  name: "AccountSummaryPage",
  components: {
    PageHeader,
    DesignButton,
    DataTable,
    Column,
    InputText,
  },
  computed: {
    ...mapState('analytics', ['clientAccountSummaryData', 'clientAccountSummaryRequest']),
  },
  data() {
    return {
      filters: ref({
        global: {value: null, matchMode: FilterMatchMode.CONTAINS},
      })
    };
  },
  methods: {
    ...mapActions('analytics', ['getClientAccountSummary', 'deleteOrderedProductWithAccount', 'deleteAccount', 'updateOrderedProduct']),
    getAccount() {
      this.getClientAccountSummary(this.$route.params.accountId)
    },
    deleteProduct(orderedProductId) {
      var confirmed = confirm(this.$t("generic.deleteConfirm"));
      if (confirmed) {
        const {accountId, productId} = {
          accountId: this.$route.params.accountId,
          productId: orderedProductId
        };
        console.log(accountId, productId);
        this.deleteOrderedProductWithAccount({accountId, productId});
      }
    },
    deleteAccountWithConfirm(accountId) {
      var confirmed = confirm(this.$t("generic.deleteConfirm"));
      if (confirmed) {
        this.deleteAccount(accountId);
      }
    },
    updateOrderedProductPayType(event, orderedProduct) {
      var confirmed = confirm(this.$t('generic.changePayType', {
        of: orderedProduct.orderedProductId,
        from: orderedProduct.payType,
        to: event.target.value
      }));
      if (confirmed) {
        console.log(event.target.value, orderedProduct);
        orderedProduct.payType = event.target.value;
        this.updateOrderedProduct(orderedProduct);
      } else {
        this.getAccount();
      }

    }
  },
  mounted() {
    this.getAccount();
  },
  watch: {
    clientAccountSummaryRequest(newReq, oldReq) {
      if (newReq.message != null) {
        this.$router.go(-1);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.date {
  display: inline-block;
  font-size: $font-small;
  margin-right: $default-edge-spacing-l;
}

.red {
  color: $color-pastel-red;
}

.card {
  border-radius: $radius-small;
  background-color: $color-input-back;
  margin-top: $default-edge-spacing-xxl;
  padding: $default-edge-spacing-xl !important;
}

.total-container {
  .header {
    font-size: $font-title-big;
    font-weight: 600;
    padding-left: 10px;
    border-left: 3px solid $color-primary-blue;
  }

  .float-left {
    color: $color-primary-text;
  }

  .float-right {
    color: $color-secondary-text;
  }
}

hr {
  border-color: $color-secondary-text;
  border-radius: 15px;
  opacity: 0.15;
}

.d-card {
  display: flex;
  flex-direction: column
}

</style>