import { organisationService } from "@/services/OrganisationService";
import { toast } from "vue3-toastify";
import { i18n } from "./../lang";

const state = {
    organisation: [],
    printerSettings: [],
    isLoadingOrganisationRequest: false,
    editingOrganisation: {
        loading: false
    }
};

const actions = {
    getOrganisation({ dispatch, commit }) {
        commit(mutations.setLoadingRequest.name, true);
        organisationService
            .getOrganisation()
            .then((response) => {
                commit("setLoadingRequest", false);
                commit(mutations.setOrganisation.name, response.data);
            })
            .catch((error) => {
                commit(mutations.setLoadingRequest.name, false, error);
            });
    },
    updateOrganisation({dispatch, commit, state}, organisation) {
        commit(mutations.setOrganisationEditRequestStarted.name);
        organisationService
          .updateOrganisation(organisation)
          .then((response) => {
            toast.success(i18n.global.t("toast.successSave"));
            commit(mutations.setEditRequestSuccess.name);
            commit(mutations.updateOrganisationList.name, response.data);
          })
          .catch((error) => {
            toast.error(i18n.global.t("toast.errorSave"));
            commit(mutations.setEditRequestError.name, error)
          });
    }
};

const mutations = {
  setLoadingRequest(state, isLoading) {
    state.isLoadingOrganisationRequest = isLoading;
  },
  setOrganisation(state, organisation) {
    state.organisation = organisation;
    organisation.printerSettings === null
      ? (state.printerSettings = {
          kitchenTopSpacing: 0,
          kitchenGroupItems: false,
          charactersPerLine: 0,
        })
      : (state.printerSettings = organisation.printerSettings);
  },
  updateOrganisationList(state, organisation) {
    state.organisation = organisation;
  },
  setOrganisationEditRequestStarted(state) {
    state.editingOrganisation.loading = true;
  },
  setEditRequestSuccess(state) {
    state.editingOrganisation.loading = false;
  },
  setEditRequestError(state, errorMessage) {
    state.editingOrganisation = {
      loading: false,
      success: false,
      message: errorMessage,
    };
  },
};

export const organisation = {
    namespaced: true,
    state,
    actions,
    mutations,
};
