import apiClient from '../helpers/ApiClient'
import { handleError, authHeader } from '../helpers/ApiClient';

export const productGroupService = {
    getProductGroups,
    updateProductGroup,
    addProductGroup,
    deleteProductGroup
}

function getProductGroups() {
    return apiClient.get('/product_group?with_product_data=false', {headers: authHeader()})
        .then((response) => {
            return response.data;
        })
        .catch((error) => handleError(error));
}

function updateProductGroup(productGroup) {
    return apiClient.patch(`/product_group/${productGroup.productGroupId}`, productGroup, {headers: authHeader()})
        .then((response) => {
            return response.data;
        })
        .catch((error) => handleError(error));
}

function addProductGroup(productGroup) {
    return apiClient.post(`/product_group`, productGroup, {headers: authHeader()})
        .then((response) => {
            return response.data;
        })
        .catch((error) => handleError(error));
}

function deleteProductGroup(productGroup) {
    return apiClient.delete(`/product_group/${productGroup.productGroupId}`, {headers: authHeader()})
        .then((response) => {
            return response.data;
        })
        .catch((error) => handleError(error));  
}