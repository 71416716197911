<template>
    <div class="main">
        <div class="form-input-container mb-2">
            <DesignInput v-model:customValue="tableNumber" type="number" :hint="$t('tablePage.tableNumber')" />
        </div>
        <div class="mb-2">
            <design-select :hint="$t('tablePage.nameTypes')" v-model:customValue="selectedTypeGroup" @change="sendSelectedObject">
                <option :selected="true">{{ $t('tablePage.selectType') }}</option>
                <option v-for="item in types" :key="item.id" :value="item.id">{{item.name}}</option>
            </design-select>
        </div>
        <design-select :hint="$t('tablePage.nameZones')" v-model:customValue="selectedZoneGroup" @change="sendSelectedObject">
            <option :selected="true">{{ $t('tablePage.selectZone') }}</option>
            <option v-for="item in zones" :key="item.zoneId" :value="item.zoneId">{{item.name}}</option>
        </design-select>
        <p v-if="tableRequest.message" class="text-status error"> {{ tableRequest.message}}</p>
        <div class="button-container row">
            <design-button class="button col-12 col-md-6" @customClick="setCurrentTable(null)" type="secondary"> {{$t('generic.cancel')}} </design-button >
            <design-button class="button col-12 col-md-6" @customClick="submitTable()" :loading="tableRequest.loading"> {{$t('generic.save')}} </design-button >
        </div>
    </div>
</template>

<script>
import {mapActions, mapState, mapMutations} from "vuex";
import DesignInput from '../components/DesignInput.vue';
import DesignButton from '../components/DesignButton.vue';
import DesignSelect from '../components/DesignSelect.vue';

export default {
    name: 'EditTableDialog',
    components: {
        DesignInput,
        DesignButton,
        DesignSelect,
    },
    data() {
        return {
            tableNumber: 0,
            isNew: false,
            selectedZoneGroup: null,
            selectedTypeGroup: null,
            zonesFiltered: null,
            typesFiltered: null,
        }
    },
    methods: {
        ...mapMutations('table', ['setCurrentTable']),
        ...mapActions('table', ['addTable', 'updateTable']),
        submitTable(){
            const tableToSend = this.currentTable;
            tableToSend.tableNumber = this.tableNumber;
            if (this.zonesFiltered) {
                tableToSend.tableZone = this.zonesFiltered[0]
            }
            if (this.typesFiltered) {
                tableToSend.tableType = this.typesFiltered[0]
            }
            if (this.isNew){
                this.addTable(tableToSend);
            } else{
                this.updateTable(tableToSend);
            }
        },
        sendSelectedObject() {
            this.zonesFiltered = this.zones.filter(item => item.zoneId === Number(this.selectedZoneGroup));
            this.typesFiltered = this.types.filter(item => item.id === Number(this.selectedTypeGroup));
        },
    },
    computed: {
        ...mapState('table', ['tableRequest', 'currentTable', 'zones', 'types'])
    },
    mounted() {
        if (!$.isEmptyObject(this.currentTable)){
            this.isNew = false;
            this.tableNumber = this.currentTable.tableNumber;
            this.selectedZoneGroup = this.currentTable?.tableZone?.zoneId;
            this.selectedTypeGroup = this.currentTable?.tableType?.id;
        }
        else {
            this.isNew = true;
        }
    }

}
</script>

<style lang="scss" scoped>
    .main {
        width: auto;
        padding: 20px 20px;
    }   

    select {
        padding: 10px 20px 10px 20px;
    }

    .form-input-container {
        display: flex;
        flex-direction: column;
        row-gap: $default-edge-spacing-l;
    }

    .button-container {
        margin-top: $default-edge-spacing-l;
        .button {
            width: 100%;

            &:nth-child(1) {
                padding-right: $default-edge-spacing !important;
            }
        }

    }

    .profile-data-container {
        padding: 0px 0px 30px 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        
        .letter-icon {
            color: $color-primary-text;
            background-color: $color-primary-blue;
            border-radius: 150px;
            text-align: center;
            font-weight: 400;
            font-size: 24px;
            height: 48px;
            width: 48px;
            min-width: 48px;
            min-height: 48px;
            padding: 6px;
        }

        .profile-text-container {
            flex-grow: 1;
            padding-left: $default-edge-spacing-l;
            overflow: hidden;
            .title {
                font-size: $font-title;
                color: $color-primary-text;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .subtitle {
                font-size: $font-subtitle;
                color: $color-secondary-text;
            }
        }

        .delete-button {
            min-width: 18px;
            max-width: 18px;
            margin-right: $default-edge-spacing-xl;
            stroke: $color-pastel-red;

        }


    }

    @media (max-width: $screen-md ) {

        .button-container {
            padding-top: $default-edge-spacing;
            .button {
                margin-top: $default-edge-spacing-l;

                &:nth-child(1) {
                    padding-right: 0 !important;
                }
            }

            
        }

    }
</style>