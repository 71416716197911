import apiClient from '../helpers/ApiClient'
import { handleError, authHeader } from '@/helpers/ApiClient';

export const workerService = {
    getWorkersData
}

function getWorkersData(startDateString, endDateString) {
    let startMs = new Date(startDateString).getTime();
    let endMs = new Date(endDateString).getTime();

    return apiClient
        .get("/worker-shifts", {
            params: { start: startMs, end: endMs },
            headers: authHeader(),
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => handleError(error));
}