<template>
  <div>
    <popup-view :isVisible="currentPrinter !== null" @close="setCurrentPrinter(null)">
      <edit-printer-dialog />
    </popup-view>
    <popup-view :isVisible="deleteOpenDialog" @close="deleteOpenDialog = false">
      <confirm-delete-dialog :product="currentSelect" @submitDeleteAction="submitDelete" @cancelDeleteAction="deleteOpenDialog = false"/>
    </popup-view>
    <page-header
      class="header sticky-top"
      @actionClick="setCurrentPrinter({})"
      iconName="printer.svg"
      actionIconName="plus.svg"
      :actionText="$t('printerStrings.addPrinter')"
      :headerTitle="$t('printerStrings.printers')"
    />
    <div class="card-list mt-4" name="list" tag="div">   
      <printer-row v-for="printer in printers" :key="printer.id" :printer="printer" @openPrinter="startEdit" @submitDeleteAction="openDeleteDialog" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex"
import PageHeader from './components/PageHeader.vue'
import PrinterRow from './components/PrinterRow.vue'
import PopupView from './components/PopupView.vue'
import EditPrinterDialog from './dialogs/EditPrinterDialog.vue'
import ConfirmDeleteDialog from './dialogs/ConfirmDeleteDialog.vue';

export default {
    name: "PrintersPage",
    components: {
        PageHeader,
        PrinterRow,
        PopupView,
        EditPrinterDialog,
        ConfirmDeleteDialog
    },
    data() {
      return {
          deleteOpenDialog: false,
          currentSelect: {}
      }
    },
    methods: {
        ...mapActions('printer', ['getPrinters', 'deletePrinter']),
        ...mapMutations('printer', ['setCurrentPrinter']), 
        startEdit(item) {
            this.setCurrentPrinter(item)
        },
        openDeleteDialog(item){
          this.deleteOpenDialog = true;
          this.currentSelect = item;
        },
        submitDelete(){
          this.deletePrinter(this.currentSelect);
          this.deleteOpenDialog = false;
        },
    },
    computed: {
        ...mapState('printer', ['printers', 'currentPrinter'])
    },
    mounted() {
        this.getPrinters();
    }
}
</script>

<style>

</style>