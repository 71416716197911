<template>
  <div class="main">
    <div class="form-input-container" v-if="user.orgId === currentProduct.orgId || !currentProduct.orgId">
      <DesignInput
          v-model:customValue="name"
          :error="hasEmptyValue(name)"
          :hint="$t('productPage.name')"
      />
      <design-select
          :error="hasEmptyValue(selectedProductGroupId)"
          :hint="$t('productPage.productGroup')"
          v-model:customValue="selectedProductGroupId"
      >
        <option
            v-for="item in productGroups"
            :key="item.productGroupId"
            :value="item.productGroupId"
        >
          {{ item.name }}
        </option>
      </design-select>

      <div class="row">
        <div class="col-6">
          <div class="mr-1">
            <DesignInput
                v-model:customValue="priceVatInclIn"
                type="number"
                :hint="$t('productPage.priceVatInclIn')"
            />
          </div>
        </div>
        <div class="col-6">
          <div class="ml-1">
            <design-select
                :error="hasEmptyValue(selectedTvaInGroup)"
                :hint="$t('productPage.vatIn')"
                v-model:customValue="selectedTvaInGroup"
            >
              <option
                  v-for="item in productTvaGroup"
                  :key="item.value"
                  :value="item.value"
              >
                {{ item.name }}
              </option>
            </design-select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="mr-1">
            <DesignInput
                v-model:customValue="priceVatInclOut"
                type="number"
                :hint="$t('productPage.priceVatInclOut')"
            />
          </div>
        </div>
        <div class="col-6">
          <div class="ml-1">
            <design-select
                :error="hasEmptyValue(selectedTvaOutGroup)"
                :hint="$t('productPage.vatOut')"
                v-model:customValue="selectedTvaOutGroup"
            >
              <option
                  v-for="item in productTvaGroup"
                  :key="item.value"
                  :value="item.value"
              >
                {{ item.name }}
              </option>
            </design-select>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <div class="mr-1">
            <DesignInput
                v-model:customValue="stock"
                type="number"
                :hint="$t('productPage.stock')"
            />
          </div>
        </div>
        <div class="col-6">
          <div class="ml-1">
            <DesignInput
                v-model:customValue="orderIndex"
                type="number"
                :hint="$t('productPage.orderIndex')"
                :tooltip-text="$t('productPage.orderTooltip')"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <div class="mr-1">
            <design-select
                :fullSize="true"
                :hint="$t('productPage.timedproduct')"
                v-model:customValue="timedProduct"
                :tooltip-text="$t('productPage.timedProductTooltip')"
            >
              <option :value="true">{{ $t("generic.yes") }}</option>
              <option :value="false">{{ $t("generic.no") }}</option>
            </design-select>
          </div>
        </div>
        <div class="col-6">
          <div class="ml-1">
            <design-select
                :fullSize="true"
                :hint="$t('productPage.allowDecimal')"
                v-model:customValue="allowDecimalUnits"
                :tooltip-text="$t('productPage.allowDecimalTooltip')"
            >
              <option :value="true">{{ $t("generic.yes") }}</option>
              <option :value="false">{{ $t("generic.no") }}</option>
            </design-select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="mr-1">
            <design-select
                :fullSize="true"
                :hint="$t('productPage.dynamicPrice')"
                v-model:customValue="dynamicPrice"
                :tooltip-text="$t('productPage.dynamicPriceTooltip')"
            >
              <option :value="true">{{ $t("generic.yes") }}</option>
              <option :value="false">{{ $t("generic.no") }}</option>
            </design-select>
          </div>
        </div>
        <div class="col-6">
          <div class="ml-1">
            <design-select
                :fullSize="true"
                :hint="$t('productPage.weightPrice')"
                v-model:customValue="weightPrice"
                :tooltip-text="$t('productPage.weightPriceTooltip')"
            >
              <option :value="true">{{ $t("generic.yes") }}</option>
              <option :value="false">{{ $t("generic.no") }}</option>
            </design-select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="mr-1">
            <div class="input-container">
              <design-select
                  :fullSize="true"
                  :hint="$t('productGroupPage.userHidden')"
                  v-model:customValue="userHidden"
                  :tooltip-text="$t('productPage.userHiddenTooltip')">
                <option :value="true">{{ $t("generic.yes") }}</option>
                <option :value="false">{{ $t("generic.no") }}</option>
              </design-select>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="ml-1">
            <div class="input-container">
              <design-select
                  :fullSize="true"
                  :hint="$t('productGroupPage.webHidden')"
                  v-model:customValue="webHidden"
                  :tooltip-text="$t('productPage.webHiddenTooltip')">
                <option :value="true">{{ $t("generic.yes") }}</option>
                <option :value="false">{{ $t("generic.no") }}</option>
              </design-select>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 input-container">
        <DesignInput
            v-model:customValue="promoPercent"
            type="number"
            :hint="$t('productPage.promo_percent')"
        />
      </div>

      <div class="col-12">
        <div class="mt-2 form-input-container">
          <design-input-color-list :hint="$t('generic.color')" :custom-value="productColor"
                                   v-model:custom-value="productColor"/>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-11">
          <div v-if="currentLanguage === 'FR'">
            <DesignInputArea
                class="text-area-description"
                v-model:customValue="descriptionFR"
                :hint="$t('productPage.description')"
            />
          </div>
          <div v-if="currentLanguage === 'NL'">
            <DesignInputArea
                class="text-area-description"
                v-model:customValue="descriptionNL"
                :hint="$t('productPage.description')"
            />
          </div>
          <div v-if="currentLanguage === 'EN'">
            <DesignInputArea
                class="text-area-description"
                v-model:customValue="descriptionEN"
                :hint="$t('productPage.description')"
            />
          </div>
        </div>
        <div class="col-1 mt-4">
          <button
              class="bread-crumb-base m-1"
              @click="currentLanguage = 'FR'"
              :class="{ 'active-language': currentLanguage === 'FR' }">
            FR
          </button>
          <button
              class="bread-crumb-base m-1"
              @click="currentLanguage = 'NL'"
              :class="{ 'active-language': currentLanguage === 'NL' }">
            NL
          </button>
          <button
              class="bread-crumb-base m-1"
              @click="currentLanguage = 'EN'"
              :class="{ 'active-language': currentLanguage === 'EN' }">
            EN
          </button>
        </div>

      </div>

      <div class="row">
        <div class="col-12">
          <DesignInputArea
              class="text-area-barcode"
              v-on:update:customValue="acceptEmptyCodebaresValue"
              v-model:customValue="productBarcodes"
              :hint="$t('productPage.codeBarre')"
          />
        </div>
      </div>
      <div class="row">
        <DesignInput
            v-model:customValue="imageUrl"
            :hint="$t('productPage.imageUrl')"
        />
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <design-multiple-options
              @optionClicked="toggleAllowedOptionGroup"
              :hint="$t('productPage.productGroupOptions')"
              :options="productGroupsExcludingCurrent"
              valuePropName="name"
              valuePropId="productGroupId"
              :selectedItems="allowedOptionGroups"
          />
        </div>
        <div class="col-12 mt-2">
          <design-select
              :fullSize="true"
              :hint="$t('productPage.multiChoice')"
              v-model:customValue="freeOptionGroupsMultiChoice"
          >
            <option :value="true">{{ $t("generic.yes") }}</option>
            <option :value="false">{{ $t("generic.no") }}</option>
          </design-select>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <design-multiple-options
              @optionClicked="toggleAllowedOptionGroupPaid"
              :hint="$t('productPage.productGroupOptionsPaid')"
              :options="productGroupsExcludingCurrent"
              valuePropName="name"
              valuePropId="productGroupId"
              :selectedItems="allowedOptionGroupsPaid"
          />
        </div>
        <div class="col-12 mt-2">
          <design-select
              :fullSize="true"
              :hint="$t('productPage.multiChoice')"
              v-model:customValue="paidOptionGroupsMultiChoice"
          >
            <option :value="true">{{ $t("generic.yes") }}</option>
            <option :value="false">{{ $t("generic.no") }}</option>
          </design-select>
        </div>
      </div>
    </div>
    <div class="form-input-container" v-if="user.orgId !== currentProduct.orgId && currentProduct.orgId">
      <div class="row">
        <div class="col-12">
          <div class="mr-1">
            <DesignInput
                v-model:customValue="stock"
                type="number"
                :hint="$t('productPage.stock')"
            />
          </div>
        </div>
        <div class="col-12 mt-3">
          <DesignInput
              v-model:customValue="promoPercent"
              type="number"
              :hint="$t('productPage.promo_percent')"
          />
        </div>
      </div>

    </div>

    <transition name="fade">
      <p v-if="productRequest.message" class="text-status error">
        {{ productRequest.message }}
      </p>
    </transition>
    <div class="button-container pb-5">
      <design-button class="button" @customClick="cancel()" type="secondary">
        {{ $t("generic.cancel") }}
      </design-button>
      <design-button
          class="button"
          @customClick="submitGroup()"
          :loading="productRequest.loading"
      >
        {{ $t("generic.save") }}
      </design-button>
    </div>
    &nbsp;
  </div>
</template>

<script>
import DesignInput from "../components/DesignInput.vue";
import DesignButton from "../components/DesignButton.vue";
import DesignSelect from "../components/DesignSelect.vue";
import {mapMutations, mapActions, mapState} from "vuex";
import DesignMultipleOptions from "../components/DesignMultipleOptions.vue";
import DesignInputArea from "@/views/components/DesignInputArea.vue";
import {organisation} from "@/store/organisation.module";
import DesignInputColorList from "@/views/components/DesignInputColorList.vue";

export default {
  name: "EditProductDialog",
  components: {
    DesignInputColorList,
    DesignInputArea,
    DesignInput,
    DesignButton,
    DesignSelect,
    DesignMultipleOptions
  },
  data() {
    return {
      name: "",
      priceVatInclIn: 0,
      promoPercent: 0,
      priceVatInclOut: 0,
      productBarcodes: [],
      description: "",
      userHidden: false,
      webHidden: false,
      stock: 0,
      orderIndex: 0,
      timedProduct: false,
      weightPrice: false,
      allowDecimalUnits: false,
      dynamicPrice: false,
      selectedProductGroupId: "",
      selectedProductGroup: {},
      productTvaGroup: [
        {name: "0%", value: 0},
        {name: "6%", value: 6},
        {name: "12%", value: 12},
        {name: "21%", value: 21},
      ],
      selectedTvaInGroup: {},
      selectedTvaOutGroup: {},
      optionProductGroups: [],
      allowedOptionGroups: [],
      allowedOptionGroupsPaid: [],
      productGroupsExcludingCurrent: [],
      freeOptionGroupsMultiChoice: false,
      paidOptionGroupsMultiChoice: false,
      isNew: false,
      color: "",
      productColor: "",
      imageUrl: "",
      currentLanguage: 'FR',  // Default language
      descriptionFR: '',
      descriptionNL: '',
      descriptionEN: '',
    };
  },
  methods: {
    ...mapMutations("product", ["setCurrentProduct"]),
    ...mapActions("productGroup", ["getProductGroups"]),
    ...mapActions("product", ["updateProduct", "addProduct"]),
    acceptEmptyCodebaresValue(value) {
      this.productBarcodes = value.length > 0 ? [value] : [];
    },
    submitGroup() {
      const productToSend = this.currentProduct;
      productToSend.name = this.name;
      productToSend.vatIn = this.selectedTvaInGroup;
      productToSend.vatOut = this.selectedTvaOutGroup;
      productToSend.priceVatInclIn = Number(this.priceVatInclIn).toFixed(2);
      productToSend.priceVatInclOut = Number(this.priceVatInclOut).toFixed(2);
      if (this.productBarcodes.includes(",")) {
        productToSend.productBarcodes = this.productBarcodes
            .split(",")
            .filter((barcode) => barcode.length > 0)
            .map((barcode) => parseInt(barcode.trim(), 10));
      } else {
        if (!Array.isArray(this.productBarcodes)) {
          productToSend.productBarcodes =
              this.productBarcodes.length > 0
                  ? [parseInt(this.productBarcodes)]
                  : [];
        }
      }

      productToSend.stock = Number(this.stock);
      productToSend.orderIndex = Number(this.orderIndex);
      productToSend.productGroupId = this.selectedProductGroup.productGroupId;
      productToSend.productGroup = this.selectedProductGroup;
      productToSend.timedProduct = this.timedProduct;
      productToSend.weightPrice = this.weightPrice;
      productToSend.allowDecimalUnits = this.allowDecimalUnits;
      productToSend.dynamicPrice = this.dynamicPrice;
      productToSend.allowedOptionGroups = this.createAllowedOptionsToSend();
      productToSend.color = this.productColor;
      productToSend.imageUrl = this.imageUrl;
      productToSend.userHidden = this.userHidden;
      productToSend.webHidden = this.webHidden;
      productToSend.promoPercent = this.promoPercent;

      if(this.descriptionFR !== null && this.descriptionFR !== ""
          && this.descriptionNL !== null && this.descriptionNL !== ""
          && this.descriptionEN !== null && this.descriptionEN !== ""){
          productToSend.description = {
            en: this.descriptionEN || "", // Replace with your specific logic
            fr: this.descriptionFR || "",  // Replace with your specific logic
            nl: this.descriptionNL || ""   // Replace with your specific logic
          };
      }else{
        productToSend.description = null;
      }

      if (this.isNew) {
        this.addProduct(productToSend);
      } else {
        this.updateProduct(productToSend);
      }
    },
    createAllowedOptionsToSend() {
      var generatedAllowedOptionsToSend = [];
      this.allowedOptionGroups.forEach((el) => {
        var optionGroup = {};
        optionGroup.productGroup = el;
        optionGroup.multiOptionAllowed = this.freeOptionGroupsMultiChoice;
        optionGroup.optionType = "FREE";
        generatedAllowedOptionsToSend.push(optionGroup);
      });
      this.allowedOptionGroupsPaid.forEach((el) => {
        var optionGroup = {};
        optionGroup.productGroup = el;
        optionGroup.multiOptionAllowed = this.paidOptionGroupsMultiChoice;
        optionGroup.optionType = "PAID";
        generatedAllowedOptionsToSend.push(optionGroup);
      });
      return generatedAllowedOptionsToSend;
    },
    cancel() {
      this.setCurrentProduct(null);
    },
    hasEmptyValue(inputValue) {
      if (
          this.productRequest.message &&
          (inputValue === "" || inputValue === 0)
      ) {
        return true;
      }
      return false;
    },
    toggleAllowedOptionGroup(option) {
      const foundArray = this.allowedOptionGroups.filter(
          (e) => e.productGroupId == option.productGroupId
      );
      if (foundArray.length > 0) {
        this.allowedOptionGroups = this.allowedOptionGroups.filter(
            (x) => x.productGroupId !== option.productGroupId
        );
      } else {
        this.allowedOptionGroups.push(option);
      }
    },
    toggleAllowedOptionGroupPaid(option) {
      const foundArray = this.allowedOptionGroupsPaid.filter(
          (e) => e.productGroupId == option.productGroupId
      );
      if (foundArray.length > 0) {
        this.allowedOptionGroupsPaid = this.allowedOptionGroupsPaid.filter(
            (x) => x.productGroupId !== option.productGroupId
        );
      } else {
        this.allowedOptionGroupsPaid.push(option);
      }
    },
    filterOptionGroups(currentProductGroupId) {
      if (currentProductGroupId == null) {
        currentProductGroupId = this.selectedProductGroupId;
      }
      //update allowedOptionGroups based on selected group -> cant add options that's the same as parent group
      const updatedOptionGroups = this.productGroups.filter(function (element) {
        return element.productGroupId != currentProductGroupId;
      });

      this.productGroupsExcludingCurrent = updatedOptionGroups;

      //remove any selected groups that are same as parent group
      this.allowedOptionGroups = this.allowedOptionGroups.filter(function (el) {
        return el.productGroupId != currentProductGroupId;
      });

      this.allowedOptionGroupsPaid = this.allowedOptionGroupsPaid.filter(
          function (el) {
            return el.productGroupId != currentProductGroupId;
          }
      );
    },
  },
  computed: {
    organisation() {
      return organisation
    },
    ...mapState("productGroup", ["productGroups"]),
    ...mapState("product", ["currentProduct", "productRequest"]),
    ...mapState("account", ["user"]),
  },
  mounted() {
    if (!$.isEmptyObject(this.currentProduct)) {
      this.isNew = false;
      this.name = this.currentProduct.name;
      this.selectedTvaInGroup = this.currentProduct.vatIn;
      this.selectedTvaOutGroup = this.currentProduct.vatOut;
      this.priceVatInclIn = this.currentProduct.priceVatInclIn;
      this.priceVatInclOut = this.currentProduct.priceVatInclOut;
      this.productBarcodes = this.currentProduct.productBarcodes;
      this.stock = this.currentProduct.stock;
      this.orderIndex = this.currentProduct.orderIndex;
      this.selectedProductGroup = this.currentProduct.productGroup;
      this.selectedProductGroupId = this.currentProduct.productGroup.productGroupId;
      this.timedProduct = this.currentProduct.timedProduct;
      this.weightPrice = this.currentProduct.weightPrice;
      this.allowDecimalUnits = this.currentProduct.allowDecimalUnits;
      this.dynamicPrice = this.currentProduct.dynamicPrice;
      this.imageUrl = this.currentProduct.imageUrl;
      this.userHidden = this.currentProduct.userHidden;
      this.webHidden = this.currentProduct.webHidden;
      this.promoPercent = this.currentProduct.promoPercent;
      this.orgId = this.currentProduct.orgId;
      this.descriptionNL = this.currentProduct?.description?.['nl'];
      this.descriptionFR = this.currentProduct?.description?.['fr'];
      this.descriptionEN = this.currentProduct?.description?.['en'];
      var paidGroupsFromOptions = [];
      var freeGroupsFromOptions = [];
      this.currentProduct.allowedOptionGroups.forEach((el) => {
        if (el.optionType == "FREE") {
          freeGroupsFromOptions.push(el.productGroup);
          this.freeOptionGroupsMultiChoice = el.multiOptionAllowed;
        } else if (el.optionType == "PAID") {
          paidGroupsFromOptions.push(el.productGroup);
          this.paidOptionGroupsMultiChoice = el.multiOptionAllowed;
        }
      });
      this.allowedOptionGroups = freeGroupsFromOptions;
      this.allowedOptionGroupsPaid = paidGroupsFromOptions;
      this.productColor = this.currentProduct.color;
    } else {
      this.isNew = true;
    }

    if (this.productGroups.length === 0) {
      this.getProductGroups();
    } else {
      this.filterOptionGroups(this.selectedProductGroupId);
    }
  },
  watch: {
    productGroups(newProductGroups, oldProductGroups) {
      if (newProductGroups.length > oldProductGroups.length) {
        this.filterOptionGroups();
      }
    },
    selectedProductGroupId(newP, oldP) {
      if (this.productGroups && newP) {
        let matchedGroup = this.productGroups.filter((obj) => {
          if (obj.productGroupId == newP) {
            this.selectedTvaInGroup = obj.vatIn;
            this.selectedTvaOutGroup = obj.vatOut;
          }
          return obj.productGroupId == newP;
        })[0];
        if (matchedGroup) {
            this.selectedProductGroup = matchedGroup;
        }
        if (this.isNew) {
          this.vatIn = this.selectedProductGroup.vatIn;
          this.vatOut = this.selectedProductGroup.vatOut;
        }
        this.filterOptionGroups(newP);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-check {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  input {
    margin-right: $default-edge-spacing;
  }
}

.main {
  width: auto;
  padding: 20px 20px;
}

select {
  padding: 10px 20px 10px 20px;
}

.form-input-container {
  display: flex;
  flex-direction: column;
  row-gap: $default-edge-spacing-l;
}

.button-container {
  display: flex;
  justify-content: stretch;
  align-content: stretch;
  column-gap: $default-edge-spacing-l;
  margin-top: $default-edge-spacing-xxl;

  .button {
    width: 100%;
  }
}

.colorPicker-style {
  padding-right: 10px;
}

.input-hint-color {
  padding: 0 !important;
  font-size: 13px;
  font-weight: normal;
  color: $color-secondary-text;
  opacity: 0.8;
  margin: 0 0 8px 8px;
}

.text-area-barcode {
  height: 100px;
}
.text-area-description{
  height: 155px;
}

.profile-data-container {
  padding: 0px 0px 30px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .letter-icon {
    color: $color-primary-text;
    background-color: $color-primary-blue;
    border-radius: 150px;
    text-align: center;
    font-weight: 400;
    font-size: 24px;
    height: 48px;
    width: 48px;
    min-width: 48px;
    min-height: 48px;
    padding: 6px;
  }

  .profile-text-container {
    flex-grow: 1;
    padding-left: $default-edge-spacing-l;
    overflow: hidden;

    .title {
      font-size: $font-title;
      color: $color-primary-text;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .subtitle {
      font-size: $font-subtitle;
      color: $color-secondary-text;
    }
  }

  .delete-button {
    min-width: 18px;
    max-width: 18px;
    margin-right: $default-edge-spacing-xl;
    stroke: $color-pastel-red;
  }
}
.active-language {
  background-color: #8393F4; /* Change this to your desired color */
  color: white; /* Text color */
}

@media (max-width: $screen-md) {
  .button-container {
    justify-content: center;
    flex-direction: column;
    padding-top: $default-edge-spacing;

    .button {
      margin-top: $default-edge-spacing-l;

      &:nth-child(1) {
        padding-right: 0 !important;
      }
    }
  }

  .text-area-barcode {
    height: 100px;
  }


  .color-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }

  .color-button {
    width: 31px; /* Default size for mobile */
    height: 31px; /* Default size for mobile */
    border: 2px solid transparent;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
    padding: 1px;
    transition: border-color 0.3s, box-shadow 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .color-button.selected {
    box-shadow: 0 0 0 3px #8393F4; /* Change this to desired highlight style */
  }

  @media (min-width: 768px) {
    .color-button {
      width: 52px; /* Adjusted size for desktop */
      height: 45px; /* Adjusted size for desktop */
      padding: 4px; /* Adjust padding if necessary */
    }
  }
}
</style>