<template>
  <div class="login-page">

    <div class="login-container">
      <div class="left">
        <img :src="require('@/assets/illustrations/secure.svg')" alt="secure">
        <p class="left-title">{{ $t('loginPage.welcome_back') }}</p>
        <p class="left-subtitle">{{ $t('loginPage.login_page_subtitle') }}</p>
      </div>
      <div class="right">
        <p class="title">{{ $t('loginPage.login_page_form_title') }}</p>
        <p class="subtitle">{{ $t('loginPage.login_page_form_subtitle') }}</p>

        <design-input class="input" data-index="1" name="username" v-model:customValue="username" type="text" :hint="$t('generic.username')" ref="username" @keypress.enter="submitLogin"/>
        <design-input class="input" data-index="2" name="password" v-model:customValue="password" type="password" :hint="$t('generic.password')" ref="password" @keypress.enter="submitLogin"/>
        <design-input class="org-id input" data-index="3" name="org_id" v-model:customValue="orgId" type="text" :hint="$t('generic.org_id')" ref="org_id" @keypress.enter="submitLogin"/>
        <transition name="fade" mode="out-in">
          <p v-if="accountState.loginMessage" class="error-msg">{{ accountState.loginMessage }}</p>
        </transition>
        <design-button class="login-btn" :loading="accountState.isLoadingRequest" @customClick="submitLogin">{{ $t('loginPage.login_button') }}</design-button>
      </div>
    </div>

  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import DesignButton from './components/DesignButton.vue';
import DesignInput from './components/DesignInput.vue';

export default {
  name: 'LoginPage',
  components: {
    DesignButton,
    DesignInput
  },
  data() {
    return {
      username: '',
      password: '',
      orgId: ''
    }
  },
  computed: {
    ...mapState({
      accountState: 'account'
    })
  },
  methods: {
    ...mapActions('account', {
      login: 'login'
    }),
    submitLogin(){
      this.login({
        username: this.username,
        password: this.password,
        orgId: this.orgId
      });
    }
  }
}
</script>

<style lang="scss" scoped>
  .login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    .login-container {
      display: flex;
      flex-direction: row;
      background-color: $color-card-back;
      border-radius: 20px;
      box-shadow: $shadow-soft;
      margin-left: 15%;
      margin-right: 15%;
      flex-basis: 0;
      flex-grow: 0.5;

      .left {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: $color-primary-blue-transparent;
        padding: 50px 80px 50px 80px;
        border-radius: 20px 0 0 20px;

        .left-title {
          font-size: 30px;
          font-weight: bold;
          color: $color-primary-dark-text;
          margin-top: 30px;
          text-align: center;
        }

        .left-subtitle {
          margin-top: $default-edge-spacing;
          font-size: 14px;
          font-weight: normal;
          color: $color-secondary-dark-text;
          text-align: center;
        }
      }
      .right {
        width: 50%;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 50px 80px 50px 80px;

        .error-msg {
          font-size: $font-small;
          color: $color-secondary-text;
        }
        .title {
          text-align: center;
          font-size: $font-title-big;
          font-weight: 400;
          margin: 0 0;
        }

        .subtitle {
          text-align: center;
          font-size: $font-small;
          color: $color-secondary-text;
          margin-bottom: $default-edge-spacing-xxl;
          margin-top: 0;
        }

        .input {
          margin-bottom: $default-edge-spacing-l;
          width: 80%;
        }

        .login-btn {
          margin-top: 30px;
        }


      }


    }
  }

  @media (max-width: $screen-md ) {
    .login-container {
      width: 98% !important;
      .left {
        display: none !important;
      }

      .right {
        width: 100% !important;
        padding: 25px 30px 35px 25px !important;

      }
      
    }
  }
</style>
