import apiClient from '../helpers/ApiClient'
import { handleError, authHeader } from '../helpers/ApiClient';

export const userService = {
    getAllUsers,
    deleteUser,
    createUser,
    updateUser,
};

function getAllUsers(){
    return apiClient.get("/user", {headers: authHeader()})
    .then((response) => {
        return response.data
    })
    .catch((error) => handleError(error));
}

function deleteUser(userId){
    return apiClient.delete(`/user/${userId}`, { headers: authHeader()})
        .then( (response) => {
            return response.data
        })
        .catch((error) => handleError(error));
}

function createUser(usernameVal, passwordVal, typeVal, pincode, terminalIp){
    let createUserData = {
        username: usernameVal,
        type: typeVal,
        password: passwordVal,
        pincode: pincode,
        terminalIp: terminalIp
    };
    return apiClient.post("/user/create", createUserData, { headers: authHeader()})
        .then( (response) => {
            return response.data
        })
        .catch((error) => handleError(error));
}

function updateUser(user){
    return apiClient.patch(`/user/${user.userId}`, user, {headers: authHeader()})
        .then( (response) => {
            return response.data
        }) 
        .catch((error) => handleError(error));
}