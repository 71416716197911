<template>
  <div class="input-container">
      <p class="input-hint"> {{ hint }} </p>
      <div class="select-wrapper">

      </div>
      <slot></slot>
  </div>
</template>

<script>
export default {
    name: "DesignMultiSelectWrapper",
    props: {
        'hint': {
            type: String,
            default: ""
        },
    }
}
</script>

<style lang="scss" scoped>
    .input-container {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        .input-hint {
            padding: 0 !important;
            font-size: 12px;
            font-weight: normal;
            color: $color-secondary-text;
            opacity: 0.8;
            margin: 0 0 8px 8px;
        }

        input {
            width: 100%;
            padding: 10px 20px 10px 20px;
            &.error {
                outline: 1px solid rgba($color-pastel-red, .3);;
                box-shadow: 0px 0px 15px rgba($color-pastel-red, .3);
            }
            &:disabled {
                opacity: 0.3;
            }
        }

        .select-wrapper {
            position: relative;
            &:after {
                pointer-events: none;
                content: url('@/assets/icons/arrow.svg');
                width: 14px;
                fill: #fff;
                position: absolute;
                top: 10px;
                right: 20px;
            }
        }


    }
</style>