<template>
  <div >
     <button @click="handleCustomClick($event.target.value)" 
     ref="buttonElement" 
     :class="type"> 
        <div class="outer">
            <div :class="loading ? 'invisible' : ''">
                <slot></slot> 
            </div>
            <div ref="animation" :class="loading ? 'visible' : 'invisible'">
                <lottie-animation
                    path="animations/loading.json"
                    :autoPlay="true"
                />
            </div>

        </div>
        
     </button>
  </div>
</template>

<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs

export default {
    name: "DesignButton",
    emits: ['customClick'],
    components: {
        LottieAnimation
    },
    props: {
        'type': {
            type: String,
            default: ""
        },
        'loading': {
            type: Boolean,
            default: false
        },
    },
    methods: {
        handleCustomClick(e){
            if (!this.loading){
                this.$emit('customClick', e);
            }

        }
    },
    data() {
        return {
            loadingHeight : 0
        }
    },
    mounted() {
        this.loadingHeight = $(this.$refs.buttonElement).height();
        $(this.$refs.animation).height(this.loadingHeight);
    }
}
</script>

<style lang="scss" scoped>
    .loading-anim {
        margin: 0 !important;
    }

    button {
        width: 100%;

        &.delete {
            background-color: $color-pastel-red !important;
            color: $color-secondary-text;
        }
    }

    .invisible {
        opacity: 0
    }

    .visible {
        opacity: 1;
    }

    .outer {
        display: grid;
        grid-template: 1fr / 1fr;
        place-items: center;
    }
    .outer > * {
        grid-column: 1 / 1;
        grid-row: 1 / 1;
    }
</style>