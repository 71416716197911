import { invoiceService } from "@/services/InvoiceService";

const state = {
    invoiceData: [],
    clientRequestMessage: "",
    isLoadingStockRequest: false
};

const actions = {
    getInvoiceData({dispatch, commit}, { startDate, endDate }){
        commit(mutations.setLoadingRequest.name, true);
        invoiceService.getInvoices(startDate, endDate)
            .then((response) => {
                commit('setLoadingRequest', false);
                commit(mutations.setInvoiceData.name, response.data);
            })
            .catch((error) => {
                commit(mutations.setLoadingRequest.name, false, error);
            });
    },
    getDownloadInvoice({dispatch, commit}, invoiceId){
        commit(mutations.setLoadingRequest.name, true);
        invoiceService.getDownloadInvoice(invoiceId)
            .then((response) => {
                const urlOpen = response?.data?.downloadUrl;
                window.open(urlOpen)
            })
            .catch((error) => {
                commit("setLoadingRequest", { loading: false });
            });
    },
    setInvoicePaid({dispatch, commit}, invoiceId){
        commit(mutations.setLoadingRequest.name, true);
        invoiceService.setInvoiceToPaid(invoiceId)
            .then((response) => {
                commit('setLoadingRequest', false);
            })
            .catch((error) => {
                commit(mutations.setLoadingRequest.name, false, error);
            });
    }
}

const mutations = {
    setLoadingRequest(state, isLoading, message=''){
        state.invoiceRequestMessage = message;
        state.isLoadingInvoiceRequest = isLoading;
    },
    setInvoiceData(state, invoiceData){
        state.invoiceData = invoiceData;
    },
}


export const invoice = {
    namespaced: true,
    state,
    actions,
    mutations
};