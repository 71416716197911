<script>
export default {
    props: ['text']
}
</script>

<template>
  <p class="side-nav-header">{{text}}</p>
</template>

<style lang="scss" scoped>
    p {
        padding: 0 !important;
        font-size: 12px;
        font-weight: normal;
        color: $color-secondary-text;
        opacity: 0.8;
        margin: 0 0 0 8px;
    }
</style>