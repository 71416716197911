import { createStore } from "vuex";
import { account } from "./account.module";
import { global } from "./global.module";
import { user } from "./user.module";
import { productGroup } from "./productgroup.module";
import { product } from "./product.module";
import { table } from "./table.module";
import { analytics } from "./analytics.module";
import { printer } from "./printer.module";
import { organisation } from "./organisation.module";
import { shift } from "./shift.module";
import { stock } from "@/store/stock.module";
import { client } from "@/store/client.module";
import { invoice } from "@/store/invoice.module";
import {workerShift} from "@/store/workerShift.module";


export default createStore({
  modules: {
    account,
    global,
    user,
    productGroup,
    product,
    table,
    analytics,
    printer,
    organisation,
    shift,
    stock,
    client,
    invoice,
    workerShift
  },
});
