<template>
    <div>
        <popup-view :isVisible="userState.editingUser != null" @close="setUserEdit(null)">
            <edit-user-dialog />
        </popup-view>
        <popup-view :isVisible="userState.isAddingUser" @close="setAddingUser(false)">
            <create-user-dialog />
        </popup-view>
        <popup-view :isVisible="deleteOpenDialog" @close="deleteOpenDialog = false">
            <confirm-delete-dialog :user="currentSelect" @submitDeleteAction="submitDelete" @cancelDeleteAction="deleteOpenDialog = false"/>
        </popup-view>
        <page-header
            @actionClick="setAddingUser(true)" 
            actionIconName="plus.svg" 
            iconName="user.svg" 
            :actionText="$t('generic.create')" 
            :headerTitle="$t('navBar.users')"/>

        <DataTable
          :value="userState.users"
          v-model:filters="filters"
          table-style="min-width: 50rem"
          :loading="loading"
          :global-filter-fields="['username', 'type', 'pincode']"
        >
          <template #header>
            <div class="flex justify-content-end" style="margin-top: 15px">
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
              </span>
            </div>
          </template>
          <template #empty> {{$t('productGroupPage.noFundResultTable')}} </template>
          <template #loading> {{$t('productGroupPage.loadingResultTable')}} </template>
            <Column v-if="isSuperUser" field="userId" header="Id" sortable>
                <template #body="{ data }">
                    {{data.userId}}
                </template>
            </Column>
          <Column field="username" header="Username" sortable></Column>
          <Column field="type" header="Type" sortable>
            <template #body="{ data }">
              <span class="default-text">{{ data.type }}</span>
            </template>
          </Column>
          <Column field="pincode" header="Pincode" sortable>
            <template #body="{ data }">
              <span class="default-text">{{ data.pincode }}</span>
            </template>
          </Column>
          <Column field="pincode" header="Pincode" sortable>
            <template #body="{ data }">
              <span class="default-text">{{new Date(data.creationTimestamp).toLocaleDateString()}}</span>
            </template>
          </Column>
          <Column field="quantity" header="Actions">
            <template #body="{data}">
              <div class="edit-row-btn">
                  <button class="delete" @click="openDeleteDialog(data)" ><inline-svg :src="require('@/assets/icons/trash.svg')" /></button>
                  <button @click="setUserEdit(data)" ><inline-svg :src="require('@/assets/icons/edit.svg')" /></button>
              </div>
            </template>
          </Column>
        </DataTable>
    </div>
</template>

<script>
import { ref } from 'vue';
import { mapActions, mapState, mapMutations } from "vuex";
import { FilterMatchMode } from 'primevue/api';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import PageHeader from './components/PageHeader.vue';
import PopupView from './components/PopupView.vue';
import EditUserDialog from './dialogs/EditUserDialog.vue';
import CreateUserDialog from './dialogs/CreateUserDialog.vue';
import ConfirmDeleteDialog from './dialogs/ConfirmDeleteDialog.vue';

export default {
    name: "UsersPage",
    components: {
        DataTable,
        Column, 
        InputText,
        PopupView,
        EditUserDialog,
        CreateUserDialog,
        ConfirmDeleteDialog,
        PageHeader
    },
    data() {
        return {
            filters: ref({
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            }),
            deleteOpenDialog: false,
            currentSelect: {}
        }
    },
    computed: {
        ...mapState({
            userState: 'user'
        }),
        ...mapState('account', ['isSuperUser']),
    },
    methods: {
        ...mapActions('user', ['updateUser', 'deleteUser']),
        ...mapMutations('user', ['setAddingUser', 'setUserEdit']),
        ...mapMutations('productGroup', ['setEditingGroup']),
        ...mapActions('user', {
            getAllUsers: 'getAllUsers'
        }),
        getUsers(){
            this.getAllUsers();
        },
        openDeleteDialog(item){
            this.deleteOpenDialog = true;
            this.currentSelect = item;
        },
        submitDelete(){
            this.deleteUser(this.currentSelect);
            this.deleteOpenDialog = false;
        },
    },
    created() {
        this.getUsers();
    }
}
</script>

<style lang="scss" scoped>




</style>