<template>
  <div>
    <page-header 
      :headerTitle="$t('dashboard.takeawayText')"
      iconName="table.svg" />

    <transition-group class="card-list mt-4" name="list" tag="div">
        <table-row v-for="(takeaway, index) in takeaways" :key="takeaway.tableId" :order="index + 1" :isTakeaway="true" :isDashboard="true" :item="takeaway" @openAccount="openAccount" />
    </transition-group>
  </div>
</template>

<script>
import PageHeader from './components/PageHeader.vue';
import {mapState, mapActions} from 'vuex';
import TableRow from './components/TableRow.vue';

export default {
    name: "OpenTakeaway",
    components: {
      PageHeader,
      TableRow,
    },
    data() {
      return {
          deleteOpenDialog: false,
          currentSelect: {}
      }
    },
    methods: {
        ...mapActions('table', ['getTakeaway']),
        openAccount(account) {
            this.$router.push({
                name: "AccountSummary",
                params: { accountId: account },
            });
        },
    },
    computed: {
      ...mapState('table', ['takeaways'])
    },
    mounted() {
      this.getTakeaway();
    }
}
</script>

<style>

</style>