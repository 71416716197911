<template>
    <div class="d-flex">
        <div class="table-row p-xxl">
            <div class="container-header">
                <div class="pb-3">
                    <p class="card-title counter d-inline-block">{{ name }}</p>
                    <span class="tooltip-container ml-2" v-if="tooltipText" v-tooltip.left="tooltipText">
                          <inline-svg
                              class="icon-info"
                              :src="require('@/assets/icons/info.svg')"></inline-svg>
                    </span>
                </div>

                <p v-if="count !== -1" class="default-header"> {{countPrefix}} {{ displayedCount }}</p>
            </div>

            <div v-if="chartType" class="container-chart-all">
                <div class="container-chart">
                    <!-- Conditional rendering of chart based on the chartType prop -->
                    <component
                        id="data-chart"
                        :is="chartType"
                        :height="0"
                        :data="chartData"
                        :options="chartOptions">

                    </component>
                </div>

                <div v-if="!hideLabels" class="container-labels">
                    <p v-for="(label, index) in this.customData.labels" :key="index" :style="{ 'border-left': '3px solid ' + autoChartColors[index] }">
                        <span v-if="!hideLabelCount" class="label-count" :style="{ 'color': autoChartColors[index] }">{{countPrefix}} {{this.customData.data[index].toFixed(2)}}</span>
                        {{ label }}
                    </p>
                </div>
            </div>

        </div>
    </div>

</template>

<script>
import { Doughnut, Bar, Line as LineChart } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement } from 'chart.js';
import { colorUtil } from "@/helpers/ColorUtil";
import store from "vuex";
import {FormattedChartData} from "@/models/FormattedChartData";
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement);

export default {
    name: "CounterCard",
    components: {
        Doughnut,
        Bar,
        LineChart,
    },
    props: {
        name: String,
        count: Number,
        countPrefix: String,
        tooltipText: String,
        customData: {
            type: FormattedChartData,
            default: null
        },
        chartType: {
            type: String,
            default: null
        },
        hideLabels: {
            type: Boolean,
            default: false
        },
        indexAxis: {
            type: String,
            default: "y"
        },
        hideLabelCount: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            displayedCount: 0
        }
    },
    created() {
        this.changeCount();
    },
    watch: {
        count() {
            this.changeCount();
        }
    },
    methods: {
        changeCount() {
            if (this.displayedCount === this.count) return;
            if (this.displayedCount < this.count) {
                this.displayedCount++;
            } else {
                if (!(this.displayedCount - 1 < 0)){
                    this.displayedCount--;
                }

            }

            const difference = Math.abs(this.displayedCount - this.count);
            const duration = Math.max(200, 2000 - Math.log2(difference + 1) * 100);

            this.animateCountChange(Date.now(), duration);
        },
        animateCountChange(startTime, duration) {
            const animate = () => {
                const currentTime = Date.now();
                const elapsed = currentTime - startTime;

                if (elapsed < duration) {
                    const progress = elapsed / duration;
                    this.displayedCount = Math.round(this.easeInOut(progress) * (this.count - this.startCount) + this.startCount);

                    requestAnimationFrame(animate);
                } else {
                    this.displayedCount = this.count;
                }
            };

            this.startCount = this.displayedCount;
            requestAnimationFrame(animate);
        },

        easeInOut(t) {
            return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
        }
    },
    computed: {
        autoChartColors() {
            if (this.customData) {
                let numColors = 10;
                return this.customData.labels.map((item, index) => {
                    return colorUtil.generateFromBaseColor(index, numColors);
                });
            } else {
                return []
            }

        },
        computedLabels() {
            if (this.customData && this.customData.labels) {
                return this.customData.labels;
            } else {
                return [];
            }
        },
        computedChartData() {
            if (this.customData && this.customData.data) {
                return this.customData.data;
            } else {
                return [];
            }
        },
        chartData() {
            return {
                labels: this.computedLabels,
                datasets: [
                    {
                        data: this.computedChartData,
                        backgroundColor: this.autoChartColors,
                        borderWidth: 0,
                        borderJoinStyle: 'round',
                        borderRadius: 4,
                        spacing: 2,
                    },
                ],
            };
        },
        chartOptions() {
            return {
                indexAxis: this.indexAxis,
                name: null,
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                    },
                },
                scales: {
                    x: {
                        display: false, // Hide the X-axis
                    },
                    y: {
                        display: false, // Hide the Y-axis
                    },
                }
            };
        },
    },
};
</script>

<style scoped lang="scss">
    .tooltip-container {
        line-height: 0 !important;
        .icon-info {
            stroke: rgba($color-secondary-text, 0.5);
            width: 12px;
            height: 12px;
        }
    }
    .card {
        flex-direction: row !important;
    }
    p {
        display: block;
        line-height: 1;
    }
    .default-header {
        margin: 0 0 !important;
        font-weight: 500;
        font-size: $font-card-big-text !important;
        color: $color-white-text !important;
        white-space: nowrap;
    }

    .table-row {
        display: flex;
        justify-content: flex-start;
        column-gap: $default-edge-spacing-l;
        justify-content: space-between;
        margin-top: 0 !important;
        align-items: stretch;
        flex: 1;
    }

    .container-chart-all {
        display: flex;
        flex-direction: row;
        column-gap: $default-edge-spacing-l;
    }

    .container-chart {
        justify-content: end;
        width: 100px !important;
        padding-left: $default-edge-spacing-l;
    }

    .container-labels {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        p {
            padding-left: $default-edge-spacing-l;
        }
    }

    .counter {
        color: $color-secondary-text;
    }
    .label-count {
        margin-right: $default-edge-spacing-l;
    }

    .card-title {
        font-size: $font-card-title;
    }

    @media (max-width: $screen-md ) {
        .table-row {
            display: flex;
            column-gap: $default-edge-spacing-l;
            justify-content: start;
            margin-top: 0 !important;
            align-items: stretch;
            flex-direction: column;
            flex: 1;

            .container-header {
                text-align: center;
            }

            .container-chart {
                padding-left: 0;
            }

            .container-chart-all {
                border-top: 1px solid $color-separator;
                margin-top: $default-edge-spacing-xl;
                padding-top: $default-edge-spacing-xl;
                justify-content: space-between;
            }
        }
    }
</style>