<template>
    <div>
        <page-header
            class="header"
            iconName="credit-card.svg"
            actionIconName="search.svg"
            @actionClick="submitSearch"
            :actionText="$t('generic.search')"
            :headerTitle="$t('paymentsPage.header')"
        >
            <design-input v-model:customValue="startDateTime" type="datetime-local"  id="startDateTime" class="date" name="startDateTime"/>
            <design-input v-model:customValue="endDateTime" type="datetime-local" id="endDateTime" class="date" name="endDateTime"/>
        </page-header>
        <div class="row">
            <div class="main-content col-12 col-md-9">
                <div class="table-responsive">
                    <table class="table table-sm">
                    <thead>
                        <tr>
                            <th scope="col">{{$t('generic.accountId')}}</th>
                            <th scope="col">{{$t('paymentsPage.paymentType')}}</th>
                            <th scope="col">{{$t('paymentsPage.amount')}}</th>
                            <th scope="col">{{$t('paymentsPage.returned')}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <transition-group class="table-list" name="list" >
                            <tr v-for="paymentTransaction in paymentsData.paymentTransactions" :key="paymentTransaction.paymentTransactionId">
                                <th scope="row">{{paymentTransaction.clientAccountId}}</th>
                                <td>{{paymentTransaction.paymentType}}</td>
                                <td>€{{paymentTransaction.amountPaid.toFixed(2)}}</td>
                                <td>€{{paymentTransaction.amountReturned.toFixed(2)}}</td>
                            </tr>
                        </transition-group>
                        
                    </tbody>
                    </table>
                </div>
            </div>

            <div class="col-12 col-md-3">
                <div class="col-12 sticky-top">
                    <div class="ml-md-3 total-container card ">
                        <p class="header mb-3">{{$t('generic.total')}}</p>
                        <div class="text-center">
                            <span class="float-left">{{$t('paymentsPage.totalCash')}}</span>
                            <span v-if="paymentsData.totalCash != null" class="float-right">€{{ paymentsData.totalCash.toFixed(2) }}</span>
                            <span v-else class="float-right">€{{ 0.0.toFixed(2) }}</span>
                        </div>

                        <div class="text-center">
                            <span class="float-left">{{$t('paymentsPage.totalCard')}}</span>
                            <span v-if="paymentsData.totalCard != null" class="float-right">€{{ paymentsData.totalCard.toFixed(2) }}</span>
                            <span v-else class="float-right">€{{ 0.0.toFixed(2) }}</span>
                        </div>
                        <hr>

                        <div class="text-center">
                            <span class="float-left">{{$t('generic.total')}}</span>
                            <span v-if="paymentsData.total != null" class="float-right">€{{ paymentsData.total.toFixed(2) }}</span>
                            <span v-else class="float-right">€{{ 0.0.toFixed(2) }}</span>
                        </div>

                        <div class="text-center">
                            <span class="float-left">{{$t('paymentsPage.totalOffered')}}</span>
                            <span v-if="paymentsData.totalOffered != null" class="float-right">€{{ paymentsData.totalOffered.toFixed(2) }}</span>
                            <span v-else class="float-right">€{{ 0.0.toFixed(2) }}</span>
                        </div>
                        <div class="text-center">
                          <span class="float-left">{{$t('paymentsPage.totalError')}}</span>
                          <span v-if="paymentsData.totalError != null" class="float-right">€{{ paymentsData.totalError.toFixed(2) }}</span>
                          <span v-else class="float-right">€{{ 0.0.toFixed(2) }}</span>
                        </div>
                    </div>
                    <div v-if="paymentsData.vatSummaries" class="ml-md-3 total-container card mt-3">
                        <p class="header mb-3">{{$t('generic.vat')}}</p>
                        <div v-for="vatSummary in paymentsData.vatSummaries" :key="vatSummary.vatBasePercent" class="mb-3 d-card">
                            <span>{{vatSummary.vatBasePercent}}%</span>
                            <div class="text-center">
                                <span class="float-left">{{$t('paymentsPage.totalVatExcl')}}</span>
                                <span class="float-right">€{{ vatSummary.totalVatExcl.toFixed(2) }}</span>
                            </div>
                            <div class="text-center">
                                <span class="float-left">{{$t('paymentsPage.totalVatIncl')}}</span>
                                <span class="float-right">€{{ vatSummary.totalVatIncl.toFixed(2) }}</span>
                            </div>
                            <div class="text-center">
                                <span class="float-left">{{$t('generic.vat')}}</span>
                                <span class="float-right">€{{ vatSummary.totalVat.toFixed(2) }}</span>
                            </div>                            
                        </div>
                    </div>
                </div>
                
            </div>
        </div>

    </div>

</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import PageHeader from './components/PageHeader.vue'
import DesignInput from './components/DesignInput.vue'

export default {
    name: "PaymentsPage",
    components: {
        PageHeader,
        DesignInput,
    },
    data() {

    },
    methods: {
        ...mapActions('analytics', ['getPaymentTransactions']),
        ...mapMutations('analytics', ['setQueryStartDateTime', 'setQueryEndDateTime']),
        submitSearch() {
            this.getPaymentTransactions();
        }
    },
    computed: {
        ...mapState('analytics', ['paymentsData','queryStartDateTime', 'queryEndDateTime']),
        startDateTime: {
            get() {
                return this.queryStartDateTime;
            },
            set(newV) {
                this.setQueryStartDateTime(newV);
            }
        },
        endDateTime: {
            get() {
                return this.queryEndDateTime;
            },
            set(newV) {
                this.setQueryEndDateTime(newV);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .date {
        display: inline-block;
        font-size: $font-small;
        margin-right: $default-edge-spacing-l;
    }

    .card {
        border-radius: $radius-small;
        background-color: $color-input-back;
        margin-top: $default-edge-spacing-xxl;
        padding: $default-edge-spacing-xl !important;
    }

    .total-container {
        .header {
            font-size: $font-title-big;
            font-weight: 600;
        }

        .float-left {
            color: $color-primary-text;
        }
        .float-right {
            color: $color-secondary-text;
        }
    }

    hr {
        border-color: $color-secondary-text;
        border-radius: 15px;
        opacity: 0.15;
    }

    .d-card {
        display: flex;
        flex-direction: column
    }

</style>