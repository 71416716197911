<template>
    <div class="d-flex">
        <div class="table-row p-xxl align-self-stretch">
            <div>
                <p class="card-title counter pb-2">{{ name }}</p>
            </div>

            <div class="container-chart-all">
                <div class="container-chart">
                    <!-- Conditional rendering of chart based on the chartType prop -->
                    <component
                        id="data-chart"
                        :is="chartType"
                        :data="chartData"
                        height="0"
                        :options="chartOptions">
                    </component>
                </div>

                <div v-if="!hideLabels" class="container-labels">
                    <p v-for="(label, index) in this.customData.labels" :key="index" :style="{ 'border-left': '3px solid ' + autoChartColors[index] }">
                        {{ label }}
                    </p>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { Doughnut, Bar, Line as LineChart } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement, PointElement, LineElement, Filler } from 'chart.js';
import { colorUtil } from "@/helpers/ColorUtil";
import store from "vuex";
import {FormattedChartData} from "@/models/FormattedChartData";
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement, PointElement, LineElement, Filler);

export default {
    name: "GraphCard",
    components: {
        Doughnut,
        Bar,
        LineChart,
    },
    props: {
        name: String,
        count: Number,
        countPrefix: String,
        customData: {
            type: FormattedChartData,
            default: null
        },
        chartType: String,
        hideLabels: {
            type: Boolean,
            default: false
        },
        indexAxis: {
            type: String,
            default: "y"
        }
    },
    computed: {
        autoChartColors() {
            if (this.customData) {
                let numColors = 10;
                return this.customData.labels.map((item, index) => {
                    return colorUtil.generateFromBaseColor(index, numColors);
                });
            } else {
                return []
            }

        },
        computedLabels() {
            if (this.customData && this.customData.labels) {
                return this.customData.labels;
            } else {
                return [];
            }
        },
        computedChartData() {
            if (this.customData && this.customData.data) {
                return this.customData.data;
            } else {
                return [];
            }
        },
        chartData() {
            return {
                labels: this.computedLabels,
                datasets: [
                    {
                        data: this.computedChartData,
                        borderColor: (ctx) => {
                            const canvas = ctx.chart.ctx;
                            const gradient = canvas.createLinearGradient(0, 0, 0, 160);
                            const color1 = colorUtil.generateFromBaseColor(0, 10);
                            const color2 = colorUtil.generateFromBaseColor(1, 10);
                            const color3 = colorUtil.generateFromBaseColor(2, 10);
                            gradient.addColorStop(0, color1);
                            gradient.addColorStop(.5, color2);
                            gradient.addColorStop(1, color3);

                            return gradient;
                        },
                        fill: {
                            target: 'origin',
                            above: (ctx) => {
                                const canvas = ctx.chart.ctx;
                                const gradient = canvas.createLinearGradient(0, 0, 0, 160);
                                const color1 = colorUtil.generateFromBaseColor(0, 10, 1);
                                const color2 = colorUtil.generateFromBaseColor(1, 10, 0.4);
                                const color3 = colorUtil.generateFromBaseColor(2, 10, 0.05);
                                gradient.addColorStop(0, color1);
                                gradient.addColorStop(.5, color2);
                                gradient.addColorStop(1, color3);

                                return gradient;
                            }  // And blue below the origin
                        },
                        pointRadius: 3,
                        borderWidth: 2,
                        borderJoinStyle: 'round',
                        borderRadius: 4,
                        spacing: 2,
                        showLine: true,
                        tension: .3,
                        animation: false
                    },
                ],
            };
        },
        chartOptions() {
            return {
                indexAxis: this.indexAxis,
                name: null,
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                    },
                },
                scales: {
                    x: {
                        display: true, // Hide the X-axis
                    },
                    y: {
                        display: true, // Hide the Y-axis
                    },
                }
            };
        },
    },
};
</script>

<style scoped lang="scss">
    .card {
        flex-direction: row !important;
    }
    p {
        display: block;
        line-height: 1;
    }
    .default-header {
        margin: 0 0 !important;
        font-weight: 500;
        margin-bottom: $default-edge-spacing-l;
        font-size: $font-card-big-text !important;
        color: $color-white-text !important;
    }

    .table-row {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-top: 0 !important;
        column-gap: $default-edge-spacing-l;
        width: 100%;
    }

    .container-chart-all {
        display: flex;
        flex-direction: row;
        height: 100% !important;
        column-gap: $default-edge-spacing-l;
    }

    .container-chart {
        justify-content: end;
        width: 100% !important;
        height: 100% !important;
        padding-left: $default-edge-spacing-l;
    }

    .container-labels {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        p {
            padding-left: $default-edge-spacing-l;
        }
    }

    .counter {
        color: $color-secondary-text;
    }

    .card-title {
        font-size: $font-card-title;
    }

    @media (max-width: $screen-md ) {
        .table-row {
            display: flex;
            column-gap: $default-edge-spacing-l;
            justify-content: start;
            margin-top: 0 !important;
            align-items: stretch;
            flex-direction: column;
            flex: 1;
            .container-chart {
                padding-left: 0;
            }

            .container-chart-all {
                border-top: 1px solid $color-separator;
                margin-top: $default-edge-spacing-l;
                padding-top: $default-edge-spacing-l;
                justify-content: space-between;
            }
        }
    }
</style>