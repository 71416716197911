import apiClient from "../helpers/ApiClient";
import { handleError, authHeader } from "../helpers/ApiClient";

export const organisationService = {
  getOrganisation,
  updateOrganisation,
};

function getOrganisation() {
  return apiClient
    .get("/org", { headers: authHeader() })
    .then((response) => {
      return response.data;
    })
    .catch((error) => handleError(error));
}

function updateOrganisation(organisation) {
  return apiClient
    .patch("/org", organisation, { headers: authHeader() })
    .then((response) => {
      return response.data;
    })
    .catch((error) => handleError(error));
}