<template>
    <div class="table-row d-flex flex-column p-0 m-0">
        <div class="d-flex" :class="{ 'justify-content-around': item?.closeUser !== null }">
            <div class="d-flex justify-content-center row w-100">
                <div v-if="from === 'detailPage'">
                    <span class="secondary-text fz16 uppercase">{{ $t('generic.open') }}</span>
                </div>
                <div class="d-flex mt-2 mr-2 w-100 justify-content-center" v-if="item?.openUser?.username">
                    <span class="secondary-text green fz16">
                        <inline-svg class="user" :src="require('@/assets/icons/user.svg')" /> {{ item?.openUser?.username }}</span>
                </div>
                <div class="d-flex mt-2 mr-2 w-100 justify-content-center" v-if="item?.startTimestamp">
                    <span class="secondary-text green fz16">
                        <inline-svg class="user" :src="require('@/assets/icons/clock.svg')" /> {{ item?.startTimestamp.toDate() + item?.startTimestamp.toTime()}}</span>
                </div>
                <div class="d-flex mt-2 mr-2 w-100 justify-content-center" v-if="item?.startCash">
                    <span class="secondary-text green fz16">
                        <inline-svg class="user" :src="require('@/assets/icons/hour-glass-start.svg')" /> {{ item?.startCash.toFixed(2) }}€</span>
                </div>
            </div>
            <div class="d-flex justify-content-center row w-100">
                <div v-if="from === 'detailPage'">
                    <span class="secondary-text fz16 uppercase">{{ $t('generic.close') }}</span>
                </div>
                <div class="d-flex mt-2 mr-2 w-100 justify-content-center" v-if="item?.closeUser?.username">
                    <span class="secondary-text green fz16">
                        <inline-svg class="user" :src="require('@/assets/icons/user.svg')" /> {{ item?.closeUser?.username }}</span>
                </div>
                <div class="d-flex mt-2 mr-2 w-100 justify-content-center" v-else><span class="secondary-text green">......</span></div>
                <div class="d-flex mt-2 mr-2 w-100 justify-content-center" v-if="item?.endTimestamp" >
                    <span class="secondary-text green fz16">
                        <inline-svg class="user" :src="require('@/assets/icons/clock.svg')" /> {{ item?.endTimestamp.toDate() + item?.endTimestamp.toTime()}}</span>
                </div>
                <div class="d-flex mt-2 mr-2 w-100 justify-content-center" v-else><span class="secondary-text green">......</span></div>
                <div class="d-flex mt-2 mr-2 w-100 justify-content-center" v-if="item?.endCash">
                    <span class="secondary-text green fz16">
                        <inline-svg class="user" :src="require('@/assets/icons/hour-glass-end.svg')" /> {{ item?.endCash.toFixed(2) }}€</span>
                </div>
                <div class="d-flex mt-2 mr-2 w-100 justify-content-center" v-else><span class="secondary-text green">......</span></div>
            </div>
        </div>
        <div v-if="item?.totalData?.totalCash" class="d-flex justify-content-center mt-2">
            <span class="secondary-text fz16">
                <span class="icon">€</span> {{ item?.totalData?.totalCash.toFixed(2) }}€
            </span>
        </div>
        <div v-if="item?.totalData?.totalCard" class="d-flex justify-content-center">
            <span class="secondary-text fz16">
                <inline-svg class="user" :src="require('@/assets/icons/credit-card.svg')" /> {{ item?.totalData?.totalCard.toFixed(2) }}€
            </span>
        </div>
        <div v-if="item?.totalData?.totalOffered" class="d-flex justify-content-center mb-2">
            <span class="secondary-text fz16">
                <span class="icon">%</span> {{ item?.totalData?.totalOffered.toFixed(2) }}€
            </span>
        </div>
        <div v-if="item?.totalData?.total" class="d-flex justify-content-center my-2">
            <span class="button" @click="$emit('openShift', item.shiftId)">
                Total: {{ item?.totalData?.total.toFixed(2) }}€
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: "CardShift",
    props: ['item', 'from'],
    // emits: ['primaryAction', 'secondaryAction', 'openAccount'],
    methods: {
    }
}
</script>

<style lang="scss" scoped>

    .edit-row-btn {
        column-gap: 10px;
        background: none !important;
    }
 
</style>