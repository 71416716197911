<template>
    <div class="d-flex">
        <div class="table-row p-xxl">
            <div class="shift-header">
                <p class="card-label"> {{ isShiftOpen ? $t('generic.shift') : $t('generic.lastShift')}} </p>
                <p v-if="isShiftOpen">
                    <span class="status-dot pulse-animation"/>
                    <span class="ml-3">Live</span>
                </p>
                <p v-else>
                    <span class="status-dot"/>
                </p>
            </div>

            <div class="container-base">
                <div class="row container-shift-details">
                    <div class="first-col col">
                        <shift-data-item class="shift-data-item" :item-name="$t('shiftPage.openShift')" :item-value="shift.startTimestamp.toDate()" />

                        <shift-data-item class="shift-data-item" :item-name="$t('shiftPage.openUserCash')" :item-value="shift.startCash == null ? '0' : shift.startCash.toFixed(2)" />

                        <shift-data-item class="shift-data-item" :item-name="$t('shiftPage.openUserName')" :item-value="shift.openUser.username" />
                    </div>

                    <div class="second-col col">
                        <shift-data-item class="shift-data-item" :item-name="$t('shiftPage.closeUserTime')"  :item-value="shift.endTimestamp == 0 ? '-' : shift.endTimestamp.toDate()" />

                        <shift-data-item class="shift-data-item" :item-name="$t('shiftPage.closeUserCash')" :item-value="shift.endCash == null ? '0' : shift.endCash.toFixed(2)" />

                        <shift-data-item class="shift-data-item" :item-name="$t('shiftPage.closeUserName')" :item-value="shift.closeUser == null ? '-' : shift.closeUser.username" />
                    </div>
                </div>

                <div v-if="isShiftOpen" class="row container-open-accounts">
                    <div class="col">
                        <shift-data-item class="shift-data-item" @click="openShift" :big-text="true" :item-name="$t('generic.openAccounts')" :item-value="openAccountsData.totalCount.toString()" />
                        <div class="container-open-accounts-details">
                            <shift-data-item class="shift-data-item" @click="openTables" :item-name="$t('generic.tables')" :item-value="openAccountsData.tableCount.toString()" />
                            <shift-data-item class="shift-data-item" @click="openTakeaway" :item-name="$t('generic.takeaway')" :item-value="openAccountsData.takeawayCount.toString()" />
                            <shift-data-item class="shift-data-item" @click="openTakeaway" :item-name="$t('generic.delivery')" :item-value="openAccountsData.deliveryCount.toString()" />
                        </div>
                    </div>
                </div>
            </div>




        </div>
    </div>

</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import {Shift} from "@/models/Shift";
import {AccountsCountData} from "@/models/AccountsCountData";
import ShiftDataItem from "@/views/components/dashboard/ShiftDataItem.vue";

export default defineComponent({
    name: "CurrentShiftCard",
    components: {ShiftDataItem},
    props: {
        shift: {
            type: Object as PropType<Shift>,
            required: true
        },
        openAccountsData: {
            type: Object as PropType<AccountsCountData>,
            required: true
        }
    },
    computed: {
        shiftStatusColor() {
            if (this.shift.closeUser == null) {
                return "#73D772";
            } else {
                return "#DD6365";
            }
        },
        isShiftOpen() {
            return this.shift.endTimestamp == 0;
        }
    },
    methods: {
        openTables() {
            this.$router.push('/open-tables')
        },
        openTakeaway() {
            this.$router.push('/open-takeaway')
        },
        openShift() {
            this.$router.push('/shift-details/'+this.shift.shiftId)
        }
    }
})
</script>

<style scoped lang="scss">
    .container-base {
        display: flex;
        flex-direction: row;
        column-gap: $default-edge-spacing-xxxl;

        .container-shift-details {
            border-right: 1px solid $color-primary-dark-text;
            padding-right: $default-edge-spacing-xxxl;
        }

        .container-open-accounts {
            margin: auto !important;
            text-align: center;

            .shift-data-item {
                cursor: pointer;
                &:hover {
                    transform: scale(1.1);
                    opacity: 0.5;
                }
            }
        }

        .container-open-accounts-details {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            text-align: center;
            column-gap: $default-edge-spacing-xxl;
        }
    }

    @media (max-width: $screen-md ) {
        .container-base {
            flex-direction: column;

            .container-shift-details {
                border-right: 0;
            }

            .container-open-accounts {
                border-left: 0;
                border-top: 1px solid $color-primary-dark-text !important;
                padding-left: 0 !important;
                padding-top: $default-edge-spacing-xl;
            }
        }
    }
    .shift-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .card-label {
        font-size: $font-card-title;
        color: $color-secondary-text;
        margin-bottom: $default-edge-spacing-l;
    }

    .first-col {
        margin-right: $default-edge-spacing-xxl;
    }

    .shift-data-item {
        margin-bottom: $default-edge-spacing-l !important;
    }

    .status-dot {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        display: inline-block;
        background-color: v-bind(shiftStatusColor);
    }

    .table-row {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-top: 0 !important;
        column-gap: $default-edge-spacing-l;
        width: 100%;
    }

    //create a pulse animation using keyframes
    .pulse-animation {
        animation: pulse 2s infinite;
    }
    @keyframes pulse {
        0% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(115, 215, 114, 0.7);
        }

        70% {
            transform: scale(1);
            box-shadow: 0 0 0 10px rgba(115, 215, 114, 0);
        }

        100% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(115, 215, 114, 0);
        }
    }

</style>