<template>
  <p>account page</p>
</template>

<script>
export default {
    name: "MyAccountPage",
}
</script>

<style>

</style>