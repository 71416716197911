<template>
    <div class="select-container">
        <div class="hint-container">
            <p class="select-hint"> {{ hint }} </p>
            <span class="tooltip-container" v-if="tooltipText" v-tooltip.left="tooltipText">
            <inline-svg
                class="icon-info"
                :src="require('@/assets/icons/info.svg')"></inline-svg>
        </span>
        </div>
        <div class="select-wrapper">
            <select :class="[error ? 'error' : '']" :value="customValue" @input="$emit('update:customValue', $event.target.value)">
                <slot></slot>
            </select>
        </div>
    </div>
</template>

<script>
import Tooltip from 'primevue/tooltip';
export default {
    name: "DesignSelect",
    directives: {
        'tooltip': Tooltip
    },
    emits: ['update:customValue'],
    props: {
        'hint': {
            type: String,
            default: ""
        },
        'customValue': {
            type: [String, Number, Boolean],
            default: ""
        },
        'error': {
            type: Boolean,
            default: false
        },
        'tooltipText': {
            type: String,
            default: ""
        }
    }
}
</script>

<style lang="scss" scoped>
.select-container {
    display: flex;
    flex-direction: column;

    .hint-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 8px 8px !important;

        .select-hint {
            padding: 0 !important;
            font-size: 12px;
            font-weight: normal;
            color: $color-secondary-text;
            opacity: 0.8;
            margin: 0 !important;
        }

        .icon-info {
            stroke: rgba($color-secondary-text, 0.5);
            width: 12px;
            height: 12px;
        }

        .tooltip-container {
            line-height: 0 !important;
        }
    }

    .select-wrapper {
        position: relative;
        &:after {
            pointer-events: none;
            content: url('@/assets/icons/arrow.svg');
            width: 14px;
            fill: #fff;
            position: absolute;
            top: 10px;
            right: 20px;
        }
    }

    select {
        padding: 10px 50px 10px 20px;
        -moz-appearance: none; /* Firefox */
        -webkit-appearance: none; /* Safari and Chrome */
        appearance: none;
        cursor: pointer;
        width: 100%;
        &.error {
            outline: 1px solid rgba($color-pastel-red, .3);
            box-shadow: 0px 0px 15px rgba($color-pastel-red, .3);
        }
    }
}
</style>
