import { shiftService } from "@/services/ShiftService";
import { analyticsService } from "@/services/AnalyticsService";
import { toast } from "vue3-toastify";

const state = {
  shiftData: {},
  shiftDataX: {},
  shiftMessage: {},
  shiftCurrentData: {},
  updateShiftLatestData: {},
  isLoadingShiftRequest: false,
  //7 means 7 day before and after the current day
  queryStartDateTime: Date.now() - 7 * 24 * 60 * 60 * 1000,
  queryEndDateTime: Date.now() + 2 * 24 * 60 * 60 * 1000,
};

const actions = {
  getShiftData({ commit, state }) {
    commit("setLoadingRequest", true);
    shiftService
      .getShiftService(state.queryStartDateTime, state.queryEndDateTime)
      .then((response) => {
        commit("setLoadingRequest", { loading: false });
        commit("updateShiftData", response.data);
      })
      .catch((error) => {
        commit("setLoadingRequest", { loading: false });
        toast.warning(error);
      });
  },
  getShiftIdData({ commit, state }, shiftId) {
    commit("setLoadingRequest", true);
    shiftService
      .getShiftIdService(shiftId)
      .then((response) => {
        commit("setLoadingRequest", { loading: false });
        commit("updateShiftData", response.data);
        commit("updateShiftMessage", response.message);
      })
      .catch((error) => {
        commit("setLoadingRequest", { loading: false });
      });
  },
  getShiftIdDataX({ commit, state }, shiftId) {
    commit("setLoadingRequest", true);
    shiftService
      .getShiftDetailDataXService(shiftId)
      .then((response) => {
        commit("setLoadingRequest", { loading: false });
        commit("updateShiftDataX", response.data);
        commit("updateShiftMessage", response.message);
      })
      .catch((error) => {
        commit("setLoadingRequest", { loading: false });
      });
  },
  getShiftStatsDataXDate({ commit, state }, date) {
    commit("setLoadingRequest", true);
    shiftService
      .getShiftStatsDataXDateService(date?.startDate, date?.endDate)
      .then((response) => {
        commit("setLoadingRequest", { loading: false });
        commit("updateShiftDataX", response.data);
        commit("updateShiftMessage", response.message);
      })
      .catch((error) => {
        commit("setLoadingRequest", { loading: false });
      });
  },
  getShiftCurrentData({ commit, state }) {
    commit("setLoadingRequest", true);
    shiftService
      .getShiftCurrentService()
      .then((response) => {
        commit("setLoadingRequest", { loading: false });
        commit("updateShiftCurrentData", response.data);
      })
      .catch((error) => {
        commit("setLoadingRequest", { loading: false });
      });
  },
  getShiftLatestData({ commit, state }) {
    commit("setLoadingRequest", true);
    shiftService
      .getShiftLatestService()
      .then((response) => {
        commit("setLoadingRequest", { loading: false });
        commit("updateShiftLatestData", response.data);
      })
      .catch((error) => {
        commit("setLoadingRequest", { loading: false });
      });
  },
  deleteAccountWithHistory({ dispatch, commit }, accountId) {
    commit("setLoadingRequest", { loading: true });
    analyticsService
      .deleteAccount(accountId)
      .then((response) => {
        const segments = window.location.href.split("/");
        const shiftId = segments[segments.length - 1];
        dispatch("getShiftIdData", shiftId);
      })
      .catch((error) =>
        commit("setLoadingRequest", { loading: false })
      );
  },
  getShiftDetailDownloadXData({ commit, state }, shiftId) {
    commit("setLoadingRequest", true);
    shiftService
      .getShiftDetailDownloadXService(shiftId)
      .then((response) => {
        window.open(URL.createObjectURL(response));
      })
      .catch((error) => {
        commit("setLoadingRequest", { loading: false });
      });
  },
  getShiftDetailDownloadZData({ commit, state }, shiftId) {
    commit("setLoadingRequest", true);
    shiftService
      .getShiftDetailDownloadZService(shiftId)
      .then((response) => {
        window.open(URL.createObjectURL(response));
      })
      .catch((error) => {
        commit("setLoadingRequest", { loading: false });
      });
  },
  getShiftDownloadXData({ commit, state }, page) {
    commit("setLoadingRequest", true);
    shiftService
      .getShiftDownloadXService( page,
        page === 'shiftDetail' ? state?.shiftData?.shift?.shiftId :
        state?.queryStartDateTime,
        state?.queryEndDateTime
      )
      .then((response) => {
        page === 'shiftDetail' ? 
        window.open(URL.createObjectURL(response?.data))
        :
        window.open(URL.createObjectURL(response)); 
      })
      .catch((error) => {
        commit("setLoadingRequest", { loading: false });
      });
  },
  getShiftDownloadZData({ commit, state }, page) {
    commit("setLoadingRequest", true);
    shiftService
      .getShiftDownloadZService(page,
        page === 'shiftDetail' ? state?.shiftData?.shift?.shiftId :
        state?.queryStartDateTime,
        state?.queryEndDateTime
      )
      .then((response) => {
        page === 'shiftDetail' ? 
        window.open(URL.createObjectURL(response?.data))
        :
        window.open(URL.createObjectURL(response)); 
      })
      .catch((error) => {
        commit("setLoadingRequest", { loading: false });
      });
  },
    forceClosShiftForMixedPayment({ dispatch, commit }, { myComment, myCurrentCash, myPaymentTypeForForcedClose }) {
        commit("setLoadingRequest", { loading: true });
        shiftService.forceCloseShift(myComment, myCurrentCash, myPaymentTypeForForcedClose)
            .then((response) => {
                commit("setLoadingRequest", { loading: false });
                dispatch("getShiftIdData", state.shiftData.shift.shiftId);
            })
            .catch((error) =>
                commit("setLoadingRequest", { loading: false })
            );
    },
    openNewShift({ dispatch, commit }, { myCurrentCash }) {
        commit("setLoadingRequest", { loading: true });
        shiftService.openNewShift(myCurrentCash)
            .then((response) => {
                commit("setLoadingRequest", { loading: false });
                dispatch("getShiftCurrentData");
                dispatch("getShiftData");
            })
            .catch((error) =>
                commit("setLoadingRequest", { loading: false })
            );
    }
};

const mutations = {
  setLoadingRequest(state, isLoading) {
    state.isLoadingShiftRequest = isLoading;
  },
  updateShiftData(state, shiftData) {
    state.shiftData = shiftData;
  },
  updateShiftDataX(state, shiftData) {
    state.shiftDataX = shiftData;
  },
  updateShiftMessage(state, message) {
    state.shiftMessage = message;
  },
  updateShiftCurrentData(state, shiftCurrentData) {
    state.shiftCurrentData = shiftCurrentData;
  },
  updateShiftLatestData(state, shiftLatestData) {
    state.shiftLatestData = shiftLatestData;
  },
  setQueryStartDateTime(state, startDate) {
    state.queryStartDateTime = startDate;
  },
  setQueryEndDateTime(state, endDate) {
    state.queryEndDateTime = endDate;
  }
};

export const shift = {
    namespaced: true,
    state,
    actions,
    mutations,
}