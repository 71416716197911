<template>
  <div class="input-container">
      <p class="input-hint"> {{ hint }} </p>
      <textarea
          rows="12"
          :class="error ? 'error' : ''"
          :type="type"
          :value="customValue"
          :disabled="disabled"
          @input="$emit('update:customValue', $event.target.value)"/>
  </div>
</template>

<script>
export default {
    name: "DesignInputArea",
    emits: ['update:customValue'],
    props: {
        'type': {
            type: String,
            default: ""
        },
        'hint': {
            type: String,
            default: ""
        },
        'customValue': {
            type: [String, Number],
            default: ""
        },
        'error' : {
            type: Boolean,
            default: false
        },
        'disabled' : {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss" scoped>
    .input-container {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        .input-hint {
            padding: 0 !important;
            font-size: 12px;
            font-weight: normal;
            color: $color-secondary-text;
            opacity: 0.8;
            margin: 0 0 8px 8px;
        }

        input {
            width: 100%;
            padding: 10px 20px 10px 20px;
            &.error {
                outline: 1px solid rgba($color-pastel-red, .3);;
                box-shadow: 0px 0px 15px rgba($color-pastel-red, .3);
            }
            &:disabled {
                opacity: 0.3;
            }
        }
    }
</style>