<template>
    <div class="page-header">
        <div class="header-container">
            <inline-svg @click="toggleSideNav" class="icon menu mr-4 d-md-none" :src="require('@/assets/icons/menu.svg')"></inline-svg>
            <inline-svg class="icon mr-3 d-none d-md-block" :src="require('@/assets/icons/' + iconName)"></inline-svg>
            <p class="header mr-3">{{ headerTitle }}<span v-if="name">&nbsp;-&nbsp;{{ name }}</span></p>
            <div class="header-button">
                <button class="action-button mr-2 mb-1" @click="$emit('actionTernaryClick', $event.target.value);" v-if="actionTernaryIconName"> 
                    <inline-svg class="action-icon" :src="require('@/assets/icons/' + actionTernaryIconName)" />
                    <span class=" action-text d-none d-md-inline-block">{{ actionTernaryText }}</span>
                </button>
                <button class="action-button mr-2 mb-1" @click="$emit('actionSecondaryClick', $event.target.value);" v-if="actionSecondaryIconName"> 
                    <inline-svg class="action-icon" :src="require('@/assets/icons/' + actionSecondaryIconName)" />
                    <span class=" action-text d-none d-md-inline-block">{{ actionSecondaryText }}</span>
                </button>
                <button class="action-button" @click="$emit('actionClick', $event.target.value);" v-if="actionIconName"> 
                    <inline-svg class="action-icon" :src="require('@/assets/icons/' + actionIconName)" />
                    <span class=" action-text d-none d-md-inline-block">{{ actionText }}</span>
                </button>
            </div>
        </div>
        <div class="slot-container">
            <span class="input-hint d-block"> {{ slotHint }} </span>
            <slot></slot>
        </div>
    </div>
</template>

<script>
import {mapActions, mapState, mapMutations} from "vuex";

export default {
    name: 'PageHeader',
    data() {
        return {
            dates: null
        };
    },
    props: ['iconName', 'headerTitle', 'actionIconName', 'actionText', 'actionSecondaryIconName','actionSecondaryClick','actionSecondaryText','actionTernaryIconName','actionTernaryClick','actionTernaryText', 'slotHint', 'name'],
    emits: ['actionClick'],
    methods: {
        ...mapMutations('global', ['toggleSideNav']),
    },
    mounted() {
        if (!this.slotHint) {
            $('.slot-container').hide();
        }
    }

}
</script>

<style lang="scss" scoped>
    .header, .action-text {
        font-size: 1rem !important;
    }
    .menu {
        padding: 6px;
        width: 38px;
        min-width: 38px;
        height: 38px;
        cursor: pointer;

        &:hover, &:focus {
            opacity: 0.5;
        }
    }
    .header-button {
        text-align: right;
        width: 100%;
    }
    button { 
        &.secondary-button {
            background-color: $color-pastel-4;
        }
        border-radius: 16px;
        width: auto;
        height: auto;
        .action-icon {
            width: 20px;
            height: 20px;
            stroke: $color-white-text !important;
            margin-right: 10px;
        }
        span {
            margin: 0 !important;
            padding: 0 !important;
        }
    }

    .slot-container {
        border-radius: $radius-small;
        padding: $default-edge-spacing-xl;
        margin-top: $default-edge-spacing-xxl;
        background-color: $color-input-back;
    }

    .action-button {
        margin-left: auto;
    }

    @media (max-width: $screen-md ) {
        .action-button {
            border-radius: 12px;
            padding: 12px !important;
            .action-icon {
                margin: 0 !important;
            }
        }

    }
</style>