<script>
import {mapActions, mapState} from "vuex";

export default {
    name: "SideNavItem",
    props: {
        icon: {
            type: String,
            default: ""
        },
        text: String,
    },
    computed: {
        compIcon(){
            if (this.icon){
                return require(`../../assets/icons/${this.icon}`);
            }
            else {
                return "";
            }
        }
    },

}
</script>

<template>
    <div class="side-nav-item">
        <inline-svg class="icon" :src="compIcon"></inline-svg>
        <p class="text">{{text}}</p>
    </div>
</template>

<style scoped>

</style>
