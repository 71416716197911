import { clientService } from "@/services/ClientService";

const state = {
    clientData: [],
    clientRequestMessage: "",
    isLoadingStockRequest: false
};

const actions = {
    getClientData({dispatch, commit}){
        commit(mutations.setLoadingRequest.name, true);
        clientService.getClientData()
            .then((response) => {
                commit('setLoadingRequest', false);
                commit(mutations.setClientData.name, response.data);
            })
            .catch((error) => {
                commit(mutations.setLoadingRequest.name, false, error);
            });
    }
}

const mutations = {
    setLoadingRequest(state, isLoading, message=''){
        state.clientRequestMessage = message;
        state.isLoadingStockRequest = isLoading;
    },
    setClientData(state, clientData){
        state.clientData = clientData;
    },
}


export const client = {
    namespaced: true,
    state,
    actions,
    mutations
};