<template>
  <div class="vertical-divider"></div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
    .vertical-divider {
        width: 100%;
        height: 1px;
        background-color: white;
        opacity: 0.1;
        justify-self: center;
    }
</style>