<script>
import {mapState, mapActions} from "vuex";
import SideNav from './views/components/SideNav.vue';
import {JWTHelper} from "@/helpers/JWTHelper";

export default {
  name: 'App',
  components: {
    SideNav
  },
  methods: {
      ...mapActions('account', {
          logout: 'logout',
          getMyData: 'getMyData'
      }),
      submitLogout(){
          this.logout();
      }
  },
  computed: {
    ...mapState({
      accountState: 'account'
    })
  },
  mounted() {
    if (!this.accountState.user && JWTHelper.isLoggedIn()) {
        this.getMyData();
    }
  }
}
</script>

<template class="main-content">
  <side-nav v-if="accountState.isLoggedIn" id="side-nav" />
  <router-view  v-slot="{ Component }">
    <transition name="rt-anim"  mode="out-in" >
      <component class="main-router d-flex flex-column" :is="Component" />
    </transition>
  </router-view>
</template>

<style lang="scss">
  @import './assets/icons/fantasticon/icons.css';
  .main-router {
    overflow-y: auto;
    overflow-x: hidden;
  }

  .rt-anim-move,
  .rt-anim-enter-active,
  .rt-anim-leave-active {
    transition: all 0.8s ease;
  }
  .rt-anim-enter-from,
  .rt-anim-leave-to {
    opacity: 0;
    transform: translateY(30px);
  }

</style>
