import apiClient from '../helpers/ApiClient'
import { handleError, authHeader} from '../helpers/ApiClient';

export const tableService = {
  getTables,
  getZones,
  getTypes,
  addTable,
  addZone,
  addTypeService,
  updateTable,
  updateZone,
  updateType,
  deleteTable,
  deleteZone,
  deleteTypeService,
  getTakeawayService,
};

function getTables(){
    return apiClient.get('/table', {headers: authHeader()})
        .then((response) => {
            return response.data;
        })
        .catch((error) => handleError(error));
}
function getZones(){
    return apiClient.get('/table_zone', {headers: authHeader()})
        .then((response) => {
            return response.data;
        })
        .catch((error) => handleError(error));
}
function getTypes(){
    return apiClient.get('/table_type', {headers: authHeader()})
        .then((response) => {
            return response.data;
        })
        .catch((error) => handleError(error));
}

function getTakeawayService(){
    return apiClient.get('/client_account/takeaway', {headers: authHeader()})
        .then((response) => {
            return response.data;
        })
        .catch((error) => handleError(error));
}

function addTable(table){
    return apiClient.post('/table', table, {headers: authHeader()})
        .then((response) => {
            return response.data;
        })
        .catch((error) => handleError(error));
}

function addZone(table){
    return apiClient.post('/table_zone', table, {headers: authHeader()})
        .then((response) => {
            return response.data;
        })
        .catch((error) => handleError(error));
}

function addTypeService(type){
    return apiClient.post('/table_type', type, {headers: authHeader()})
        .then((response) => {
            return response.data;
        })
        .catch((error) => handleError(error));
}

function updateTable(table){
    return apiClient.patch(`/table/${table.tableId}`, table, {headers: authHeader()})
        .then((response) => {
            return response.data;
        })
        .catch((error) => handleError(error));
}

function updateZone(zone){
    return apiClient
      .patch(`/table_zone/${zone.zoneId}`, zone, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
      .catch((error) => handleError(error));
}

function updateType(type){
    return apiClient
      .patch(`/table_type/${type.id}`, type, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
      .catch((error) => handleError(error));
}

function deleteTable(table){
    return apiClient.delete(`/table/${table.tableId}`, {headers: authHeader()})
        .then((response) => {
            return response.data;
        })
        .catch((error) => handleError(error));
}

function deleteZone(zone){
    return apiClient.delete(`/table_zone/${zone.zoneId}`, {headers: authHeader()})
    .then((response) => {
        return response.data;
    })
    .catch((error) => handleError(error));
}
function deleteTypeService(type){
    return apiClient.delete(`/table_type/${type.id}`, {headers: authHeader()})
        .then((response) => {
            return response.data;
        })
        .catch((error) => handleError(error));
}