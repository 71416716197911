<template>
  <div>
    <popup-view :isVisible="currentProduct !== null" @close="setCurrentProduct(null)">
      <edit-product-dialog/>
    </popup-view>
    <popup-view :isVisible="deleteOpenDialog" @close="deleteOpenDialog=false">
      <confirm-delete-dialog :product="currentSelect" @submitDeleteAction="submitDelete"
                             @cancelDeleteAction="deleteOpenDialog = false"/>
    </popup-view>
    <page-header
        @actionClick="setCurrentProduct({})"
        iconName="shopping-cart.svg"
        actionIconName="plus.svg"
        :actionText="$t('productPage.addProduct')"
        :headerTitle="$t('productPage.products')"
    />
    <DataTable
        :value="getProductFiltered"
        v-model:filters="filters"
        filter-display="row"
        paginator
        ref="dt"
        :rows="500"
        :rows-per-page-options="[500, 1000, 2500, 5000, 10000]"
        table-style="min-width: 50rem"
        :global-filter-fields="['name', 'vatIn', 'vatOut', 'productGroup.name', 'productBarcodes']"
        editMode="cell"
        @cell-edit-complete="onCellEditComplete"
    >
      <template #header>
        <div class="flex justify-content-end" style="margin-top: 15px">
          <span class="p-input-icon-left">
            <i class="pi pi-search"/>
            <InputText v-model="filters['global'].value" placeholder="Keyword Search"/>
          </span>
          <Button style="margin-left: 3px;" label="Export" @click="exportCSV($event)">
            <inline-svg class="action-icon" :src="require('@/assets/icons/download.svg')"/>
          </Button>

        </div>

      </template>
      <template #empty> {{ $t('productGroupPage.noFundResultTable') }}</template>
      <template #loading> {{ $t('productGroupPage.loadingResultTable') }}</template>
      <Column field="name" header="Name" style="max-width: 12rem">
        <template #editor="{ data, field }">
            <div>
                <InputText v-model="data[field]" autofocus/>
            </div>
        </template>
        <template #body="{ data }">
            <div>
                <span>{{ data.name }}</span>
            </div>
              <div>
                <span class="allowed-option" v-for="allowedOption in data.allowedOptionGroups"
                      :key="allowedOption.productOptionGroupId">
                    {{ allowedOption.productGroup.name }},
                </span>
              </div>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter"
                     placeholder="Search by name"/>
        </template>
      </Column>
      <Column field="priceVatInclIn" header="IN" sortable>
        <template #body="{ data }">
                    <span class="vat-in">€{{ data.priceVatInclIn + (data.weightPrice ? '/KG' : '') }} : {{
                        data.vatIn
                      }}%</span>
        </template>
        <template #editor="{ data, field }">
          <InputText v-model="data[field]" autofocus/>
        </template>
      </Column>
      <Column field="vatIn" hidden>
        <template #body="{ data }">
          <span>{{ data.vatIn }}</span>
        </template>
      </Column>
      <Column field="priceVatInclOut" header="Out" sortable>
        <template #body="{ data }">
                    <span class="vat-out">€{{
                        data.priceVatInclOut + (data.weightPrice ? '/KG' : '')
                      }} : {{ data.vatOut }}%</span>
        </template>
        <template #editor="{ data, field }">
          <InputText v-model="data[field]" autofocus/>
        </template>
      </Column>
      <Column field="vatOut" hidden>
        <template #body="{ data }">
          <span>{{ data.vatOut }}</span>
        </template>
      </Column>
      <Column field="productGroup.name" header="Group" sortable>
        <template #body="{ data }">
          <span class="bread-crumb-base">{{ data.productGroup.name }}</span>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter"
                     placeholder="Search by group"/>
        </template>
      </Column>
      <Column field="stock" header="Stock" sortable>
        <template #body="{ data }">
          <button class="bread-crumb-base" title="stock" @click="openStock(data.productId)">{{
              data.stock
            }}
          </button>
        </template>
        <template #editor="{ data, field }">
          <InputText v-model="data[field]" autofocus style="width: 70px"/>
        </template>
      </Column>

      <Column field="color" header="Color" sortable>
        <template #body="{ data }">
          <span class="bread-crumb-base" :style="{ backgroundColor: data.color }">{{ data.color }}</span>
        </template>
      </Column>

      <Column field="imageUrl" header="Image" sortable>
        <template #body="{ data }">
            <span v-if="data?.imageUrl">
              <a :href="`${data.imageUrl}`" target="_blank"> <img :src="`${data.imageUrl}`" width="45px"
                                                                  class="w-6rem border-round"/></a>
            </span>
        </template>
      </Column>
      <Column field="userHidden" :header="$t('productPage.visibility')" sortable>
        <template #body="{ data }">
          <button class="bread-crumb-base" @click="changeSpecificField({ product: data, field: 'userHidden' })">
            <inline-svg
                v-if="data.userHidden"
                class="row-icon disabled"
                :src="require('@/assets/icons/eye-off.svg')"
            />
            <inline-svg
                v-else
                class="row-icon"
                :src="require('@/assets/icons/eye.svg')"
            />
          </button>
        </template>
      </Column>
      <Column field="webHidden" :header="$t('productPage.visibilityWeb')" sortable v-if="user?.organization?.onlineOrderOpen">
        <template #body="{ data }">
          <button class="bread-crumb-base" @click="changeSpecificField({ product: data, field: 'webHidden' })">
            <inline-svg
                v-if="data.webHidden"
                class="row-icon disabled"
                :src="require('@/assets/icons/eye-off.svg')"
            />
            <inline-svg
                v-else
                class="row-icon"
                :src="require('@/assets/icons/eye.svg')"
            />
          </button>
        </template>
      </Column>
      <Column field="favorite" :header="$t('productPage.favorite')" sortable>
        <template #body="{ data }">
          <button class="bread-crumb-base" @click="changeSpecificField({ product: data, field: 'favorite' })">
            <inline-svg
                v-if="data.favorite"
                class="row-icon disabled"
                :src="require('@/assets/icons/heart-full.svg')"
            />
            <inline-svg
                v-else
                class="row-icon"
                :src="require('@/assets/icons/heart-empty.svg')"
            />
          </button>
        </template>
      </Column>
      <Column field="orderIndex" header="Order" sortable>
        <template #editor="{ data, field }">
          <InputText v-model="data[field]" autofocus style="width: 60px"/>
        </template>
      </Column>
      <Column header="Actions">
        <template #body="{data}">
          <div class="edit-row-btn">
            <button title="edit" @click="startEdit(data)">
              <inline-svg :src="require('@/assets/icons/edit.svg')"/>
            </button>
            <button v-if="user.orgId === data.orgId" class="duplicate" title="duplicate"
                    @click="duplicateProduct(data)">
              <inline-svg :src="require('@/assets/icons/duplicate.svg')"/>
            </button>
            <button v-if="user.orgId === data.orgId" class="delete" title="delete" @click="openDeleteDialog(data)">
              <inline-svg :src="require('@/assets/icons/trash.svg')"/>
            </button>
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import {ref} from 'vue';
import {mapActions, mapState, mapMutations, mapGetters} from "vuex";
import {FilterMatchMode} from 'primevue/api';
import PageHeader from './components/PageHeader.vue'
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import PopupView from './components/PopupView.vue';
import EditProductDialog from './dialogs/EditProductDialog.vue';
import ConfirmDeleteDialog from './dialogs/ConfirmDeleteDialog.vue';
import {isNumber} from "chart.js/helpers";

export default {
  name: "ProductsPage",
  components: {
    DataTable,
    Column,
    InputText,
    PageHeader,
    EditProductDialog,
    ConfirmDeleteDialog,
    PopupView,
  },
  data() {
    return {
      filters: ref({
        global: {value: null, matchMode: FilterMatchMode.CONTAINS},
        name: {value: null, matchMode: FilterMatchMode.STARTS_WITH},
        'productGroup.name': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
      }),
      selectedFilterProduct: "",
      itemFiltered: [],
      deleteOpenDialog: false,
      currentSelect: {}
    }
  },
  methods: {
    ...mapActions('product', ['getProducts', 'deleteProduct', 'duplicateProduct', 'updateProduct', 'changeSpecificField']),
    ...mapActions('productGroup', ['getProductGroups']),
    ...mapMutations('product', ['setCurrentProduct', 'setFilterProduct', 'resetFilterProduct']),
    startEdit(item) {
      this.setCurrentProduct(item);
    },
    openDeleteDialog(item) {
      this.deleteOpenDialog = true;
      this.currentSelect = item;
    },
    submitDelete() {
      this.deleteProduct(this.currentSelect);
      this.deleteOpenDialog = false;
    },
    openStock(productId) {
      this.$router.push({
        name: "stock",
        params: {productId: productId},
      });
    },
    onCellEditComplete(event) {
      const {data: product, newValue: updatedValue, field: columnField} = event;
      const isValueChanged = updatedValue.toString() !== product[columnField].toString();

      const updateCellData = (field) => {
        product[field] = updatedValue;
        this.changeSpecificField({product, field});
      }

      switch (columnField) {
        case 'name':
          if (isValueChanged && updatedValue.length > 2) updateCellData('name');
          else event.preventDefault();
          break;
        case 'priceVatInclIn':
          if (isNumber(updatedValue)) {
            if (isValueChanged) updateCellData('priceVatInclIn');
          } else {
            event.preventDefault();
          }
          break;
        case 'priceVatInclOut':
          if (isNumber(updatedValue)) {
            if (isValueChanged) updateCellData('priceVatInclOut');
          } else {
            event.preventDefault();
          }
          break;
        case 'stock':
          if (isNumber(updatedValue)) {
            if (isValueChanged) updateCellData('stock');
          } else {
            event.preventDefault();
          }
          break;
        case 'favorite':
          if (isNumber(updatedValue)) {
            if (isValueChanged) updateCellData('favorite');
          } else {
            event.preventDefault();
          }
          break;
        case 'orderIndex':
          if (isNumber(updatedValue)) {
            if (isValueChanged) updateCellData('orderIndex');
          } else {
            event.preventDefault();
          }
          break;
        default:
          event.preventDefault();
          break;
      }
    }

  },
  computed: {
    ...mapState('product', ['products', 'currentProduct']),
    ...mapState('productGroup', ['productGroups']),
    ...mapGetters('product', ['getProductFiltered']),
    ...mapState("account", ["user"]),
  },
  mounted() {
    this.getProducts();
    this.getProductGroups();
  }
}
</script>

<style lang="scss" scoped>
.allowed-option {
  color: $color-secondary-text;
  font-size: $font-small;
  font-weight: lighter;
}

.row-icon {
  width: 18px;
  height: 18px;

  &.disabled {
    color: $color-secondary-text !important;
  }
}
</style>