import EN from './strings_en';
import FR from './strings_fr';
import { createI18n } from 'vue-i18n';

function getBrowserLocale(options = {}) {
    const defaultOptions = { countryCodeOnly: false };
    const opt = { ...defaultOptions, ...options };
    const navigatorLocale = navigator.language || navigator.languages[0];

    if (!navigatorLocale) {
        return undefined;
    }

    const trimmedLocale = opt.countryCodeOnly
        ? navigatorLocale.trim().split(/-|_/)[0]
        : navigatorLocale.trim();

    return trimmedLocale;
}

const messages = {
    'en': EN,
    'fr': FR
}
const browserLocale = getBrowserLocale({ countryCodeOnly: true });
export const i18n = createI18n({
    locale: browserLocale, // set locale
    fallbackLocale: 'en', // set fallback locale
    messages, // set locale messages
});