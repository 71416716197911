<template>
    <div class="card-list-row table-row d-flex flex-column p-3 m-0">
        <p class="d-flex justify-content-center"> {{ printer.name }}</p>
        <div class="d-flex justify-content-center flex-wrap mt-2">
            <p class="default-text d-md-inline-block" >{{ printer.ipAddress }}</p>
        </div>
        <div class="d-flex justify-content-center mt-2" v-if="printer.doublePrint">
            <span class="vat-in bread-crumb secondary-text">X2</span>
        </div>
        <div class="card-list-footer">
            <div class="edit-row-btn d-flex justify-content-center mt-2">
                <button class="delete" @click="$emit('submitDeleteAction', printer)" ><inline-svg :src="require('@/assets/icons/trash.svg')" /></button>
                <button @click="$emit('openPrinter', printer)" ><inline-svg :src="require('@/assets/icons/edit.svg')" /></button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PrinterRow",
    props: ['printer'],
    emits: ['openPrinter', 'submitDeleteAction'],
    methods: {}
}
</script>

<style lang="scss" scoped>

    .edit-row-btn {
        column-gap: 10px;
        background: none !important;
    }
</style>