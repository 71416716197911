<template>
    <div class="main">
        <div class="profile-data-container">
            <p class="letter-icon" :style="`background-color: ${randomHSL(username.charCodeAt(0))}`">
                {{ username.charAt(0).toUpperCase() }}</p>
            <div class="profile-text-container">
                <p class="title"> {{ username === '' ? $t('userPage.username') : username }} </p>
                <p class="subtitle"> {{ type === '' ? $t('userPage.type') : type.toLowerCase() }} </p>
            </div>
        </div>
        <div class="form-input-container">
            <DesignInput v-model:customValue="username" :hint="$t('userPage.username')"/>
            <DesignInput type="password" v-model:customValue="password" :hint="$t('userPage.password')"/>
            <DesignInput v-model:customValue="pincode" :hint="$t('userPage.pincode')"
                        :tooltipText="$t('userPage.pincodeTooltip')"
            />
            <design-select :hint="$t('userPage.type')" v-model:customValue="type">
                <option value="ADMIN">Admin</option>
                <option value="SERVER">Server</option>
                <option value="REGISTER">Register</option>
                <option value="MAIN">Main</option>
            </design-select>
            <DesignInput v-if="isSuperUser" v-model:customValue="terminalIp" :hint="$t('userPage.vwTerminalIp')"/>

        </div>
        <p v-if="addRequest.message" class="text-status error"> {{ addRequest.message }}</p>
        <div class="button-container row">
            <design-button class="button " @customClick="setAddingUser(false)" type="secondary">
                {{ $t('generic.cancel') }}
            </design-button>
            <design-button class="button ml-3" @customClick="submitUserCreate()" :loading="addRequest.loading">
                {{ $t('generic.create') }}
            </design-button>
        </div>
    </div>
</template>

<script>
import DesignInput from '../components/DesignInput.vue';
import DesignSelect from '../components/DesignSelect.vue';
import DesignButton from '../components/DesignButton.vue';
import {colorUtil} from '@/helpers/ColorUtil';
import {mapMutations, mapActions, mapState} from "vuex";

export default {
    name: 'CreateUserDialog',
    components: {
        DesignInput,
        DesignSelect,
        DesignButton,
    },
    methods: {
        ...mapMutations('user', ['setAddingUser']),
        ...mapActions('user', ['createUser']),
        randomHSL(id) {
            return colorUtil.randomHSL(id);
        },
        submitUserCreate() {
            const {username, password, type, pincode, terminalIp} = this;
            this.createUser({username, password, type, pincode, terminalIp});
        }
    },
    computed: {
        ...mapState('user', ['addRequest'])
    },
    data() {
        return {
            username: "",
            password: "",
            type: "SERVER",
            pincode: "",
            terminalIp: null

        }
    },
    mounted() {
    }


}
</script>

<style lang="scss" scoped>
.main {
    width: auto;
    padding: 20px 20px;
}

select {
    padding: 10px 20px 10px 20px;
}

.form-input-container {
    display: flex;
    flex-direction: column;
    row-gap: $default-edge-spacing-l;
}

.button-container {
    margin-top: $default-edge-spacing-xxl;

    .button {
        flex-grow: 1;
    }

}

.profile-data-container {
    padding: 0px 0px 30px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .letter-icon {
        color: $color-primary-text;
        background-color: $color-primary-blue;
        border-radius: 150px;
        text-align: center;
        font-weight: 400;
        font-size: 24px;
        height: 48px;
        width: 48px;
        min-width: 48px;
        min-height: 48px;
        padding: 6px;
    }

    .profile-text-container {
        flex-grow: 1;
        padding-left: $default-edge-spacing-l;
        overflow: hidden;

        .title {
            font-size: $font-title;
            color: $color-primary-text;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .subtitle {
            font-size: $font-subtitle;
            color: $color-secondary-text;
        }
    }

    .delete-button {
        min-width: 18px;
        max-width: 18px;
        margin-right: $default-edge-spacing-xl;
        stroke: $color-pastel-red;

    }


}

</style>