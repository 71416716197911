<template>
  <div class="input-container">
      <div class="hint-container">
          <p class="input-hint" > {{ hint }} </p>
          <span class="tooltip-container" v-if="tooltipText" v-tooltip.left="tooltipText">
              <inline-svg
                  class="icon-info"
                  :src="require('@/assets/icons/info.svg')"></inline-svg>
          </span>
      </div>
      <div class="color-buttons pt-2">
        <button
            v-for="(color, index) in bestColors"
            :key="index"
            :style="{ backgroundColor: color }"
            :class="{ 'selected': color === colorSelected || color === customValue }"
            class="color-button"
            @click="selectColor(color)"
        ></button>
      </div>
  </div>
</template>

<script>
import Tooltip from 'primevue/tooltip';
export default {
    name: "DesignInputColorList",
    directives: {
        'tooltip': Tooltip,
    },
    emits: ['update:customValue'],
    data() {
        return {
          bestColors: [
            '#CC586F',
            '#A270AB',
            '#7B82AD',
            '#4D98A0',
            '#80AA82',
            '#C78C58',
            '#8D6E63',
            '#607D8B',
            '#000000',
            // New colors added
            '#A28C80', // ok
            '#3D1E6D', // ok
            '#D2856A', // ok
            '#3B5323', // ok
            '#3B2F2F',  // ok
            '#4A7A77', // ok
            '#9AA567', // ok
            '#336699', // ok
            '#CC3333' // ok
          ],
          colorSelected: null
        }
    },
    props: {
        'hint': {
            type: String,
            default: ""
        },
        'customValue': {
            type: [String, Number],
            default: ""
        },
        'error' : {
            type: Boolean,
            default: false
        },
        'tooltipText': {
            type: String,
            default: ""
        }
    },
  methods: {
    selectColor(color) {
      this.colorSelected = color;
      this.$emit('update:customValue', color);
    }
  },
}
</script>

<style lang="scss" scoped>
  .color-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }

  .color-button {
    width: 31px; /* Default size for mobile */
    height: 31px; /* Default size for mobile */
    border: 2px solid transparent;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
    padding: 1px;
    transition: border-color 0.3s, box-shadow 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .color-button.selected {
    box-shadow: 0 0 0 3px #8393F4; /* Change this to desired highlight style */
  }

  @media (min-width: 768px) {
    .color-button {
      width: 52px; /* Adjusted size for desktop */
      height: 45px; /* Adjusted size for desktop */
      padding: 4px; /* Adjust padding if necessary */
    }
  }
</style>