import { stockService } from "@/services/StockService";

const state = {
    productStock: [],
    stockRequestMessage: "",
    isLoadingStockRequest: false
};

const actions = {
    getStockHistoryFromOneProduct({dispatch, commit}, productId){
        commit(mutations.setLoadingRequest.name, true);
        stockService.getStockHistoryFromOneProduct(productId)
            .then((response) => {
                commit('setLoadingRequest', false);
                commit(mutations.setStockHistoryList.name, response.data);
            })
            .catch((error) => {
                commit(mutations.setLoadingRequest.name, false, error);
            });
    }
}

const mutations = {
    setLoadingRequest(state, isLoading, message=''){
        state.stockRequestMessage = message;
        state.isLoadingStockRequest = isLoading;
    },
    setStockHistoryList(state, productStock){
        state.productStock = productStock;
    },
}


export const stock = {
    namespaced: true,
    state,
    actions,
    mutations
};