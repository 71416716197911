<template>
  <div>
    <page-header
        iconName="statistic.svg"
        :headerTitle="$t('productPage.stock')"
    />


    <DataTable
        :value="productStock"
        v-model:filters="filters"
        filter-display="row"
        paginator
        :rows="50"
        :rows-per-page-options="[50, 100, 150, 200, 250, 500, 1000]"
        table-style="min-width: 50rem"
        :loading="loading"
        :global-filter-fields="['product.name', 'newStock','stockVariation','user.username']"
    >
      <template #header>
        <div class="flex justify-content-end" style="margin-top: 15px">
            <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
            </span>
        </div>
      </template>
      <template #empty> {{$t('productGroupPage.noFundResultTable')}} </template>
      <template #loading> {{$t('productGroupPage.loadingResultTable')}} </template>
      <Column field="timestamp" header="Date" sortable>
        <template #body="{ data }">
          {{ data.timestamp.toDate()}} <br>
          <span class="default-text">{{ data.timestamp.toTime()}}</span>
        </template>
      </Column>
      <Column field="product.name" header="Name" style="max-width: 12rem"></Column>
      <Column field="newStock" header="New stock" sortable></Column>
      <Column field="stockVariation" header="Stock variation" sortable></Column>
      <Column field="user.username" header="Edited By" sortable></Column>
    </DataTable>


  </div>
</template>

<script>
import { ref } from 'vue';
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import { FilterMatchMode } from 'primevue/api';
import PageHeader from './components/PageHeader.vue'
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';

export default {
  name: "StockPage",
  components: {
    DataTable,
    Column,
    InputText,
    PageHeader
  },
  data() {
    return {
      filters: ref({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'productGroup.name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      }),
      selectedFilterProduct: "",
      currentProductId: "",
      itemFiltered:[],
      currentSelect: {}
    }
  },
  methods: {
    ...mapActions('stock', ['getStockHistoryFromOneProduct']),
  },
  computed: {
    ...mapState('stock', ['productStock'])
  },
  mounted() {
    const segments = window.location.href.split("/");
    const productId = segments[segments.length - 1];
    this.currentProductId = productId;
    this.getStockHistoryFromOneProduct(productId);
  }
}
</script>

<style>

</style>