<template>
  <div>
    <popup-view :isVisible="currentTable != null">
      <edit-table-dialog />
    </popup-view>
    <popup-view :isVisible="deleteOpenDialog" @close="deleteOpenDialog = false">
      <confirm-delete-dialog :product="currentSelect" @submitDeleteAction="submitDelete" @cancelDeleteAction="deleteOpenDialog = false"/>
    </popup-view>
    <page-header 
      :headerTitle="$t('dashboard.shiftTitle')"
      iconName="table.svg" />

    <transition-group class="card-list mt-4" name="list" tag="div">
        <table-row v-for="table in tablesActive" :key="table.tableId" :isDashboard="true" :item="table" @openAccount="openAccount" />
    </transition-group>
  </div>
</template>

<script>
import PageHeader from './components/PageHeader.vue';
import {mapState, mapActions} from 'vuex';
import TableRow from './components/TableRow.vue';
import PopupView from './components/PopupView.vue';
import EditTableDialog from './dialogs/EditTableDialog.vue';
import ConfirmDeleteDialog from './dialogs/ConfirmDeleteDialog.vue';

export default {
    name: "OpenTables",
    components: {
      PageHeader,
      TableRow,
      PopupView,
      EditTableDialog,
      ConfirmDeleteDialog
    },
    data() {
      return {
          deleteOpenDialog: false,
          currentSelect: {}
      }
    },
    methods: {
        ...mapActions('table', ['getTablesActive']),
        openAccount(account) {
            this.$router.push({
                name: "AccountSummary",
                params: { accountId: account.clientAccountId },
            });
        },
    },
    computed: {
      ...mapState('table', ['tablesActive'])
    },
    mounted() {
      this.getTablesActive();
    }
}
</script>

<style>

</style>