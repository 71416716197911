import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import store from './store';
import { i18n } from './lang';
import InlineSvg from 'vue-inline-svg';
import Vue3Toastify, { toast } from 'vue3-toastify';
import PrimeVue from 'primevue/config';
import 'vue3-toastify/dist/index.css';
import Tooltip from 'primevue/tooltip';

// Extend Number prototype
declare global {
    interface Number {
        toDate(): string;
        toTime(): string;
    }
}

Number.prototype.toDate = function (this: number) {
    return new Date(this).toLocaleString('en-GB').split(',')[0];
};

Number.prototype.toTime = function (this: number) {
    return new Date(this).toLocaleString('en-GB').split(',')[1];
};

const sharedFunc = {
    date: Number.prototype.toDate,
    time: Number.prototype.toTime,
    install(app: any) {
        app.config.globalProperties.$sharedFunc = sharedFunc;
    }
};

const globalMixin = {
    methods: {
        isPhone(this: any) {
            return window.innerWidth < 768;        },
    },
};

const appInstance = createApp(App);
appInstance.use(router);
appInstance.use(Vue3Toastify, {
    autoClose: 1500,
    transition: toast.TRANSITIONS.SLIDE,
    position: toast.POSITION.TOP_RIGHT,
    limit: 4,
    hideProgressBar: true,
    icon: false,
});
appInstance.use(store);
appInstance.use(i18n);
appInstance.use(PrimeVue);
appInstance.directive('tooltip', Tooltip);
appInstance.use(sharedFunc);
appInstance.component('inline-svg', InlineSvg);
appInstance.mixin(globalMixin);
appInstance.mount('#app');
