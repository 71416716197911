import { analyticsService } from "@/services/AnalyticsService";
import {FormattedChartData} from "@/models/FormattedChartData";
import { i18n as $t} from '@/lang'; // Import your Vue I18n instance


const state = {
    paymentsData: {},
    orderedProductsData: null,
    accountsData: null,
    paymentsRequest: {
        loading: false
    },
    orderedProductsRequest: {
        loading: false
    },
    accountsRequest: {
        loading: false
    },
    clientAccountSummaryRequest: {
        loading: false
    },
    dashboardRequest: {
        loading: false,
        data: null
    },
    queryStartDateTime: 0,
    queryEndDateTime: 0,
    clientAccountSummaryData : null,
    currentTimeInterval: "TODAY",
    dashboardAccountsChartData: null,
    dashboardSalesChartData: null,
    dashboardDailySalesFormattedChartData: null,
    dashboardRushFormattedChartData: null,
    dashboardTopSalesFormattedChartData: null,
    rawDashboardData: null

}

const actions = {
    getDashboardData({commit, state}, timeInterval) {
        state.currentTimeInterval = timeInterval
        commit('setDashboardRequest', {loading: true})
        analyticsService.getDashboardData(timeInterval)
            .then((response) => {
                console.log("Received dashbaord "+response.data);
                commit("setDashboardRequest", {data: response.data});
                commit("setDashboardChartData" , response.data);
            })
            .catch((error) => commit("setDashboardRequest", {message: error}))
    },
    getPaymentTransactions({commit, state}) {
        commit('setPaymentsRequest', {loading: true});
        //console.log(state.queryStartDateTime, state.queryEndDateTime);
        analyticsService.getPaymentTransactions(state.queryStartDateTime, state.queryEndDateTime)
            .then((response) => {
                commit('setPaymentsRequest', {loading: false});
                commit('updatePaymentsData', response.data);
            })
            .catch((error) => commit('setPaymentsRequest', {loading: false, message: error}));
    },
    getOrderedProductsHistory({commit, state}) {
        commit('setOrderedProductsRequest', {loading: true});
        //console.log(state.queryStartDateTime, state.queryEndDateTime);
        analyticsService.getOrderedProductsHistory(state.queryStartDateTime, state.queryEndDateTime)
            .then((response) => {
                commit('setOrderedProductsRequest', {loading: false});
                commit('updateOrderedProductsData', response.data);
            })
            .catch((error) => commit('setOrderedProductsRequest', {loading: false, message: error}));
    },
    getAccountsHistory({commit, state}) {
        commit('setAccountsRequest', {loading: true});
        //console.log(state.queryStartDateTime, state.queryEndDateTime);
        analyticsService.getClientAccountsHistory(state.queryStartDateTime, state.queryEndDateTime)
            .then((response) => {
                commit('setAccountsRequest', {loading: false});
                commit('updateAccountsData', response.data);
            })
            .catch((error) => commit('setAccountsRequest', {loading: false, message: error}));
    },
    deleteOrderedProduct({dispatch, commit}, id) {
        commit('setOrderedProductsRequest', {loading: true});
        analyticsService.deleteOrderedProduct(id)
            .then((response) => {
                dispatch('getOrderedProductsHistory');
            })
            .catch((error) => commit('setOrderedProductsRequest', {loading: false, message: error}));
    },
    deleteOrderedProductWithAccount({dispatch, commit}, {accountId, productId}) {
        commit('setOrderedProductsRequest', {loading: true});
        analyticsService.deleteOrderedProduct(productId)
            .then((response) => {
                dispatch('getClientAccountSummary', accountId);
            })
            .catch((error) => commit('setOrderedProductsRequest', {loading: false, message: error}));
    },
    updateOrderedProduct({dispatch, commit}, orderedProduct) {
        commit('setAccountSummaryRequest', {loading: true});
        analyticsService.updateOrderedProduct(orderedProduct)
            .then((response) => {
                dispatch('getClientAccountSummary', state.clientAccountSummaryData.clientAccount.clientAccountId);
                commit('setAccountSummaryRequest', {loading: false});
            })
            .catch((error) => commit('setAccountSummaryRequest', {loading: false, message: error}));
    },
    deleteAccount({dispatch, commit}, accountId){
        commit('setOrderedProductsRequest', {loading: true});
        analyticsService.deleteAccount(accountId)
            .then((response) => {
                dispatch('getClientAccountSummary', accountId);
            })
            .catch((error) => commit('setOrderedProductsRequest', {loading: false, message: error}));
    },
    deleteAccountWithHistory({dispatch, commit}, accountId) {
        commit('setAccountsRequest', {loading: true});
        analyticsService.deleteAccount(accountId)
            .then((response) => {
                dispatch('getAccountsHistory');
            })
            .catch((error) => commit('setAccountsRequest', {loading: false, message: error}));
    },
    getClientAccountSummary({commit}, id) {
        commit('setAccountSummaryRequest', {loading: true});
        analyticsService.getClientAccountSummary(id)
            .then((response) => {
                commit('setAccountSummaryRequest', {loading: false});
                commit('updateAccountSummaryData', response.data);
            })
            .catch((error) => commit('setAccountSummaryRequest', {loading: false, message: error}));
    }
}

const mutations = {
    setPaymentsRequest(state, {loading, message}){
        state.paymentsRequest = {
            loading, message
        };
    },
    updatePaymentsData(state, paymentsData){
        state.paymentsData = paymentsData;
    },
    //ordered products
    setOrderedProductsRequest(state, {loading, message}){
        state.orderedProductsRequest = {
            loading, message
        };
    },
    updateOrderedProductsData(state, orderedProductsData){
        state.orderedProductsData = orderedProductsData;
    },
    //accounts
    setAccountsRequest(state, {loading, message}){
        state.accountsRequest = {
            loading, message
        };
    },
    updateAccountsData(state, accountsData){
        state.accountsData = accountsData;
    },
    setQueryStartDateTime(state, startDate) {
        state.queryStartDateTime = startDate;
    },
    setQueryEndDateTime(state, endDate) {
        state.queryEndDateTime = endDate;
    },
    //account summary
    updateAccountSummaryData(state, summary) {
        state.clientAccountSummaryData = summary;
    },
    setAccountSummaryRequest(state, {loading, message}){
        state.clientAccountSummaryRequest = {
            loading, message
        };
    },
    setDashboardRequest(state, data) {
        state.dashboardRequest = data;
    },
    setDashboardChartData(state, data) {
        state.rawDashboardData = data;
        state.dashboardAccountsChartData = computeAccountsChartData(data);
        state.dashboardSalesChartData = computeSalesChartData(data);
        state.dashboardDailySalesFormattedChartData = computeFormattedChartData(data.salesChartData);
        state.dashboardRushFormattedChartData = computeFormattedChartData(data.rushHourChartData);
        state.dashboardTopSalesFormattedChartData = computeFormattedChartData(data.topSellerChartData)
    },
}

function computeAccountsChartData(data) {
    let accountsData = data.accountsData;
    let labels = ["Tables", "Takeaway", "Delivery"];
    let chartData = [accountsData.tableCount, accountsData.takeawayCount, accountsData.deliveryCount];
    let formattedChartData = new FormattedChartData(chartData, labels)
    let totalCount = accountsData.totalCount;
    return  {
        totalCount:  totalCount,
        chartData:  formattedChartData
    }
}

function computeSalesChartData(data) {
    //compute sales count chart data
    let salesData = data.salesData;
    let labels = ["Cash", "Card", "Offered"];
    let chartData = [salesData.totalCash, salesData.totalCard, salesData.totalOffered];
    let formattedChartData = new FormattedChartData(chartData, labels)
    let totalCount = salesData.total.toFixed(2);
    return {
        totalCount:  totalCount,
        chartData:  formattedChartData
    }
}

/**
 * Takes a list of objects [name, count] and converts it to a {FormattedChartData}
 * @param data
 * @returns {FormattedChartData}
 */
function computeFormattedChartData(data) {
    let labels = data.map(item => item.name);
    let count = data.map(item => item.count);
    let formattedChartData = new FormattedChartData(count, labels);
    return formattedChartData;
}

export const analytics = {
    namespaced: true,
    state,
    actions,
    mutations,
}