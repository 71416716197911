import {CustomChartData} from "./CustomChartData";

export class FormattedChartData {
    data: number[];
    labels: string[];

    constructor(data: number[], labels: string[]) {
        this.data = data;
        this.labels = labels;
    }

    load(items: CustomChartData[]) {
        this.data = items.map(item => item.count);
        this.labels = items.map(item => item.name);
    }
}
