<template>
    <div class="card-list-row table-row d-flex p-3 m-0 justify-content-between align-items-center" :style="{'border': '2px solid' + item.color}">
        <p class="d-flex justify-content-center">{{ item.name }}</p>
        <div class="card-list-footer">
            <div class="edit-row-btn d-flex justify-content-center mt-2">
                <button class="delete" @click="$emit('secondaryAction', item)" ><inline-svg :src="require('@/assets/icons/trash.svg')" /></button>
                <button @click="setCurrentType(item)" ><inline-svg :src="require('@/assets/icons/edit.svg')" /></button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations} from "vuex";

export default {
    name: "TypeRow",
    props: ['item', 'order'],
    emits: ['primaryAction', 'secondaryAction', 'openAccount'],
    methods: {
        ...mapMutations('table', ['setCurrentType']),
    }
}
</script>

<style lang="scss" scoped>

    .edit-row-btn {
        column-gap: 10px;
        background: none !important;
    }
    .card-list-row{
        @media (max-width: $screen-md ) {
            flex-direction: column!important;
        }
    }
    .card-list-footer {
        margin-top: 0!important;
        .edit-row-btn {
            margin-top: 0!important;
        }
    }
 
</style>