<template>
  <div class="design-multiple-options">
      <p class="options-hint"> {{ hint }} </p>
      <div class="options-container">
        <p 
        v-for="option in options" 
        @click="$emit('optionClicked', option)"
        :key="option[valuePropId]" 
        :class="selectedItems.filter(e => e[valuePropId] == option[valuePropId]).length > 0 ? 'selected' : ''">
            {{ option[valuePropName] }}
        </p>
      </div>
      
  </div>
</template>

<script>
export default {
    name: "DesignMultipleOptions",
    emits: ['optionClicked'],
    props: {
        'hint': {
            default: "",
            type: String,
        },
        'options': {
            default: () => [],
            type: Array,
        },
        'valuePropName': {
            default: "",
            type: String,
        },
        'valuePropId': {
            default: "",
            type: String,
        },
        'selectedItems': {
            default: () => [],
            type: Array,
        },
    }

}
</script>

<style lang="scss" scoped>
    .design-multiple-options {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        .options-hint {
            padding: 0 !important;
            font-size: 12px;
            font-weight: normal;
            color: $color-secondary-text;
            opacity: 0.8;
            margin: 0 0 8px 8px;
        }

        .options-container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            column-gap: 10px;
            row-gap: 5px;

            p {
                background: $color-input-back;
                border-radius: $radius-small;
                padding: 5px 10px;
                font-weight: 200;
                font-size: $font-title;
                color: $color-secondary-text;
                cursor: pointer;

                &.selected {
                    background: $color-primary-blue;
                    color: $color-white-text;
                }

            }
        }

        
    }
</style>