import {tableService} from '@/services/TableService';
import { toast } from "vue3-toastify";
import { i18n } from "./../lang";

const state = {
  tables: [],
  zones: [],
  types: [],
  tablesActive: [],
  takeaways: [],
  tableRequest: {
    loading: false,
  },
  zoneRequest: {
    loading: false,
  },
  typeRequest: {
    loading: false,
  },
  takeawayRequest: {
    loading: false,
  },
  currentTable: null,
  editingTable: null,
  currentZone: null,
  editingZone: null,
  currentType: null,
  editingType: null,
};

const actions = {
  getTables({ commit }) {
    commit("setTableRequest", { loading: true });
    tableService
      .getTables()
      .then((response) => {
        commit("setTableRequest", { loading: false });
        commit("updateTables", response.data);
      })
      .catch((error) =>
        commit("setTableRequest", { loading: false, message: error })
      );
  },
  getTakeaway({ commit }) {
    commit("setTakeawayRequest", { loading: true });
    tableService
      .getTakeawayService()
      .then((response) => {
        commit("setTakeawayRequest", { loading: false });
        commit("updateTakeaway", response.data);
      })
      .catch((error) =>
        commit("setTakeawayRequest", { loading: false, message: error })
      );
  },
  getTablesActive({ commit }) {
    commit("setTableRequest", { loading: true });
    tableService
      .getTables()
      .then((response) => {
        commit("setTableRequest", { loading: false });
        commit("updateTables", response.data);
        const occupiedTables = response.data.filter(
          (table) => table.status === "OCCUPIED"
        );
        commit("updateTablesActive", occupiedTables);
      })
      .catch((error) =>
        commit("setTableRequest", { loading: false, message: error })
      );
  },
  updateTable({ commit, state }, table) {
    commit("setTableRequest", { loading: true });
    tableService
      .updateTable(table)
      .then((response) => {
        toast.success(i18n.global.t("toast.successEdit"));
        const updatedTables = state.tables.map((x) =>
          x.tableId == table.tableId ? table : x
        );
        commit("updateTables", updatedTables);
        commit("setCurrentTable", null);
      })
      .catch((error) => {
        toast.error(i18n.global.t("toast.errorEdit"));
        commit("setTableRequest", { loading: false, message: error });
      });
  },
  addTable({ commit, state }, table) {
    commit("setTableRequest", { loading: true });
    tableService
      .addTable(table)
      .then((response) => {
        toast.success(i18n.global.t("toast.createSuccess"));
        const updatedTables = state.tables;
        updatedTables.push(response.data);
        commit("updateTables", updatedTables);
        commit("setCurrentTable", null);
      })
      .catch((error) => {
        toast.error(i18n.global.t("toast.createError"));
        commit("setTableRequest", { loading: false, message: error });
      });
  },
  deleteTable({ commit, state }, table) {
    commit("setTableRequest", { loading: true });
    tableService
      .deleteTable(table)
      .then((response) => {
        toast.success(i18n.global.t("toast.deleteSuccess"));
        commit("setTableRequest", { loading: false });
        const updatedTables = state.tables.filter(function (element) {
          return element.tableId !== table.tableId;
        });
        commit("updateTables", updatedTables);
        commit("setCurrentTable", null);
      })
      .catch((error) => {
        toast.error(i18n.global.t("toast.deleteError"));
        commit("setTableRequest", { loading: false, message: error });
      });
  },
  getZones({ commit }) {
    commit("setZoneRequest", { loading: true });
    tableService
      .getZones()
      .then((response) => {
        commit("setZoneRequest", { loading: false });
        commit("updateZones", response.data);
      })
      .catch((error) =>
        commit("setZoneRequest", { loading: false, message: error })
      );
  },
  getTypes({ commit }) {
    commit("setTypeRequest", { loading: true });
    tableService
      .getTypes()
      .then((response) => {
        commit("setTypeRequest", { loading: false });
        commit("updateTypes", response.data);
      })
      .catch((error) =>
        commit("setTypeRequest", { loading: false, message: error })
      );
  },
  addZone({ commit, state }, zone) {
    commit("setZoneRequest", { loading: true });
    tableService
      .addZone(zone)
      .then((response) => {
        toast.success(i18n.global.t("toast.createSuccess"));
        const updatedZones = state.zones;
        updatedZones.push(response.data);
        commit("updateZones", updatedZones);
        commit("setCurrentZone", null);
      })
      .catch((error) => {
        toast.error(i18n.global.t("toast.createError"));
        commit("setZoneRequest", { loading: false, message: error });
      });
  },
  addType({ commit, state }, type) {
    commit("setTypeRequest", { loading: true });
    tableService
      .addTypeService(type)
      .then((response) => {
        toast.success(i18n.global.t("toast.createSuccess"));
        const updatedTypes = state.types;
        updatedTypes.push(response.data);
        commit("updateTypes", updatedTypes);
        commit("setCurrentType", null);
      })
      .catch((error) => {
        toast.error(i18n.global.t("toast.createError"));
        commit("setTypeRequest", { loading: false, message: error });
      });
  },
  updateZone({ commit, state }, zone) {
    commit("setZoneRequest", { loading: true });
    tableService
      .updateZone(zone)
      .then((response) => {
        toast.success(i18n.global.t("toast.successEdit"));
        const updatedZones = state.zones.map((x) =>
          x.zoneId == zone.zoneId ? zone : x
        );
        commit("updateZones", updatedZones);
        commit("setCurrentZone", null);
      })
      .catch((error) => {
        toast.error(i18n.global.t("toast.errorEdit"));
        commit("setZoneRequest", { loading: false, message: error });
      });
  },
  updateType({ commit, state }, type) {
    commit("setTypeRequest", { loading: true });
    tableService
      .updateType(type)
      .then((response) => {
        toast.success(i18n.global.t("toast.successEdit"));
        const updatedTypes = state.types.map((x) =>
          x.id == type.id ? type : x
        );
        commit("updateTypes", updatedTypes);
        commit("setCurrentType", null);
      })
      .catch((error) => {
        toast.error(i18n.global.t("toast.errorEdit"));
        commit("setTypeRequest", { loading: false, message: error });
      });
  },
  deleteZone({ commit, state }, zone) {
    commit("setZoneRequest", { loading: true });
    tableService
      .deleteZone(zone)
      .then((response) => {
        toast.success(i18n.global.t("toast.deleteSuccess"));
        commit("setZoneRequest", { loading: false });
        commit("updateZonesDelete", zone);
        commit("setCurrentZone", null);
      })
      .catch((error) => {
        toast.error(i18n.global.t("toast.deleteError"));
        commit("setZoneRequest", { loading: false, message: error });
      });
  },
  deleteType({ commit, state }, type) {
    commit("setTypeRequest", { loading: true });
    tableService
      .deleteTypeService(type)
      .then((response) => {
        toast.success(i18n.global.t("toast.deleteSuccess"));
        commit("setTypeRequest", { loading: false });
        commit("updateTypesDelete", type);
        commit("setCurrentType", null);
      })
      .catch((error) => {
        toast.error(i18n.global.t("toast.deleteError"));
        commit("setTypeRequest", { loading: false, message: error });
      });
  },
};

const mutations = {
  setTakeawayRequest(state, { loading, message }) {
    state.tableRequest = {
      loading,
      message,
    };
  },
  setTableRequest(state, { loading, message }) {
    state.takeawayRequest = {
      loading,
      message,
    };
  },
  setZoneRequest(state, { loading, message }) {
    state.zoneRequest = {
      loading,
      message,
    };
  },
  setTypeRequest(state, { loading, message }) {
    state.typeRequest = {
      loading,
      message,
    };
  },
  updateTables(state, newTables) {
    state.tables = newTables;
  },
  updateZonesDelete(state, newZones) {
    const updatedTables = state.zones.filter(function (element) {
      return element.zoneId !== newZones.zoneId;
    });
    state.zones = updatedTables;
  },
  updateTypesDelete(state, newTypes) {
    const updatedTables = state.types.filter(function (element) {
      return element.id !== newTypes.id;
    });
    state.types = updatedTables;
  },
  updateZones(state, newZones) {
    state.zones = newZones;
  },
  updateTypes(state, newTypes) {
    state.types = newTypes;
  },
  updateTakeaway(state, takeaway) {
    state.takeaways = takeaway;
  },
  updateTablesActive(state, newTables) {
    state.tablesActive = newTables;
  },
  setCurrentTable(state, currentTable) {
    state.currentTable = currentTable;
    if (currentTable === null) {
      state.tableRequest = {};
    }
  },
  setCurrentZone(state, currentZone) {
    state.currentZone = currentZone;
    if (currentZone === null) {
      state.zoneRequest = {};
    }
  },
  setCurrentType(state, currentType) {
    state.currentType = currentType;
    if (currentType === null) {
      state.typeRequest = {};
    }
  },
};

export const table = {
    namespaced: true,
    state,
    actions,
    mutations
}