<template>
    <div class="main">
      <div class="form-input-container">
        <DesignInput v-model:customValue="typeName" type="text" :hint="$t('tablePage.typeName')" />
      </div>
      <p v-if="typeRequest.message" class="text-status error"> {{ typeRequest.message}}</p>
      <div class="button-container row">
          <design-button class="button col-12 col-md-6" @customClick="setCurrentType(null)" type="secondary"> {{$t('generic.cancel')}} </design-button >
          <design-button class="button col-12 col-md-6"  @customClick="submitType()" :loading="typeRequest.loading"> {{$t('generic.save')}} </design-button >
      </div>
  </div>
</template>

<script>
import {mapActions, mapState, mapMutations} from "vuex";
import DesignInput from '../components/DesignInput.vue';
import DesignButton from '../components/DesignButton.vue';
export default {
    name: 'EditTypeDialog',
    components: {
        DesignInput,
        DesignButton,
    },
    data() {
        return {
            typeName: '',
            isNew: false,
        }
    },
    methods: {
        ...mapMutations('table', ['setCurrentType']),
        ...mapActions('table', ['addType', 'updateType']),
        submitType(){
            const typeToSend = this.currentType;
            if (this.isNew){
              typeToSend.tableTypeName = this.typeName;
              this.addType(typeToSend);
            }
            else{
              typeToSend.name = this.typeName;
              this.updateType(typeToSend);
            }
        }
    },
    computed: {
        ...mapState('table', ['typeRequest', 'currentType'])
    },
    mounted() {
        if (!$.isEmptyObject(this.currentType)){
            this.isNew = false;
            this.typeName = this.currentType.name;
        }
        else {
            this.isNew = true;
        }
    }

}
</script>

<style lang="scss" scoped>
    .main {
        width: auto;
        padding: 20px 20px;
    }   

    select {
        padding: 10px 20px 10px 20px;
    }

    .form-input-container {
        display: flex;
        flex-direction: column;
        row-gap: $default-edge-spacing-l;
    }

    .button-container {
        margin-top: $default-edge-spacing-l;
        .button {
            width: 100%;

            &:nth-child(1) {
                padding-right: $default-edge-spacing !important;
            }
        }

    }

    .profile-data-container {
        padding: 0px 0px 30px 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        
        .letter-icon {
            color: $color-primary-text;
            background-color: $color-primary-blue;
            border-radius: 150px;
            text-align: center;
            font-weight: 400;
            font-size: 24px;
            height: 48px;
            width: 48px;
            min-width: 48px;
            min-height: 48px;
            padding: 6px;
        }

        .profile-text-container {
            flex-grow: 1;
            padding-left: $default-edge-spacing-l;
            overflow: hidden;
            .title {
                font-size: $font-title;
                color: $color-primary-text;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .subtitle {
                font-size: $font-subtitle;
                color: $color-secondary-text;
            }
        }

        .delete-button {
            min-width: 18px;
            max-width: 18px;
            margin-right: $default-edge-spacing-xl;
            stroke: $color-pastel-red;

        }


    }

    @media (max-width: $screen-md ) {

        .button-container {
            padding-top: $default-edge-spacing;
            .button {
                margin-top: $default-edge-spacing-l;

                &:nth-child(1) {
                    padding-right: 0 !important;
                }
            }

            
        }

    }
</style>