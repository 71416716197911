import apiClient from '../helpers/ApiClient'
import { handleError, authHeader } from '../helpers/ApiClient';

export const analyticsService = {
    getPaymentTransactions,
    getOrderedProductsHistory,
    getClientAccountsHistory,
    deleteOrderedProduct,
    getClientAccountSummary,
    deleteAccount,
    updateOrderedProduct,
    getDashboardData
}

function getDashboardData(timeInterval) {
    return apiClient.get("/data/dashboard", {
        params: {timeInterval: timeInterval},
        headers: authHeader()}
    )
    .then((response) => {
        return response.data
    })
    .catch((error) => handleError(error));
}

function getPaymentTransactions(startDateString, endDateString) {

    let startMs = new Date(startDateString).getTime();
    let endMs = new Date(endDateString).getTime();
    
    return apiClient.get("/data/payments", {
        params: {startDate: startMs, endDate: endMs},
        headers: authHeader()}
    )
    .then((response) => {
        return response.data
    })
    .catch((error) => handleError(error));
}

function getOrderedProductsHistory(startDateString, endDateString) {

    let startMs = new Date(startDateString).getTime();
    let endMs = new Date(endDateString).getTime();

    console.log(startMs, endMs);
    
    return apiClient.get("/data/ordered_products", {
        params: {startDate: startMs, endDate: endMs},
        headers: authHeader()}
    )
    .then((response) => {
        return response.data
    })
    .catch((error) => handleError(error));
}

function getClientAccountsHistory(startDateString, endDateString) {

    let startMs = new Date(startDateString).getTime();
    let endMs = new Date(endDateString).getTime();
    
    return apiClient.get("/data/accounts", {
        params: {startDate: startMs, endDate: endMs},
        headers: authHeader()}
    )
    .then((response) => {
        return response.data
    })
    .catch((error) => handleError(error));
}

function getClientAccountSummary(id) {
    return apiClient.get(`/client_account/${id}`, {
        headers: authHeader()}
    )
    .then((response) => {
        return response.data
    })
    .catch((error) => handleError(error));
}

function deleteOrderedProduct(id) {
    return apiClient.delete(`/data/ordered_products/${id}`, {
        headers: authHeader()}
    )
    .then((response) => {
        return response.data
    })
    .catch((error) => handleError(error));
}

function deleteAccount(accountId) {
    return apiClient.delete(`/client_account/${accountId}`, {
        headers: authHeader()}
    )
    .then((response) => {
        return response.data
    })
    .catch((error) => handleError(error));
}

function updateOrderedProduct(orderedProduct) {
    return apiClient.patch(`/data/ordered_products/${orderedProduct.orderedProductId}`, orderedProduct, {
        headers: authHeader()}
    )
    .then((response) => {
        return response.data
    })
    .catch((error) => handleError(error));
}