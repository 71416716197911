<template>
    <transition>
        <div
            v-if="isVisible"
            ref="main"
            class="main"
            @click="onCloseClick($event)"
        >
            <div ref="card" :class="'card card-container ' + (isVisible ? 'popup-shown' : 'popup-hidden')">
                <slot></slot>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'PopupView',
    props: {
        isVisible: {
            type: Boolean,
            default: false,
        }
    },
    emits: ['close'],
    methods: {
        close() {
            this.$refs.card.addEventListener('animationend', () => {
                this.$emit('close');
                //remove the listener
                this.$refs.card.removeEventListener('animationend', () => {});
            });
            if (this.$refs.card.classList.contains('popup-shown')) {
                this.$refs.card.classList.remove('popup-shown');
                this.$refs.card.classList.add('popup-hidden');
            }
        },
        onCloseClick(event) {
            //check if event target is on the card section
            if (event.target === this.$refs.main) {
                this.close();
            }
        }
    },
    mounted() {

    }
}
</script>

<style lang="scss" scoped>
.main {
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: right;

}

.card {
    max-height: 100vh;
    overflow-y: auto;
    max-width: 600px;
    border-top-left-radius: $radius-default;
    border-bottom-left-radius: $radius-default;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    animation-duration: .3s;
    animation-fill-mode: forwards ;

    &.popup-shown {
        animation-name: slideInRight;
    }

    &.popup-hidden {
        animation-name: slideOutRight;
    }
}

/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
    transition: all .2s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}

@keyframes slideInRight {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }

    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}

@keyframes slideOutRight {
    0% {
        opacity: 1;
        transform: translateX(0%);
    }

    100% {
        opacity: 0;
        transform: translateX(100%);
    }
}

@keyframes SlideInDown {
    0% {
        opacity: 0;
        transform: translateY(100%);
    }

    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}

@keyframes SlideOutDown {
    0% {
        opacity: 1;
        transform: translateY(0%);
    }

    100% {
        opacity: 0;
        transform: translateY(100%);
    }
}


@media (max-width: $screen-md) {
    .card {
        width: 100%;
        height: 95%;
        position: absolute;
        bottom: 0;
        overflow-y: scroll;
        border-radius: $radius-default $radius-default 0 0;
        transition: all 1s ease-in-out;

        &.popup-shown {
            animation-name: SlideInDown;
        }

        &.popup-hidden {
            animation-name: SlideOutDown;
        }
    }

}
</style>