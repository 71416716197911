import apiClient from '../helpers/ApiClient'
import { handleError, authHeader } from '@/helpers/ApiClient';

export const clientService = {
    getClientData
}

function getClientData() {
    return apiClient.get(`/client_data?all=true`, {
        headers: authHeader()}
    )
        .then((response) => {
            return response.data
        })
        .catch((error) => handleError(error));
}