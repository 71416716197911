<template>
    <div class="main">
        <div class="profile-data-container">
            <p class="letter-icon" :style="`background-color: ${randomHSL(editingUser.username.charCodeAt(0))}`">
                {{ editingUser.username.charAt(0).toUpperCase() }}</p>
            <div class="profile-text-container">
                <p class="title"> {{ username }} </p>
                <p class="subtitle"> {{ type.toLowerCase() }} </p>
            </div>
        </div>
        <div class="form-input-container">
            <DesignInput v-model:customValue="username" :hint="$t('userPage.username')"/>
            <DesignInput v-model:customValue="password" :hint="$t('userPage.password')"/>
            <DesignInput v-model:customValue="pincode" :hint="$t('userPage.pincode')"
                         :tooltipText="$t('userPage.pincodeTooltip')"
            />
            <design-select :hint="$t('userPage.type')" v-model:customValue="type">
                <option value="ADMIN">Admin</option>
                <option value="SERVER">Server</option>
                <option value="REGISTER">Register</option>
                <option value="MAIN">Main</option>
            </design-select>
            <DesignInput v-model:customValue="terminalIp" :hint="$t('userPage.vwTerminalIp')"/>
            <design-select v-if="type === 'REGISTER' || type === 'MAIN'" :hint="$t('userPage.userPrinter')"
                           v-model:customValue="printerId"
                            :tooltip-text="$t('userPage.userPrinterTooltip')"
            >
                <option value="null">None</option>
                <option v-for="item in printers" :key="item.id" :value="item.id">{{ item.name }}</option>
            </design-select>
            <DesignMultiSelectWrapper :hint="$t('userPage.selectAbilities')">
                <MultiSelect v-model="userSelectedAbilities" :options="userAbilityOptions" optionLabel="name" option-value="value" placeholder="None"
                             :maxSelectedLabels="3" optionGroupLabel="groupLabel" optionGroupChildren="groupChildren" class="w-full multiselect" >
                    <template #optiongroup="slotProps">
                        <div class="flex align-items-center">
                            <hr>
                            <div>{{ slotProps.option.groupLabel }}</div>
                        </div>
                    </template>
                </MultiSelect>
            </DesignMultiSelectWrapper>


        </div>
        <p v-if="editRequest.message" class="text-status error"> {{ editRequest.message }}</p>
        <div class="button-container row">
            <design-button class="button" @customClick="setUserEdit(null)" type="secondary">
                {{ $t('generic.cancel') }}
            </design-button>
            <design-button class="button ml-3" @customClick="submitUserUpdate()" :loading="editRequest.loading">
                {{ $t('generic.save') }}
            </design-button>
        </div>
    </div>
</template>

<script>
import DesignInput from '../components/DesignInput.vue';
import DesignSelect from '../components/DesignSelect.vue';
import DesignButton from '../components/DesignButton.vue';
import {colorUtil} from '@/helpers/ColorUtil';
import {mapMutations, mapActions, mapState} from "vuex";
import MultiSelect from 'primevue/multiselect';
import DesignMultiSelectWrapper from "@/views/components/DesignMultiSelectWrapper.vue";

export default {
    name: 'EditUserDialog',
    components: {
        DesignInput,
        DesignSelect,
        DesignButton,
        MultiSelect,
        DesignMultiSelectWrapper
    },
    computed: {
        ...mapState('user', ['editingUser', 'editRequest']),
        ...mapState('account', ['isSuperUser']),
        ...mapState('printer', ['printers'])
    },
    methods: {
        ...mapMutations('user', ['setUserEdit']),
        ...mapActions('user', ['updateUser']),
        ...mapActions('printer', ['getPrinters']),
        randomHSL(id) {
            return colorUtil.randomHSL(id);
        },
        submitUserUpdate() {
            const newUserData = this.editingUser;
            const parentThis = this;
            newUserData.username = this.username;
            newUserData.password = this.password;
            newUserData.type = this.type;
            newUserData.pincode = this.pincode;
            newUserData.terminalIp = this.terminalIp;
            newUserData.printer = this.printers.find(function (element) {
                return element.id.toString() === parentThis.printerId?.toString()
            });
            newUserData.abilities = this.userSelectedAbilities;
            this.updateUser(newUserData);
        }
    },
    data() {
        return {
            username: "",
            password: "",
            type: "",
            pincode: "",
            terminalIp: null,
            printerId: null,
            userSelectedAbilities: [],
            userAbilityOptions: [
                {
                    groupLabel: "Admin",
                    groupChildren: [
                        {
                            name: "Login",
                            value: "ADMIN_LOGIN"
                        },
                        {
                            name: "Products",
                            value: "ADMIN_PRODUCTS"
                        },
                        {
                            name: "groups",
                            value: "ADMIN_GROUPS"
                        },
                        {
                            name: "tables",
                            value: "ADMIN_TABLES"
                        },
                        {
                            name: "printers",
                            value: "ADMIN_PRINTERS"
                        },
                        {
                            name: "clients",
                            value: "ADMIN_CLIENT_DATA"
                        }
                    ]
                },
                {
                    groupLabel: "Analytics",
                    groupChildren: [
                        {
                            name: "Dashboard",
                            value: "ANALYTICS_DASHBOARD"
                        },
                        {
                            name: "Accounts",
                            value: "ANALYTICS_ACCOUNTS"
                        },
                        {
                            name: "Shifts",
                            value: "ANALYTICS_SHIFTS"
                        },
                        {
                            name: "Orders",
                            value: "ANALYTICS_ORDERS"
                        },
                        {
                            name: "Stock",
                            value: "ANALYTICS_STOCK"
                        }
                    ]
                },
                {
                    groupLabel: "Payment",
                    groupChildren: [
                        {
                            name: "Error",
                            value: "PAYMENT_ERROR"
                        },
                        {
                            name: "Bank Transfer",
                            value: "PAYMENT_OFFERED"
                        },
                        {
                            name: "Offered",
                            value: "PAYMENT_BANK_TRANSFER"
                        }
                    ]
                }
            ]
        }
    },
    mounted() {
        console.log(this.editingUser);
        if (this.editingUser) {
            this.username = this.editingUser.username;
            this.password = this.editingUser.password;
            this.type = this.editingUser.type;
            this.pincode = this.editingUser.pincode;
            this.terminalIp = this.editingUser.terminalIp;
            this.printerId = this.editingUser?.printer?.id;
            this.userSelectedAbilities = this.editingUser.abilities;
        }
        this.getPrinters()
    }


}
</script>

<style lang="scss" scoped>
.main {
    width: auto;
    padding: 20px 20px;
}

select {
    padding: 10px 20px 10px 20px;
}

.form-input-container {
    display: flex;
    flex-direction: column;
    row-gap: $default-edge-spacing-l;
}

.button-container {
    margin-top: $default-edge-spacing-xxl;

    .button {
        flex-grow: 1;
    }

}

.profile-data-container {
    padding: 0px 0px 30px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .letter-icon {
        color: $color-primary-text;
        background-color: $color-primary-blue;
        border-radius: 150px;
        text-align: center;
        font-weight: 400;
        font-size: 24px;
        height: 48px;
        width: 48px;
        min-width: 48px;
        min-height: 48px;
        padding: 6px;
    }

    .profile-text-container {
        flex-grow: 1;
        padding-left: $default-edge-spacing-l;
        overflow: hidden;

        .title {
            font-size: $font-title;
            color: $color-primary-text;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .subtitle {
            font-size: $font-subtitle;
            color: $color-secondary-text;
        }
    }

    .delete-button {
        min-width: 18px;
        max-width: 18px;
        margin-right: $default-edge-spacing-xl;
        stroke: $color-pastel-red;

    }

    .multiselect {
        z-index: 99999;
        animation: none;
    }


}

</style>