<script lang="ts">
import {defineComponent, PropType} from "vue";

    export default defineComponent({
        name: "ShiftDataItem",
        props: {
            itemName: {
                type: String,
                required: true
            },
            itemValue: {
                type: [String, null] as PropType<string | null>,
                required: true
            },
            bigText: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            getValueItemDynamicClass() {
                if (this.bigText) {
                    return "big-text";
                } else {
                    return "";
                }
            }
        }
    })
</script>

<template>
    <div>
        <p class="name">{{itemName}}</p>
        <p :class="'value ' + getValueItemDynamicClass">{{itemValue}}</p>
    </div>
</template>

<style scoped lang="scss">
    .name {
        font-size: $font-card-label;
        margin-bottom: $default-edge-spacing-xs;
        color: $color-secondary-text;
    }

    .value {
        font-size: $font-card-text;
        color: $color-white-text;
    }

    .big-text {
        font-size: $font-card-big-text !important;
        font-weight: 500;
    }
</style>