<template>
  <div class="profile-card" @click="showDropdown" >
      <img class="profile-img" :src="compImg">
      <Dropdown
          ref="orgs"
          v-model="selectedOrgId"
          :options="manageableOrgs"
          optionLabel="name"
          option-value="orgId"
          class="org-dropdown"
          filter
          filter-placeholder="Search"
          />
      <div class="profile-text-container">
          <p class="title">{{title}}</p>
          <p class="subtitle">{{subtitile}}</p>
      </div>
      <img class="profile-card-dropdown" :src="require('../../assets/icons/chevron-down.svg')" alt="multi org dropdown">
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Dropdown from 'primevue/dropdown';

export default {
    components: {
        Dropdown
    },
    props: {
        title: String,
        subtitile: String,
        img: String,
        manageableOrgs: Array,
    },
    data() {
        return {
            selectedOrgId: null
        }
    },
    methods: {
        ...mapActions('account', ['switchOrg']),
        showDropdown(){
            const dropdown = this.$refs.orgs;
            dropdown.show(true)
        },
    },
    mounted() {
        console.log("manageableOrgs", this.manageableOrgs);
    },
    computed: {
        compImg(){
            return this.img ? require(`../../assets/${this.img}`) : "";
        }
    },
    watch: {
        selectedOrgId(newVal, oldVal){
            if (newVal !== -1 && newVal !== oldVal) {
                this.switchOrg(newVal)
            }
        }
    },

}
</script>

<style lang="scss" scoped>
    .profile-card {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 25px;
        transition: 0.3s ease !important;

        &:hover {
            cursor: pointer;
        }
    }
    .profile-img {
        width: 40px;
        height: 40px;
        border-radius: 8px;
    }

    p {
        margin: 0 !important;
        margin-left: 15px !important;
    }

    .title {
        font-size: $font-title;
        color: $color-primary-text;
        font-weight: bolder;
    }

    .subtitle {
        font-size: $font-small;
        color: $color-secondary-text;
        font-weight: normal;
    }

    .profile-card-dropdown {
        opacity: 0.5;
        width: 12px;
        height: 12px;
        margin-left: 30px;
    }

    .org-dropdown {
        width: 0 !important;
        height: 0 !important;
        padding: 0 !important;
        margin: 0 !important;
    }
</style>