import apiClient from '../helpers/ApiClient'
import { handleError, authHeader } from '../helpers/ApiClient';

export const productService = {
    getProducts,
    addProduct,
    deleteProduct,
    updateProduct
}

function getProducts(){
    return apiClient.get('/product', {headers: authHeader()})
        .then((response) => { return response.data})
        .catch((error) => handleError(error));
}

function addProduct(product){
    return apiClient.post(`/product`, product, {headers: authHeader()})
        .then((response) => { return response.data})
        .catch((error) => handleError(error));
}

function deleteProduct(product){
    return apiClient.delete(`/product/${product.productId}`, {headers: authHeader()})
        .then((response) => { return response.data})
        .catch((error) => handleError(error));
}

function updateProduct(product){
    return apiClient.patch(`/product/${product.productId}`, product, {headers: authHeader()})
        .then((response) => { return response.data})
        .catch((error) => handleError(error));
}