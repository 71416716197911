<template>
  <div>
    <page-header
        iconName="users.svg"
        :headerTitle="$t('generic.clients')"
    />


    <DataTable
        :value="clientData"
        v-model:filters="filters"
        filter-display="row"
        paginator
        ref="dtclient"
        :rows="500"
        :rows-per-page-options="[500, 1000, 2500, 5000, 10000]"
        table-style="min-width: 50rem"
        :loading="loading"
        :global-filter-fields="['name','phoneNumber','street','city','extraInfo']"
    >
      <template #header>
        <div class="flex justify-content-end" style="margin-top: 15px">
            <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                <Button style="margin-left: 3px;" label="Export" @click="exportCSV($refs)" >
                  <inline-svg class="action-icon" :src="require('@/assets/icons/download.svg')" />
                </Button>

            </span>
        </div>
      </template>
      <template #empty> {{$t('productGroupPage.noFundResultTable')}} </template>
      <template #loading> {{$t('productGroupPage.loadingResultTable')}} </template>
      <Column field="name" header="Name" sortable></Column>
      <Column field="phoneNumber" header="Phone" sortable></Column>
      <Column field="street" header="Street" sortable>
        <template #body="{ data }">
          {{ data.street }} {{ data.number }} {{ data.box }} <br>
        </template>
      </Column>
      <Column field="number" header="number" hidden></Column>
      <Column field="box" header="box" hidden></Column>
      <Column field="zipCode" header="zipCode" hidden></Column>

      <Column field="city" header="City" sortable>
        <template #body="{ data }">
          {{ data.zipCode }} {{ data.city }} <br>
        </template>
      </Column>
      <Column field="extraInfo" header="Extra Info" sortable></Column>

    </DataTable>


  </div>
</template>

<script>
import { ref } from 'vue';
import { mapActions, mapState } from "vuex";
import { FilterMatchMode } from 'primevue/api';
import PageHeader from './components/PageHeader.vue'
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';

export default {
  name: "ClientPage",
  components: {
    DataTable,
    Column,
    InputText,
    PageHeader
  },
  data() {
    return {
      filters: ref({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'productGroup.name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      }),
      selectedFilterProduct: "",
      itemFiltered:[],
      currentSelect: {}
    }
  },
  methods: {
    ...mapActions('client', ['getClientData']),

  exportCSV() {
    this.$refs.dtclient.exportCSV();
  }},
  computed: {
    ...mapState('client', ['clientData'])
  },
  mounted() {
    this.getClientData();
  }
}
</script>

<style>

</style>