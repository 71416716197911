import { printerService } from "@/services/PrinterService";
import { toast } from "vue3-toastify";
import { i18n } from "./../lang";

const state = {
    printers: {},
    printerRequest: {
        loading: false
    },
    currentPrinter: null
}

const actions = {
    getPrinters({commit}) {
        commit('setPrinterRequest', {loading: false});
        printerService.getPrinters()
        .then(response =>{
            commit('setPrinterRequest', {loading: false});
            commit('updatePrinters', response.data);
        })
        .catch(err => commit('setPrinterRequest', {loading: false, message: err}));
    },
    addPrinter({commit}, printer) {
        commit('setPrinterRequest', {loading: false});
        printerService.addPrinter(printer)
            .then(response =>{
                toast.success(i18n.global.t("toast.createSuccess"));
                commit('setPrinterRequest', {loading: false});
                const updatePrinters = state.printers;
                updatePrinters.push(response.data);
                commit("updatePrinters", updatePrinters);
                commit('setCurrentPrinter', null);
            })
            .catch(error => {
                toast.error(i18n.global.t("toast.createError"));
                commit('setPrinterRequest', {loading: false, message: error})
            });
    },
    editPrinter({commit}, printer) {
        commit('setPrinterRequest', {loading: false});
        printerService.updatePrinter(printer)
            .then(response =>{
                toast.success(i18n.global.t("toast.successEdit"));
                commit('setPrinterRequest', {loading: false});
                commit('setCurrentPrinter', null);
                commit('getPrinters');
            })
            .catch(error => {
                toast.error(i18n.global.t("toast.errorEdit"));
                commit('setPrinterRequest', {loading: false, message: error})
            });
    },
    deletePrinter({commit}, printer) {
        commit('setPrinterRequest', {loading: false});
        printerService.deletePrinter(printer)
            .then(response =>{
                toast.success(i18n.global.t("toast.deleteSuccess"));
                commit('setPrinterRequest', {loading: false});
                const updatePrinters = state.printers.filter(function(element) {
                    return element.id !== printer.id;
                });
                commit("updatePrinters", updatePrinters);
                commit('setCurrentPrinter', null);
            })
            .catch(error => {
                toast.error(i18n.global.t("toast.deleteError"));
                commit('setPrinterRequest', {loading: false, message: error})
            });
    }
}

const mutations = {
    setPrinterRequest(state, {loading, message}){
        state.printerRequest = {
            loading, message
        };
    },
    updatePrinters(state, printers) {
        state.printers = printers
    },
    setCurrentPrinter(state, printer) {
        state.currentPrinter = printer;
        if (printer === null) {
            state.printerRequest = {};
        }
    }
}

export const printer = {
    namespaced: true,
    state,
    actions,
    mutations,
}