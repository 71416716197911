import { workerService } from "@/services/WorkerService";
import {toast} from "vue3-toastify";
import {shiftService} from "@/services/ShiftService";

const state = {
    workersData: [],
    workerRequestMessage: "",
    isWorkerRequestMessage: false
};

const actions = {
    getWorkersData({ commit, state }, date){
        commit("setLoadingRequest", true);
        workerService.getWorkersData(date?.startDate, date?.endDate)
            .then((response) => {
                commit('setLoadingRequest', false);
                commit("setWorkersData", response.data);
            })
            .catch((error) => {
                commit(mutations.setLoadingRequest.name, false, error);
                toast.warning(error);
            });
    }
}

const mutations = {
    setLoadingRequest(state, isLoading, message=''){
        state.workerRequestMessage = message;
        state.isWorkerRequestMessage = isLoading;
    },
    setWorkersData(state, workersData){
        state.workersData = workersData;
    },
}


export const workerShift = {
    namespaced: true,
    state,
    actions,
    mutations
};