<template>
    <div class="logo-wrapper">
        <img :src="logo" alt="logo" class="logo"/>
    </div>
</template>
<script>
import {mapState} from "vuex";

export default {
    name: "CheckAbilities",
    computed: {
        ...mapState("account", {
            currentUser: "user",
            firstAllowedPage: "firstAllowedPage",
        }),

        logo() {
            return require(`../assets/logo.svg`);
        },
    },
    methods: {
        navigateToFirstAllowedPage() {
            if (this.firstAllowedPage) {
                this.$router.push({path: this.firstAllowedPage});
                console.log("Navigating to first allowed page: ", this.firstAllowedPage);
            } else if (this.currentUser && (this.currentUser.type === "ADMIN" || this.currentUser.type === "SUPER_USER")) {
                this.$router.push({path: "/dashboard"});
                console.log("Navigating to dashboard");
            }
        },
    },
    mounted() {
        this.navigateToFirstAllowedPage();
    },
    watch: {
        firstAllowedPage() {
            this.navigateToFirstAllowedPage();
        },
        currentUser() {
            this.navigateToFirstAllowedPage();
        },
    }
};
</script>
<style lang="scss" scoped>
.logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.logo {
    width: 50px;
    filter: grayscale(0%);
    transform: scale(1);
    animation: animGrayscaleAndScale 1s forwards alternate infinite;
}

@keyframes animGrayscaleAndScale {
    0% {
        filter: grayscale(0%);
        transform: scale(1);
        opacity: 1;
    }
    100% {
        filter: grayscale(100%);
        transform: scale(0.8);
        opacity: 0.4;
    }
}
</style>

