<template>
    <div>
        <page-header iconName="home.svg" :headerTitle="$t('navBar.dashboard')" name=""/>
      <design-select class="container-interval mt-4" :hint="$t('generic.dashboardIntervalChoice')"
                       v-model:customValue="selectedInterval">
            <option value="CURRENT_SHIFT"> {{$t('generic.dashboardIntervalCurrentShift')}} </option>
            <option value="LAST_SHIFT"> {{$t('generic.dashboardIntervalLastShift')}} </option>
            <option value="TODAY"> {{$t('generic.dashboardIntervalToday')}} </option>
            <option value="YESTERDAY"> {{$t('generic.dashboardIntervalYesterday')}} </option>
            <option value="THIS_WEEK"> {{$t('generic.dashboardIntervalThisWeek')}} </option>
            <option value="LAST_WEEK"> {{$t('generic.dashboardIntervalLastWeek')}} </option>
            <option value="THIS_MONTH"> {{$t('generic.dashboardIntervalThisMonth')}} </option>
            <option value="LAST_MONTH"> {{$t('generic.dashboardIntervalLastMonth')}} </option>

        </design-select>
        <div class="container-dash-cards row mt-2">
            <counter-card class="col dash-card" v-if="dashboardAccountsChartData"
                          :name="$t('dashboard.accountsTotal')"
                          :count="dashboardAccountsChartData.totalCount"
                          chart-type="Doughnut"
                          :tooltip-text="$t('dashboard.accountsTooltip')"
                          :custom-data="dashboardAccountsChartData.chartData"/>
            <counter-card class="col dash-card" v-if="dashboardSalesChartData"
                          :name="$t('dashboard.salesPaid')"
                          :count="dashboardSalesChartData.totalCount"
                          :tooltip-text="$t('dashboard.salesPaidTooltip')"
                          count-prefix="€"
                          chart-type="Bar"
                          :custom-data="dashboardSalesChartData.chartData"/>
            <counter-card class="col dash-card" v-if="rawDashboardData"
                          :name="$t('dashboard.remainingUnpaid')"
                          count-prefix="€"
                          :tooltip-text="$t('dashboard.remainingUnpaidTooltip')"
                          :count="rawDashboardData.unpaidOpenTotal.toFixed(2)"/>

        </div>
      <div class="container-dash-cards row-2 row">
            <current-shift-card
                class="col-12 col-md-7 dash-card"
                v-if="dashboardRequest.data && dashboardRequest.data.shift"
                :open-accounts-data="dashboardRequest.data.currentOpenAccountsCount"
                :shift="dashboardRequest.data.shift"/>

            <graph-card
                class="col-12 col-md-5 dash-card"
                v-if="dashboardDailySalesFormattedChartData"
                index-axis="x"
                :name="$t('generic.dailySales')"
                chart-type="LineChart"
                :hide-labels="true"
                :custom-data="dashboardDailySalesFormattedChartData" />
        </div>

        <div class="container-dash-cards row">
            <counter-card class="col-12 col-md-2 dash-card" v-if="rawDashboardData"
                          :name="$t('dashboard.averageBasket')"
                          :count="rawDashboardData.averageBasketValue.toFixed(2)"
                          count-prefix="€"/>
            <counter-card class="col-12 col-md-10 dash-card" v-if="dashboardTopSalesFormattedChartData"
                          name="Top Seller Products" :count="-1"
                          chart-type="Doughnut"
                          :custom-data="dashboardTopSalesFormattedChartData"
            />
        </div>
      <div class="container-dash-cards row">
            <graph-card
                class="col dash-card card-last"
                v-if="dashboardRushFormattedChartData"
                index-axis="x"
                :name="$t('generic.rushHours')"
                chart-type="LineChart"
                :hide-labels="true"
                :custom-data="dashboardRushFormattedChartData" />
        </div>
    </div>
</template>

<script>
import {mapActions, mapState, mapMutations} from "vuex";
import PageHeader from "./components/PageHeader.vue";
import CounterCard from "@/views/components/CounterCard.vue";
import {Doughnut} from "vue-chartjs";
import CurrentShiftCard from "@/views/components/dashboard/CurrentShiftCard.vue";
import GraphCard from "@/views/components/GraphCard.vue";
import DesignSelect from "@/views/components/DesignSelect.vue";
import {JWTHelper} from "@/helpers/JWTHelper";

export default {
    name: "Dashboard",
    components: {
        DesignSelect,
        GraphCard,
        CurrentShiftCard,
        PageHeader,
        CounterCard
    },
    data() {
        return {
            selectedInterval: 'CURRENT_SHIFT',
            socketConnection: null,
            lastSocketUpdateTimestamp: 0
        }
    },
    computed: {
        ...mapState("analytics", [
            "rawDashboardData",
            "dashboardRequest",
            "dashboardAccountsChartData",
            "dashboardSalesChartData",
            "dashboardDailySalesFormattedChartData",
            "dashboardRushFormattedChartData",
            "dashboardTopSalesFormattedChartData"
        ]),
      ...mapState("account", ["user"]),
    },
    methods: {
        ...mapActions("analytics", ["getDashboardData"]),
        openShift(shift) {
            this.$router.push({
                name: "ShiftDetailPage",
                params: {shiftId: shift},
            });
        },
        /**
         * Sends the auth token to the socket server to authenticate the current logged in user
         */
        sendSocketAuth() {
            const authObject = {
                "pushType": "PUSH_AUTH_TOKEN",
                "authToken": JWTHelper.getJwt()
            }
            this.socketConnection.send(JSON.stringify(authObject))
        },
        /**
         * Reloads the dashboard data if the last update was more than 10 seconds ago
         */
        reloadDashboardData() {
            //limit interval based on now time in milliseconds and the last update timestamp
            /*const now = new Date().getTime();
            const interval = now - this.lastSocketUpdateTimestamp;
            if (interval > 5000) {
                this.getDashboardData(this.selectedInterval);
                this.lastSocketUpdateTimestamp = now;
            }*/
            this.getDashboardData(this.selectedInterval);
            //this.lastSocketUpdateTimestamp = now;
        }
    },
    watch: {
        selectedInterval(newV, oldV) {
            this.getDashboardData(newV)
        }
    },
    unmounted() {
        if (this.socketConnection) {
            this.socketConnection.close();
        }
    },
    mounted() {
        this.reloadDashboardData();
        const parentThis = this;
        this.socketConnection = new WebSocket(process.env.VUE_APP_WS_BASE_URL);
        this.socketConnection.onmessage = function (event) {
            if (event.data && parentThis.selectedInterval == 'CURRENT_SHIFT') {
                try {
                    console.log("Updating dashboard from socket server message");
                    const jsonObject = JSON.parse(event.data);
                    if (jsonObject.pushType == "ACCOUNTS_UPDATED") {
                        parentThis.reloadDashboardData();
                    }
                } catch (e) {
                    console.log("Received invalid json socket message");
                }

            }
        };
        this.socketConnection.onopen = function (event) {
            parentThis.sendSocketAuth();
        };
        this.socketConnection.onclose = function (event) {
            console.log(event);
        };
    },
};
</script>

<style lang="scss" scoped>
    .container-dash-cards {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-content: flex-start;
        justify-content: flex-start;
        align-items: stretch;
    }

    .container-interval {
        margin-left: $default-edge-spacing;
        margin-right: $default-edge-spacing;
    }

    .table-row {
        margin-top: 0 !important;
    }
    .dash-card{
        padding: $default-edge-spacing !important;
    }

    .card-last {
        max-height: 30vh !important;
    }

</style>

