import apiClient from '../helpers/ApiClient'
import { handleError, authHeader } from '../helpers/ApiClient';

export const printerService = {
    getPrinters,
    addPrinter,
    updatePrinter,
    deletePrinter
}

function getPrinters() {
    return apiClient.get("/printer", {
        headers: authHeader()}
    )
    .then((response) => {
        return response.data
    })
    .catch((error) => handleError(error));
}

function addPrinter(printer) {
    return apiClient.post("/printer", 
    printer,
    {headers: authHeader()}
    )
    .then((response) => {
        return response.data
    })
    .catch((error) => handleError(error));
}

function updatePrinter(printer) {
    return apiClient.patch("/printer",
        printer,
        {headers: authHeader()}
    )
    .then((response) => {
        return response.data
    })
    .catch((error) => handleError(error));
}

function deletePrinter(printer) {
    return apiClient.delete(`/printer/${printer.id}`, {
        headers: authHeader()}
    )
    .then((response) => {
        return response.data
    })
    .catch((error) => handleError(error));
}