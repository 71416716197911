
import {defineComponent, PropType} from "vue";
import {Shift} from "@/models/Shift";
import {AccountsCountData} from "@/models/AccountsCountData";
import ShiftDataItem from "@/views/components/dashboard/ShiftDataItem.vue";

const __default__ = defineComponent({
    name: "CurrentShiftCard",
    components: {ShiftDataItem},
    props: {
        shift: {
            type: Object as PropType<Shift>,
            required: true
        },
        openAccountsData: {
            type: Object as PropType<AccountsCountData>,
            required: true
        }
    },
    computed: {
        shiftStatusColor() {
            if (this.shift.closeUser == null) {
                return "#73D772";
            } else {
                return "#DD6365";
            }
        },
        isShiftOpen() {
            return this.shift.endTimestamp == 0;
        }
    },
    methods: {
        openTables() {
            this.$router.push('/open-tables')
        },
        openTakeaway() {
            this.$router.push('/open-takeaway')
        },
        openShift() {
            this.$router.push('/shift-details/'+this.shift.shiftId)
        }
    }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "1ecfeaf9": (_ctx.shiftStatusColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__