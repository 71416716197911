const state = {
    isSideNavExpanded: false,
    apiErrorMessage: ''
};

const mutations = {
    setExpanded(state, expanded){
        state.isSideNavExpanded = expanded;
    },
    toggleSideNav(state) {
        var newState = !state.isSideNavExpanded
        state.isSideNavExpanded = newState;
    },
    setApiError(state, apiErrorMessage){
        state.apiErrorMessage = apiErrorMessage;
    }
}

export const global = {
    namespaced: true,
    state,
    mutations
};