import apiClient from '../helpers/ApiClient'
import { handleError, authHeader } from '../helpers/ApiClient';

export const stockService = {
    getStockHistoryFromOneProduct
}

function getStockHistoryFromOneProduct(productId) {
    return apiClient.get(`/stock/product/${productId}`, {
        headers: authHeader()}
    )
        .then((response) => {
            return response.data
        })
        .catch((error) => handleError(error));
}