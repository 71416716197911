<template>
  <div class="main">
      <div class="form-input-container">
        <p class="text-center" v-html="$t('generic.deleteConfirm')"></p>
      </div>
      <div class="button-container">
          <design-button class="button" @click="$emit('cancelDeleteAction')" type="secondary"> {{$t('generic.cancel')}} </design-button >
          <design-button class="button" @click="$emit('submitDeleteAction', item)"> {{$t('generic.delete')}} </design-button >
      </div>
  </div>
</template>

<script>
import DesignButton from '../components/DesignButton.vue';

export default {
    name: 'ConfirmDeleteDialog',
    components: {
        DesignButton
    },
    props: ['item'],
}
</script>

<style lang="scss" scoped>
    .main {
        width: auto;
        padding: 20px 20px;
    }   

    .form-input-container {
        display: flex;
        flex-direction: column;
        row-gap: $default-edge-spacing-l;
    }

    .button-container {
        display: flex;
        justify-content: stretch;
        align-content: stretch;
        column-gap: $default-edge-spacing-l;
        margin-top: $default-edge-spacing-xxl;

        .button {
            width: 100%;
        }
    }

    @media (max-width: $screen-md ) {

        .button-container {
            justify-content: center;
            flex-direction: column;
            padding-top: $default-edge-spacing;
            .button {
                margin-top: $default-edge-spacing-l;

                &:nth-child(1) {
                    padding-right: 0 !important;
                }
            }            
        }
    }
</style>