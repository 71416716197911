<template>
  <div>
    <page-header
        iconName="users.svg"
        :headerTitle="$t('generic.shiftUser')"
    />

    <DataTable
        :value="workersData"
        v-model:filters="filters"
        filter-display="row"
        paginator
        ref="dtWorkerShift"
        :rows="500"
        :rows-per-page-options="[500, 1000, 2500, 5000, 10000]"
        table-style="min-width: 50rem"
        :loading="loading"
        :global-filter-fields="['parentShiftId','user.username','user.type','startTime','endTime', 'Time']"
    >
      <template #header>
        <div class="flex justify-content-end" style="margin-top: 15px">
            <span class="p-input-icon-left">
                <i class="pi pi-search"/>
                <InputText v-model="filters['global'].value" placeholder="Keyword Search"/>
                <Button style="margin-left: 3px;" label="Export" @click="exportCSV($refs)">
                  <inline-svg class="action-icon" :src="require('@/assets/icons/download.svg')"/>
                </Button>

            </span>
        </div>
      </template>
      <template #empty> {{ $t('generic.noDataFound') }}</template>
      <template #loading> {{ $t('productGroupPage.loadingResultTable') }}</template>
      <Column field="parentShiftId" header="Shift" sortable>
        <template #body="{ data }">
          <button class="bread-crumb-base" @click="openShift(data.parentShiftId)"
                  style="background-color: rgb(131, 147, 244);">{{
              data.parentShiftId
            }}
          </button>
        </template>
      </Column>
      <Column field="user.username" header="Name" sortable></Column>
      <Column field="user.type" header="Type" sortable></Column>
      <Column field="startTime" header="Start" sortable>
        <template #body="{ data }">
          {{ data?.startTime.toDate() }} <br>
          <span class="default-text">{{ data?.startTime.toTime() }}</span>
        </template>
      </Column>
      <Column field="endTime" header="End" sortable>
        <template #body="{ data }">
          {{ data?.endTime.toDate() }} <br>
          <span class="default-text">{{ data?.endTime.toTime() }}</span>
        </template>
      </Column>
      <Column field="Time" header="Time" sortable>
        <template #body="{ data }">
          {{ minutesDiff(data?.startTime, data?.endTime) }} <br>
        </template>
      </Column>
      <Column field="autoClosed" header="Auto close" sortable></Column>
    </DataTable>


  </div>
</template>

<script>
import {ref} from 'vue';
import {mapActions, mapState} from "vuex";
import {FilterMatchMode} from 'primevue/api';
import PageHeader from './components/PageHeader.vue'
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';

export default {
  name: "UserShiftDetailPage",
  components: {
    DataTable,
    Column,
    InputText,
    PageHeader
  },
  data() {
    return {
      filters: ref({
        global: {value: null, matchMode: FilterMatchMode.CONTAINS},
        name: {value: null, matchMode: FilterMatchMode.STARTS_WITH},
        'productGroup.name': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
      }),
      selectedFilterProduct: "",
      itemFiltered: [],
      currentSelect: {},
      startDate: "",
      endDate: ""
    }
  },
  methods: {
    ...mapActions('workerShift', ['getWorkersData']),

    exportCSV() {
      this.$refs.dtWorkerShift.exportCSV();
    },
    openShift(shift) {
      this.$router.push({
        name: "ShiftStatPage",
        params: {shiftId: shift},
      });
    },
    minutesDiff(startTime, endTime) {
      if (!startTime || !endTime) {
        return ' - ';
      } else {
        // Difference in milliseconds and convert it to seconds
        let diff = Math.abs(startTime - endTime) / 1000;
        // Convert the difference into hours, minutes, and seconds
        const hours = Math.floor(diff / 3600); // Calculate full hours
        diff %= 3600; // Remaining seconds after hours
        const minutes = Math.floor(diff / 60); // Calculate full minutes
        const seconds = Math.floor(diff % 60); // Remaining seconds after full minutes
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
      }
    }
  },

  computed: {
    ...mapState('workerShift', ['workersData'])
  },
  mounted() {
    const startTimestamp = Number(this.$route.params.startdate);
    const endTimestamp = Number(this.$route.params.enddate);
    this.startDate = new Date(startTimestamp).toLocaleDateString();
    this.endDate = new Date(endTimestamp).toLocaleDateString();
    this.getWorkersData({startDate: startTimestamp, endDate: endTimestamp});
  }
}
</script>

<style>

</style>