import { accountService } from "@/services/AccountService";
import router from '@/router'

const state = {
    isLoggedIn: false,
    user: null,
    isLoadingRequest: false,
    loginMessage: '',
    isSuperUser: false,
    abilities: null,
    firstAllowedPage: null
};

const actions = {
    login({ dispatch, commit }, { username, password, orgId }) {
        commit('loadingRequest', true);
        accountService.login(username, password, orgId)
            .then(response => {
                commit('accountResponse', response.data);
                commit('computeAllowedPage', response.data.abilities);
                router.push('/');
            })
            .catch(error => {
                console.log('$(TAG) : An error has occured $(error)');
                commit('authError', error);
            });
    },

    logout({ dispatch, commit }) {
        accountService.logout();
        commit('authError', "Logged out !");
        router.push('/login');
    },
    getMyData({ dispatch, commit }) {
        accountService.getMyData()
            .then(response => {
                commit('accountResponse', response.data);
            })
            .catch(error => {
                commit('authError', error);
            });
    },
    switchOrg({ dispatch, commit }, orgId) {
        accountService.switchOrg(orgId)
            .then(response => {
                commit('accountResponse', response.data);
                location.reload();
            })
            .catch(error => {
                commit('authError', error);
            });
    }
};

const mutations = {
    loadingRequest: (state, isLoading) => {
        state.loginMessage = '';
        state.isLoadingRequest = isLoading;
    },
    accountResponse: (state, user) => {
        state.isLoggedIn = true;

        //change user managageable orgs name to include org id
        user.managedOrganizations = user.managedOrganizations.map(org => {
            org.name = org.name + " - " + org.orgId;
            return org;
        });

        state.user = user;

        state.isSuperUser = user.type === "SUPER_USER";
        state.isLoadingRequest = false;
    },
    authError: (state, errorMessage) => {
        state.isLoadingRequest = false;
        state.user = null;
        state.isLoggedIn = false;
        state.loginMessage = errorMessage;
    },
    computeAllowedPage: (state, abilities) => {
        let abilityPagePermissionManager = new AbilityPagePermissionManager(abilities);
        state.abilities = abilities;
        state.firstAllowedPage = abilityPagePermissionManager.getFirstAllowedPage();
    }
};

class AbilityPage {
    page = "";
    ability = "";
    constructor(ability, page){
        this.page = page;
        this.ability = ability;
    }
}

class AbilityPagePermissionManager {
    allowedAbilityPages = [
        new AbilityPage("ANALYTICS_DASHBOARD", "/dashboard"),
        new AbilityPage("ADMIN_PRODUCTS", "/products"),
        new AbilityPage("ADMIN_GROUPS", "/product_groups"),
        new AbilityPage("ADMIN_TABLES", "/tables"),
        new AbilityPage("ADMIN_CLIENT_DATA", "/clients"),
        new AbilityPage("ADMIN_PRINTERS", "/printers"),
        new AbilityPage("ANALYTICS_ACCOUNTS", "/analytics/accounts"),
        new AbilityPage("ANALYTICS_SHIFTS", "/analytics/shifts"),
        new AbilityPage("ANALYTICS_ORDERS", "/analytics/orders"),
        new AbilityPage("ANALYTICS_STOCK", "/stock"),
    ];
    firstAllowedPage = "/dashboard";
    abilities = [];

    constructor(abilities){
        this.abilities = abilities;
    }

    getFirstAllowedPage(){
        for (let i = 0; i < this.allowedAbilityPages.length; i++){
            if (this.abilities.includes(this.allowedAbilityPages[i].ability)){
                return this.allowedAbilityPages[i].page;
            }
        }
        return this.firstAllowedPage;
    }
}

export const account = {
    namespaced: true,
    state,
    actions,
    mutations
};