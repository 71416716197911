<template>
  <div>
    <page-header
        class="header"
        iconName="calculate.svg"
        :actionText="$t('generic.search')"
        :headerTitle="$t('navBar.shift')"
        actionSecondaryIconName="download.svg"
        @actionSecondaryClick="submitDownloadZ"
        :actionSecondaryText="$t('generic.downloadZ')"
        actionTernaryIconName="download.svg"
        @actionTernaryClick="submitDownloadX"
        :actionTernaryText="$t('generic.downloadX')">
    </page-header>

    <popup-view :isVisible="CloseShiftOpenDialog">
      <confirm-delete-dialog :product="currentSelect" @submitDeleteAction="submitDelete"
                             @cancelDeleteAction="deleteOpenDialog = false"/>
    </popup-view>

    <div class="row">
      <div class="main-content col-12 col-md-9 order-2 order-md-1">
        <DataTable :value="shiftData?.shifts" v-model:filters="filters" table-style="min-width: 50rem"
                   :loading="loading"
                   :global-filter-fields="['startTimestamp', 'closeComment', 'endTimestamp', 'totalCash', 'totalBankTransfer', 'totalOffered','total']">
          <template #header>
            <div class="d-flex" style="margin-top: 22px">
              <div class="p-input-icon-left">
                <i class="pi pi-search"/>
                <InputText v-model="filters['global'].value" placeholder="Keyword Search"/>
              </div>
              <div class="ml-3">
                <Calendar dateFormat="dd-mm-yy" class="mr-1 mb-1" v-model="dates" selectionMode="range"
                          :manualInput="false" showIcon iconDisplay="input" placeholder="jj/mm/aaaa - jj/mm/aaaa"/>
                <button class="action-button mr-1" v-if="dates" @click="searchDateCalendar()">
                  <inline-svg class="action-icon" :src="require('@/assets/icons/search.svg')"/>
                </button>
                <button class="action-button mr-1" v-if="dates" @click="deleteDateCalendar()">
                  <inline-svg class="action-icon" :src="require('@/assets/icons/close-2.svg')"/>
                </button>
                <button class="action-button" v-if="dates" @click="statisticCalendar()">
                  <inline-svg class="action-icon" :src="require('@/assets/icons/statistic.svg')"/>
                </button>
              </div>
            </div>
          </template>
          <template #empty> {{ $t('productGroupPage.noFundResultTable') }}</template>
          <template #loading> {{ $t('productGroupPage.loadingResultTable') }}</template>


          <Column field="startTimestamp" header="Open time" sortable>
            <template #body="{ data }">
              {{ data?.startTimestamp.toDate() }} <br>
              <span class="default-text">{{ data?.startTimestamp.toTime() }}</span>
            </template>
          </Column>
          <Column field="id" header="id" sortable>
            <template #body="{ data }">
              {{ data?.shiftId }} <br>
            </template>
          </Column>
          <Column field="totalCash" header="Cash" sortable>
            <template #body="{ data }">
              <span v-if="data?.totalData.totalCash">€{{ data?.totalData.totalCash.toFixed(2) }}</span>
              <span v-else>€{{ 0.0.toFixed(2) }}</span>
            </template>
          </Column>
          <Column field="totalCard" header="Card" sortable>
            <template #body="{ data }">
              <span v-if="data?.totalData.totalCard">€{{ data?.totalData.totalCard.toFixed(2) }}</span>
              <span v-else>€{{ 0.0.toFixed(2) }}</span>
            </template>
          </Column>
          <Column field="totalBankTransfer" header="B. Transfer" sortable>
            <template #body="{ data }">
              <span v-if="data?.totalData.totalBankTransfer">€{{ data?.totalData.totalBankTransfer.toFixed(2) }}</span>
              <span v-else>€{{ 0.0.toFixed(2) }}</span>
            </template>
          </Column>
          <Column field="totalOffered" header="Offered" sortable>
            <template #body="{ data }">
              <span v-if="data?.totalData.totalOffered">€{{ data?.totalData.totalOffered.toFixed(2) }}</span>
              <span v-else>€{{ 0.0.toFixed(2) }}</span>
            </template>
          </Column>
          <Column field="totalError" header="Error" sortable>
            <template #body="{ data }">
              <span v-if="data?.totalData.totalError">€{{ data?.totalData.totalError.toFixed(2) }}</span>
              <span v-else>€{{ 0.0.toFixed(2) }}</span>
            </template>
          </Column>
          <Column field="total" header="Total shift" sortable>
            <template #body="{ data }">
              <span v-if="data?.totalData.total">€{{ data?.totalData.total.toFixed(2) }}</span>
              <span v-else>€{{ 0.0.toFixed(2) }}</span>
            </template>
          </Column>
          <Column field="endTimestamp" header="Close time" sortable>
            <template #body="{ data }">
                            <span v-if="data?.endTimestamp">
                                {{ data?.endTimestamp.toDate() }} <br>
                                <span class="default-text">{{ data?.endTimestamp.toTime() }}</span>
                            </span>
              <span v-else>{{ $t('shiftPage.inProgress') }}</span>
            </template>
          </Column>
          <Column field="closeComment" header="Comment" sortable>
            <template #body="{ data }">
              <span v-if="data?.closeComment">{{ data?.closeComment }}</span>
              <span v-else>{{ $t('generic.no') }}</span>
            </template>
          </Column>
          <Column field="actions" header="Actions" sortable>
            <template #body="{ data }">
              <div class="edit-row-btn">
                <button class="" @click="openShiftDetail(data.shiftId)">
                  <inline-svg :src="require('@/assets/icons/align-left.svg')"/>
                </button>
                <button class="" @click="openShiftStat(data.shiftId)">
                  <inline-svg :src="require('@/assets/icons/statistic.svg')"/>
                </button>
              </div>
            </template>
          </Column>
        </DataTable>
      </div>

      <div class="col-12 col-md-3 order-1 order-md-2 mt-md-5">
        <div class="col-12">
          <div v-if="shiftCurrentData == null" class="ml-md-3 total-container card ">
            <p class="header mb-3">{{ $t('shiftPage.OpenNewShift') }}</p>
            <div class="form-input-container">
              <DesignInput class="p-2" v-model:customValue="myCurrentCash" :hint="$t('shiftPage.totalCashClose')"/>
            </div>
            <hr/>
            <div class="button-container row justify-content-end">
              <design-button class="button" @customClick="startNewShift()"> {{ $t('shiftPage.OpenNewShiftButton') }}
              </design-button>
            </div>
          </div>


          <div class="ml-md-3 total-container card ">
            <p class="header mb-3">{{ $t('generic.total') }}</p>
            <div class="text-center">
              <span class="float-left">{{ $t('paymentsPage.totalCash') }}</span>
              <span v-if="shiftData != null" class="float-right">€{{
                  shiftData?.totalData?.totalCash.toFixed(2)
                }}</span>
              <span v-else class="float-right">€{{ 0.0.toFixed(2) }}</span>
            </div>

            <div class="text-center">
              <span class="float-left">{{ $t('paymentsPage.totalCard') }}</span>
              <span v-if="shiftData != null" class="float-right">€{{
                  shiftData?.totalData?.totalCard.toFixed(2)
                }}</span>
              <span v-else class="float-right">€{{ 0.0.toFixed(2) }}</span>
            </div>

            <div class="text-center">
              <span class="float-left">{{ $t('paymentsPage.totalBankTransfer') }}</span>
              <span v-if="shiftData != null" class="float-right">€{{
                  shiftData?.totalData?.totalBankTransfer.toFixed(2)
                }}</span>
              <span v-else class="float-right">€{{ 0.0.toFixed(2) }}</span>
            </div>

            <div class="text-center">
              <span class="float-left">{{ $t('paymentsPage.totalOffered') }}</span>
              <span v-if="shiftData != null" class="float-right">€{{
                  shiftData?.totalData?.totalOffered.toFixed(2)
                }}</span>
              <span v-else class="float-right">€{{ 0.0.toFixed(2) }}</span>
            </div>

            <div class="text-center">
              <span class="float-left">{{ $t('paymentsPage.totalError') }}</span>
              <span v-if="shiftData != null" class="float-right">€{{
                  shiftData?.totalData?.totalError.toFixed(2)
                }}</span>
              <span v-else class="float-right">€{{ 0.0.toFixed(2) }}</span>
            </div>

            <hr>

            <div class="text-center">
              <span class="float-left default-header">{{ $t('generic.total') }}</span>
              <span v-if="shiftData != null"
                    class="float-right default-header">€{{ shiftData?.totalData?.total.toFixed(2) }}</span>
              <span v-else class="float-right default-header">€{{ 0.0.toFixed(2) }}</span>
            </div>
          </div>
          <div v-if="shiftData?.vatSummaries" class="ml-md-3 total-container card mt-3">
            <p class="header mb-3">{{ $t('generic.vat') }}</p>
            <div v-for="vatSummary in shiftData?.vatSummaries" :key="vatSummary.vatBasePercent" class="mb-3 d-card">
              <span>{{ vatSummary.vatBasePercent }}%</span>
              <div class="text-center">
                <span class="float-left">{{ $t('paymentsPage.totalVatExcl') }}</span>
                <span class="float-right">€{{ vatSummary.totalVatExcl.toFixed(2) }}</span>
              </div>
              <div class="text-center">
                <span class="float-left">{{ $t('generic.vat') }}</span>
                <span class="float-right">€{{ vatSummary.totalVat.toFixed(2) }}</span>
              </div>
              <div class="text-center">
                <span class="float-left">{{ $t('paymentsPage.totalVatIncl') }}</span>
                <span class="float-right">€{{ vatSummary.totalVatIncl.toFixed(2) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ref} from 'vue';
import {FilterMatchMode} from 'primevue/api';
import {mapActions, mapState, mapMutations} from "vuex";
import PageHeader from './components/PageHeader.vue'
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import Calendar from 'primevue/calendar';
import ConfirmDeleteDialog from "@/views/dialogs/ConfirmDeleteDialog.vue";
import PopupView from "@/views/components/PopupView.vue";
import DesignInput from "@/views/components/DesignInput.vue";
import DesignButton from "@/views/components/DesignButton.vue";

export default {
  name: "ShiftPage",
  components: {
    DesignButton, DesignInput,
    PopupView, ConfirmDeleteDialog,
    PageHeader,
    DataTable,
    Column,
    InputText,
    Calendar
  },
  computed: {
    ...mapState('shift', ['shiftData', 'queryStartDateTime', 'queryEndDateTime', 'shiftCurrentData'])
  },
  data() {
    return {
      filters: ref({
        global: {value: null, matchMode: FilterMatchMode.CONTAINS},
      }),
      dates: null,
      shiftCur: null
    };
  },
  methods: {
    ...mapActions('shift', ['getShiftData', 'getShiftDownloadXData', 'getShiftDownloadZData', 'getShiftCurrentData', 'openNewShift']),
    ...mapMutations('shift', ['setQueryStartDateTime', 'setQueryEndDateTime']),
    submitSearch() {
      this.getShiftData();
    },
    searchDateCalendar() {
      if (this.dates[0] && this.dates[1]) {
        const startDate = new Date(this.dates[0]);
        const endDate = new Date(this.dates[1]);
        endDate.setHours(23);
        endDate.setMinutes(59);
        endDate.setSeconds(59);

        this.setQueryStartDateTime(startDate);
        this.setQueryEndDateTime(endDate);
        this.getShiftData();
      } else if (this.dates[0] && !this.dates[1]) {
        const startDate = new Date(this.dates[0]);
        const endDate = new Date(this.dates[0]);
        endDate.setDate(endDate.getDate() + 1);

        this.setQueryStartDateTime(startDate);
        this.setQueryEndDateTime(endDate);
        this.getShiftData();
      }
    },
    statisticCalendar() {

      let startMs = new Date(this.dates[0]).getTime();
      let endDate = new Date(this.dates[1]);
      endDate.setHours(23);
      endDate.setMinutes(59);
      endDate.setSeconds(59);
      let endMs = endDate.getTime();
      this.$router.push({
        name: "ShiftDetailPageByDate",
        params: {startdate: startMs, enddate: endMs},
      });
    },
    deleteDateCalendar() {
      this.dates = '';
      this.setQueryStartDateTime(0);
      this.setQueryEndDateTime(0);
      this.getShiftData();
    },
    submitDownloadX() {
      this.getShiftDownloadXData('shiftPage');
    },
    submitDownloadZ() {
      this.getShiftDownloadZData('shiftPage');
    },
    openShiftDetail(shift) {
      this.$router.push({
        name: "ShiftDetailPage",
        params: {shiftId: shift},
      });
    },
    openShiftStat(shift) {
      this.$router.push({
        name: "ShiftStatPage",
        params: {shiftId: shift},
      });
    },
    getShiftCur() {
      this.getShiftCurrentData();
    },
    startNewShift() {
      if (window.confirm(this.$t('shiftPage.MessageCloseShift'))) {
        this.openNewShift({myCurrentCash: this.myCurrentCash});

        //window.location.reload();
      }
    }
  },
  mounted() {
    this.submitSearch();
    this.getShiftCur();
  }
}
</script>

<style lang="scss" scoped>
.card {
  border-radius: $radius-small;
  background-color: $color-input-back;
  margin-top: $default-edge-spacing-xxl;
  padding: $default-edge-spacing-xl !important;
}

button {
  &.action-button {
    background-color: $color-primary-blue;
    height: 41px;
  }
}

.total-container {
  .header {
    font-size: $font-title-big;
    font-weight: 600;
    padding-left: 10px;
    border-left: 3px solid $color-primary-blue;
  }

  .float-left {
    color: $color-primary-text;
  }

  .float-right {
    color: $color-secondary-text;
  }
}

hr {
  border-color: $color-secondary-text;
  border-radius: 15px;
  opacity: 0.15;
}

.d-card {
  display: flex;
  flex-direction: column
}

</style>