import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-57814996"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.itemName), 1),
    _createElementVNode("p", {
      class: _normalizeClass('value ' + _ctx.getValueItemDynamicClass)
    }, _toDisplayString(_ctx.itemValue), 3)
  ]))
}