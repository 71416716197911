<template>
  <div class="main">
    <div class="form-input-container">
        <DesignInput v-model:customValue="name" :error="hasEmptyValue(name)" :hint="$t('printerStrings.printerName')" />
        <DesignInput v-model:customValue="ipAddress" :error="hasEmptyValue(ipAddress)" :hint="$t('generic.ipAddress')" />
        <design-select :error="hasEmptyValue(doublePrint)" :hint="$t('printerStrings.doublePrint')" v-model:customValue="doublePrint">
            <option :value="true">{{$t('generic.yes')}}</option>
            <option :value="false">{{$t('generic.no')}}</option>
        </design-select>
    </div>
    <transition name="fade">
          <p v-if="printerRequest.message" class="text-status error"> {{ printerRequest.message }}</p>
      </transition>
    <div class="button-container">
        <design-button class="button" @customClick="cancel()" type="secondary"> {{$t('generic.cancel')}} </design-button >
        <design-button class="button"  @customClick="submitPrinter()" :loading="printerRequest.loading"> {{$t('generic.save')}} </design-button >
    </div>
  </div>
</template>

<script>
import {mapMutations, mapActions, mapState} from "vuex";
import DesignInput from '../components/DesignInput.vue';
import DesignButton from '../components/DesignButton.vue';
import DesignSelect from '../components/DesignSelect.vue';
export default {
    name: 'EditPrinterDialog',
    components: {
        DesignInput,
        DesignButton,
        DesignSelect,
    },
    data() {
        return {
            id: 0,
            name: "",
            ipAddress: "",
            doublePrint: false,
            isNew: false,
        }
    },
    computed: {
        ...mapState('printer', ['printers', 'currentPrinter', 'printerRequest'])
    },
    methods: {
        ...mapMutations('printer', ['setCurrentPrinter']),
        ...mapActions('printer', ['addPrinter', 'editPrinter']),
        hasEmptyValue(inputValue){
            if (this.printerRequest.message && (inputValue === '' || inputValue == 0)){
                return true;
            }
            return false;
        },
        cancel() {
            this.setCurrentPrinter(null);
        },
        submitPrinter() {
            const printerToSend = this.currentPrinter;
            printerToSend.name = this.name;
            printerToSend.ipAddress = this.ipAddress;
            printerToSend.doublePrint = this.doublePrint
            printerToSend.id = this.id;

            if (this.isNew) {
                this.addPrinter(printerToSend);
            } else {
                this.editPrinter(printerToSend);
            }
        }
    },
    mounted() {
        let currentPrinter = this.currentPrinter
        if (!$.isEmptyObject(currentPrinter)){
            this.isNew = false;
            this.id = currentPrinter.id;
            this.ipAddress = currentPrinter.ipAddress;
            this.doublePrint = currentPrinter.doublePrint;
            this.name = currentPrinter.name;
        } else {
            this.isNew = true;
        }
    }
}
</script>

<style lang="scss" scoped>
    .main {
        width: auto;
        padding: 20px 20px;
    }   
    .form-input-container {
        display: flex;
        flex-direction: column;
        row-gap: $default-edge-spacing-l;
    }

    .button-container {
        display: flex;
        justify-content: stretch;
        align-content: stretch;
        column-gap: $default-edge-spacing-l;
        margin-top: $default-edge-spacing-xxl;

        .button {
            width: 100%;
        }
    }

    @media (max-width: $screen-md ) {

        .button-container {
            justify-content: center;
            flex-direction: column;
            padding-top: $default-edge-spacing;
            .button {
                margin-top: $default-edge-spacing-l;

                &:nth-child(1) {
                    padding-right: 0 !important;
                }
            }

            
        }

    }
</style>