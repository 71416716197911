<template>
  <div class="card-list-row table-row d-flex flex-column p-3 m-0"
       :style="{'border': '2px solid' + item?.tableZone?.color}">
    <p class="d-flex justify-content-center" v-if="!isTakeaway">
      <span v-if="!item?.tableType?.name">{{ $t('generic.tableNumber') }} {{ item.tableNumber }}</span>
      <span v-if="item?.tableType?.name">{{ item?.tableType?.name }} {{ item.tableNumber }}</span>
    </p>
    <p class="d-flex justify-content-center" v-if="isTakeaway">{{ $t('generic.takeaway') }} {{ order }}</p>
    <div class="d-flex justify-content-center mt-2" v-if="isDashboard">
      <span class="secondary-text" v-if="item.user?.username"><inline-svg class="user"
                                                                          :src="require('@/assets/icons/user.svg')"/> {{
          item.user.username
        }}</span>
    </div>
    <div class="d-flex justify-content-center mt-2" v-if="isDashboard && isTakeaway">
      <span class="secondary-text" v-if="item?.clientData">
          <inline-svg class="user"
                      :src="require('@/assets/icons/users.svg')"/> {{
          item.clientData.name
        }}</span>
      <span v-else class="secondary-text">
        <inline-svg class="user"
                    :src="require('@/assets/icons/users.svg')"/> -
      </span>
    </div>
    <div v-if="item.clientAccount?.openedTimestamp && isDashboard && !isTakeaway"
         class="d-flex justify-content-center my-2">
            <span class="secondary-text">
                {{ item.clientAccount.openedTimestamp.toTime() }} {{ item.clientAccount.openedTimestamp.toDate() }}
            </span>
    </div>

    <div v-if="item?.openedTimestamp && isDashboard && isTakeaway" class="d-flex justify-content-center my-2">
            <span class="secondary-text">
                {{ item.openedTimestamp.toTime() }} {{ item.openedTimestamp.toDate() }}
            </span>
    </div>

    <div v-if="isDashboard" class="d-flex justify-content-center mt-2">
            <span v-if="!isTakeaway" class="bread-crumb red secondary-text"
                  @click="$emit('openAccount', item.clientAccount)">
                €{{ item.totalData?.total.toFixed(2) }}
            </span>
      <span v-if="isTakeaway" class="bread-crumb red secondary-text"
            @click="$emit('openAccount', item.clientAccountId)">
                €{{ item.totalData?.total.toFixed(2) }}
            </span>
    </div>
    <div class="card-list-footer" v-if="!isDashboard">
      <div class="edit-row-btn d-flex justify-content-center mt-2">
        <button class="delete" @click="$emit('secondaryAction', item)">
          <inline-svg :src="require('@/assets/icons/trash.svg')"/>
        </button>
        <button @click="setCurrentTable(item)">
          <inline-svg :src="require('@/assets/icons/edit.svg')"/>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations} from "vuex";
import {colorUtil} from '@/helpers/ColorUtil';

export default {
  name: "TableRow",
  props: ['item', 'isDashboard', 'isTakeaway', 'order'],
  emits: ['primaryAction', 'secondaryAction', 'openAccount'],
  methods: {
    ...mapMutations('table', ['setCurrentTable']),
    randomHSL(id) {
      return colorUtil.randomHSL(id);
    }
  }
}
</script>

<style lang="scss" scoped>

.edit-row-btn {
  column-gap: 10px;
  background: none !important;
}

</style>