import {DeviceHelper} from "./DeviceHelper";

const KEY_JWT = "jwt";
export class JWTHelper {

    static saveJwt(jwt) {
        if (DeviceHelper.isIOS()) {
            sessionStorage.setItem(KEY_JWT, jwt);
        } else {
            localStorage.setItem(KEY_JWT, jwt);
        }
    }

    static clearJwt() {
        sessionStorage.clear();
        localStorage.clear();
    }

    static getJwt() {
        if (DeviceHelper.isIOS()) {
            return sessionStorage.getItem(KEY_JWT);
        } else {
            return localStorage.getItem(KEY_JWT);
        }
    }

    static isLoggedIn() {
        return JWTHelper.getJwt() != null
    }
}

