import axios from "axios";
import {JWTHelper} from "@/helpers/JWTHelper";


export function authHeader() {
  // return authorization header with jwt token
  let userJwt = JWTHelper.getJwt();

  if (userJwt) {
      return { "Authorization": "Bearer " + userJwt };
  } else {
      return {};
  }
}

export function handleError(error) {
  console.log('Error', error);
  if (error.response && error.response.status === 401){
    JWTHelper.clearJwt();
    location.reload(true);
  }

  if (error.response) {
      // Request made and server responded
      console.log(error.response.data.message);
      return Promise.reject(error.response.data.message);
  } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error);
      return Promise.reject(error.message);
  }
}

const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    headers: {
        "Content-type": "application/json",

    },
});
export default apiClient;
